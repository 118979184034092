<template>
  <body class="poster">
    <div class="login-panel">
        <div class="logo-wrap"><a ><img :src="logo" class="logo" :alt="company"></a> </div>
        <el-form class="login-container" label-position="left" label-width="0px">
          <h3 class="login_title">登录</h3>
          <el-form-item>
            <el-input type="text" v-model="loginForm.username" @keyup.enter.native="login" class="login-input"
                      auto-complete="off" placeholder="请输入梅花CRM用户名" ref="username"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input type="password" v-model="loginForm.password" @keyup.enter.native="login"
                      auto-complete="off" placeholder="密码" ref="password"></el-input>
          </el-form-item>
          <el-form-item style="width: 100%">
            <el-button type="primary" style="width: 100%;background: #409EFF;border: none" v-on:click="login" ref="loginBtn" :loading="loading">登录</el-button>
          </el-form-item>
        </el-form>
    <div class="login-desc">注：请使用梅花CRM账号登录并使用</div>
    </div>
  </body>
</template>
 
<script>
  import GLOBAL from '../common/global_variable'
  export default {
    name: "Login",
    data () {
      return {
        loading: false,
        logo: '',
        company:'',
        loginForm: {
          username: '',
          password: ''
          // username: 'andyfu',
          // password: ''
        },
        responseResult: []
      }
    },
    methods: {
      login () {
        if(!this.loginForm.username){
          this.$message('请输入用户名');
          this.$refs.username.focus();
          return;
        }
        if(!this.loginForm.password){
          this.$message('请输入密码');
          this.$refs.password.focus();
          return;
        }
        var _this = this;
        // console.log(this.$refs.loginBtn)
        this.loading = true
        // this.$refs.loginBtn.$el.innerText = "登录中...";
        
        let paras = new FormData();
        paras.append('username',this.loginForm.username);
        paras.append('password',this.loginForm.password);

        let loginapi = "/api/admin/login";

        var params = {
          username : this.loginForm.username,
          password : this.loginForm.password
        };

        this.$axios.post(loginapi, params).then(res=>{
          // console.log(res.status)
          this.loading = false
            if (res.status === 200) {
              if(res.data.success){
                let dataSource = res.data.data;
                if(!dataSource || !dataSource.token){
                  this.$message('登录验证失败，token无效！');
                  return;
                }
                
                // store 值存储
                let auth = {};
                auth.token = dataSource.token;
                auth.fullName = dataSource.fullName;
                auth.jobType = dataSource.jobtype;
                auth.role = dataSource.role;
                auth.userName = dataSource.username;
                auth.email = dataSource.email;

                _this.$store.commit('login', auth);//_this.loginForm
                GLOBAL.userInfo = _this.$store.state.user;
                GLOBAL.token = _this.$store.state.user.token;

                var path = this.$route.query.redirect;
                this.$router.replace({path: path === undefined ? '/' : path});
            
              }
              
            }
        }).catch(error=>{
            // console.log(error.response.status);
            let errMsg = error.response.data.message;
            this.$message(errMsg);
            this.loading = false
            // this.$refs.loginBtn.$el.innerText = "登录";
            //let status = error.response.status;
            // switch(status){
            //   case 401:
            //     errMsg = '';
            // }

        })

      }
    },
    mounted(){
      this.logo = GLOBAL.global_logo_path;
      this.company = GLOBAL.global_company;
      if(GLOBAL.userInfo){
        this.loginForm.username = GLOBAL.userInfo.userName;
      }
    }

  }
</script>
 
<style scoped>
  .poster {
    background-position: center;
    height: 100%;
    width: 100%;
    background-size: cover;
    position: fixed;
    left: 0;
    margin-top: 20px;
  }
  body{
    margin: 0px;
  }
  .login-panel{
    margin: 0 auto;
    /* margin: 15% 0 20% 0; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
  }
  .login-container {
    border-radius: 10px;
    background-clip: padding-box;
    margin: 0 auto;
    width: 350px;
    padding: 35px 35px 15px 35px;
    background: #fff;
    border: 1px solid #f7f7f7;
    /* box-shadow: 0 2px 14px 0 rgb(0 0 0 / 5%); */
    box-shadow: 0 2px 14px 0 rgba(0,0,0,.12);
  }
  .el-input__inner{
    border-top-right-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    padding-left: 20px !important;
  }
  .login_title {
    margin: 0px auto 40px auto;
    text-align: center;
    color: #505458;
  }
  .el-button--primary:hover{
    opacity: 0.8;
  }
  .logo-wrap {
    margin-bottom: 25px;
    text-align: center;
  }
  .logo-wrap .logo {
    height: 35px;
  }

  .login-desc {
    margin-top: 30px;
    text-align: center;
    color: red;
    font-weight: bolder;
  }
</style>