<template>
  <div id="app" ref="app">
    <!-- <a :href="logoHref"><img :src="logo" class="logo" alt="梅花网"></a>  -->
    <router-view></router-view>
    <!-- <Search /> -->
    <!-- <div>
      <div class="crop-class">Copyright © 梅花网 沪ICP备05009163号 公网安备 31010502000234号</div>
    </div> -->
  </div>
</template>

<script>
// import global_variable from './common/global_variable';
// import Search from "../src/components/Search.vue"
// const defaultLogo = '../static/images/logo_blue.svg';
// const focuxyLogo = '.../static/logo_focuxy.png';
export default {
    name: 'App',
    data(){
      return{
          // logo:defaultLogo,
          // logoHref:'/'
      }
    },
    components: {
      // Search
    },
    mounted(){
      // let o = this.$route.query.o;
      // let dy = this.$route.query.dy;
      // if(o == 'f'){
      //   this.logo = focuxyLogo;
      //   global_variable.global_logo_path = focuxyLogo;
      //   global_variable.global_company = '新智科技';
      //   this.logoHref = '/#/index?o=f&v=1';
      // }
      // if(dy && dy == 'i'){
      //   global_variable.useDouyinApi = 'idata';
      // }
      // else{
      //   global_variable.useDouyinApi = 'meihua';
      // }

      // console.log(global_variable.useDouyinApi);
    }
}
</script>

<style>
body{
  margin: 0 auto;
  font-size:13px;
  /* background-color: #f9f9f9; */
}
a{
  cursor: pointer;
  color: #409eff;
  text-decoration: none;
}
div {
  box-sizing: border-box;
}
  .default-class{
    color: red;
  }
#app {
  font-family: PingFangSC-Regular, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 20px; */
  /* width: 50%;
  margin: 10% auto; */
  min-height: 100%;
}
.container {
  margin: 0 auto;
  max-width: 1100px;
}
/* .logo{
  width: 130px;
} */
.crop-class{
    /* position: absolute; */
    bottom: 0;
    margin-top: 50px;
    padding-bottom: 20px;
    width: 100%;
    font-size: 12px;
    color: #888888;
}
</style>
