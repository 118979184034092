import axios from 'axios'

// const API_URL = 'https://v3mice.meihua.info'
const API_URL = process.env.VUE_APP_URL

// axios.defaults.baseURL = API_URL;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


const service = axios.create({
  baseURL: API_URL,
  // timeout: 40000,
  headers: {
    // 'X-Requested-With': 'XMLHttpRequest',
    // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    // 'Content-Type': 'application/json; charset=utf-8',
  },
})
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data;
    return res
  },
  error => {
    console.log('request error: ', error)
  }
)
export default service