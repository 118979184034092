<template>
  <div>
    <div>
      <Navigate :naviTitle="naviTitle" />
    </div>
    <div class="top-blank">&nbsp;</div>
    <div class="translate-con">
      <div class="panel-title">批量解析</div>
      <div>
        <el-row>
          <el-col :span="8">
            <div class="panel-label">
              请在下方文本框输入需要解析的链接（微博及小红书），多条需回车换行
            </div>
            <el-input
              ref="translateTextarea"
              type="textarea"
              :rows="8"
              placeholder="如：https://weibo.com/1642634100/JvwwbguMt"
              v-model="textareaVlue"
            >
            </el-input>
            <div></div>
          </el-col>
          <el-col :span="16">
            <div class="translate-status">
              <div class="panel-label">
                共解析到【{{ translateData.length }}】条待解析链接，完成进度【{{
                  curRunningIndex
                }}/{{ translateData.length }}】...
              </div>
              <div>
                <el-progress :percentage="complatedProgress"></el-progress>
              </div>
              <div>
                <el-table
                  max-height="500"
                  height="600"
                  :data="translateData"
                  style="width: 100%"
                >
                  <el-table-column prop="num" label="编号" width="60">
                  </el-table-column>
                  <el-table-column prop="handleStatus" label="状态">
                    <template slot-scope="scope">
                      <!-- <el-tag :type="scope.row.handleStatus == '已完成' ? 'success' : 'info'" -->
                      <el-tag
                        :type="statusTagMap[scope.row.handleStatus]"
                        disable-transitions
                        >{{ scope.row.handleStatus }}
                      </el-tag>
                    </template>
                  </el-table-column>
                    <el-table-column prop="platformName" label="平台" width="100">
                  </el-table-column>
                  <el-table-column
                    prop="originText"
                    label="主贴链接"
                    width="200"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="translateText"
                    label="id"
                    width="200"
                  >
                  </el-table-column>
                  <el-table-column prop="date" label="日期" width="200">
                  </el-table-column>
                  <el-table-column prop="content" label="内容" width="200">
                  </el-table-column>
                  <el-table-column
                    prop="attitudesCount"
                    label="点赞"
                    width="200"
                  >
                  </el-table-column>
                  <el-table-column prop="repostsCount" label="转发" width="200">
                  </el-table-column>
                  <el-table-column
                    prop="commentsCount"
                    label="评论"
                    width="200"
                  >
                  </el-table-column>
                  <el-table-column prop="favoriteCount" label="收藏" width="200"></el-table-column>
                  <el-table-column prop="screenName" label="昵称" width="200">
                  </el-table-column>
                  <el-table-column prop="location" label="地区" width="200">
                  </el-table-column>
                  <el-table-column prop="gender" label="性别" width="200">
                  </el-table-column>
                  <el-table-column
                    prop="followersCount"
                    label="粉丝量"
                    width="200"
                  >
                  </el-table-column>
                  <el-table-column prop="verified" label="是否认证" width="200">
                  </el-table-column>
                  <el-table-column
                    prop="verifiedType"
                    label="认证类型"
                    width="200"
                  >
                  </el-table-column>
                </el-table>
              </div>
              <br />
              <el-button
                type="primary"
                class="translate-go"
                @click="handleTransition()"
                round
                >开始执行</el-button
              >
              <span class="download-span" v-if="loadingIcon">
                <i class="el-icon-loading"></i>&nbsp;&nbsp;Running...</span
              >
              <span class="download-span" v-if="downloadBtn"
                ><a @click="downloadData()">
                  <i class="el-icon-download"></i>下载</a
                ></span
              >
            </div>
            <div></div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import global_variable from "../common/global_variable";
import Navigate from "../components/Navigate.vue";
import request from "../utils/request";

const PlatformType = {
    WEIBO: 'weibo',
    XIAOHONGSHU: 'xiaohongshu',
}

const parsePlatforms = [
    {key: PlatformType.WEIBO, domain: 'weibo.com'},
    {key: PlatformType.XIAOHONGSHU, domain: 'xiaohongshu.com'},
]


export default {
  data() {
    return {
        naviTitle: "微博解析",
        textareaVlue: "",
        complatedProgress: 0,
        curRunningIndex: 0,
        translateData: [
            // {
            //     num:1,
            //     originText: 'https://weibo.com/1642634100/JvwwbguMt',
            //     translateText: '',
            //     handleStatus:'未完成'
            // }
        ],
        downloadBtn: false,
        loadingIcon: false,

        runningStatus: false,
        runningIndex: 0,
        lastTranslateList: [],
        reqStatus: true,
        curTranslateObj: {},
        reqErrorTry: 0,
        reqDataErrorTry: 0,

        statusTagMap: {
            已完成: "success",
            未完成: "info",
            失败: "error",
        },
    };
  },
  methods: {
    getIdByXiaohongshuUrl(url='') {
        const sIdx = url.lastIndexOf('/') + 1
        const eIdx = url.indexOf('?')
        const id = eIdx >= 0 ? url.substring(sIdx, eIdx) : url.substring(sIdx)
        return id
    },
    handleTransition() {
        this.parseTranslateLine();
        this.startTranslate2();
    },
    parseTranslateLine() {
      let areaText = this.textareaVlue;
      if (areaText) {
        let texts = this.textareaVlue.split("\n");
        var lineList = [];
        for (var index in texts) {
          let urlText = texts[index];
          if (urlText) {
            let lineObj = {};
            const platformItem = parsePlatforms.find(item => urlText.indexOf(item.domain) >= 0) || {}
            lineObj.num = parseInt(index) + 1;
            lineObj.originText = urlText;
            lineObj.translateText = "";
            lineObj.handleStatus = "未完成";
            lineObj.PlatformType = platformItem.key

            if (platformItem.key === PlatformType.XIAOHONGSHU) {
                lineObj.currId = this.getIdByXiaohongshuUrl(urlText)
            }

            lineList.push(lineObj);
          }
        }

        this.translateData = lineList;
      } else {
        this.$message("请输入主贴链接");
        this.$refs["translateTextarea"].focus();
      }
    },
    startTranslate2() {
      this.loadingIcon = true;
      if (this.translateData) {
        this.lastTranslateList = this.translateData;
        // time1 = setInterval(() => {
          console.log("once again...");
          if (!this.runningStatus && this.lastTranslateList.length > 0) {
            this.curRunningIndex = this.runningIndex;
            if (this.runningIndex < this.lastTranslateList.length) {
              this.curTranslateObj = this.lastTranslateList[this.runningIndex];
              //translate2(this.lastTranslateList[this.runningIndex].originText);
            //   console.log('this.curTranslateObj', this.curTranslateObj)
              if (this.curTranslateObj.PlatformType === PlatformType.XIAOHONGSHU) {
                  this.getXiaohongshuId(this.lastTranslateList[this.runningIndex].currId);
              } else {
                  this.getWbId(this.lastTranslateList[this.runningIndex].originText);
              }

              this.complatedProgress = parseFloat(((this.runningIndex / this.lastTranslateList.length) * 100).toFixed(2));
            } else {
              this.complatedProgress = 100;
              console.log("endding...");
              this.downloadBtn = true;
              this.loadingIcon = false;
            //   window.clearInterval(time1);
            }
          } else {
            this.loadingIcon = false;
            // _this.runningStatus = false;
          }
        // }, 5000);
      }
    },
    startTranslate: async function () {
      if (this.translateData) {
        console.log("ss");
      }
    },
    getWbId(url) {
        const _this = this
        if (url) {
            if (_this.reqErrorTry >= 2) {
                _this.curTranslateObj.translateText = "";
                _this.curTranslateObj.handleStatus = "未完成";
                _this.runningIndex++;
                _this.reqErrorTry = 0;
                console.log("请求两次失败，已跳过");
                _this.startTranslate2()
                return "";
            }
            if (_this.reqDataErrorTry >= 3) {
                _this.curTranslateObj.translateText = "";
                _this.curTranslateObj.handleStatus = "失败";
                _this.runningIndex++;
                _this.reqDataErrorTry = 0;
                console.log("请求数据两次失败，已跳过");
                _this.startTranslate2()
                return "";
            }

            // let req_url = apiUrl + '/api/data/weibo/search';
            // let req_url = apiUrl + "/api/data/weibo/resolve";
            // let encodeUrl = encodeURIComponent(url);

            let paras = {
                c: "1",
                type: "post",
                url: url,
                token: global_variable.token,
            };
            request({
                url: "/api/data/weibo/resolve",
                method: "POST",
                data: paras,
            }).then((res) => {
                if (res) {
                // console.log('ret data', res)
                // if(res.retcode=='000000'){
                    if (res.success) {
                        _this.reqStatus = true;
                        if (_this.reqStatus) {
                            this.runningIndex++;
                        }
                        if (url == _this.curTranslateObj.originText) {
                        let wbItem = res.data[0];
                        let wbId = wbItem.idstr;
                        _this.curTranslateObj.translateText = wbId;
                        _this.curTranslateObj.platformName = '微博';
                        _this.curTranslateObj.handleStatus = "已完成";
                        _this.curTranslateObj.date = wbItem.created_at;
                        _this.curTranslateObj.content = wbItem.text;
                        _this.curTranslateObj.attitudesCount = wbItem.attitudes_count;
                        _this.curTranslateObj.repostsCount = wbItem.reposts_count;
                        _this.curTranslateObj.commentsCount = wbItem.comments_count;
                        _this.curTranslateObj.screenName = wbItem.user.screen_name;
                        _this.curTranslateObj.location = wbItem.user.location;
                        let genderStr = "";
                        if (wbItem.user.gender) {
                            if (wbItem.user.gender == "m") {
                            genderStr = "男";
                            } else if (wbItem.user.gender == "f") {
                            genderStr = "女";
                            }
                        }
                        _this.curTranslateObj.gender = genderStr;
                        _this.curTranslateObj.followersCount = wbItem.user.followers_count;
                        _this.curTranslateObj.verified = wbItem.user.verified == true ? "已认证" : "未认证";
                        let verifiedTypeStr = "";
                        var vType = wbItem.user.verified_type;
                        if (vType != null) {
                            if (vType == 0) {
                                verifiedTypeStr = "个人橙V认证";
                            } else if (vType == 1 || vType == 2 || vType == 3) {
                                verifiedTypeStr = "企业蓝V认证";
                            } else if (vType == 220) {
                                verifiedTypeStr = "微博达人";
                            } else {
                                verifiedTypeStr = "普通用户";
                            }
                        }
                        _this.curTranslateObj.verifiedType = verifiedTypeStr;

                        // let wbItem = res.data.data || {};
                        // let wbId = wbItem.id;
                        // _this.curTranslateObj.translateText = wbId;
                        // _this.curTranslateObj.handleStatus = '已完成';
                        // _this.curTranslateObj.date = wbItem.publishDateStr;
                        // _this.curTranslateObj.content = wbItem.content;
                        // _this.curTranslateObj.attitudesCount = wbItem.likeCount;
                        // _this.curTranslateObj.repostsCount = wbItem.shareCount;
                        // _this.curTranslateObj.commentsCount = wbItem.commentCount;
                        // _this.curTranslateObj.screenName = wbItem.from.name;
                        // _this.curTranslateObj.location = wbItem.from.url;
                        // let genderStr = '';
                        // if(wbItem.from.extend.gender){
                        //     if(wbItem.from.extend.gender == 'm'){
                        //         genderStr = '男';
                        //     }
                        //     else if(wbItem.from.extend.gender == 'f'){
                        //         genderStr = '女';
                        //     }
                        // }
                        // _this.curTranslateObj.gender = genderStr;
                        // _this.curTranslateObj.followersCount = wbItem.from.extend.followers_count;
                        // _this.curTranslateObj.verified = wbItem.from.extend.verified==true?'已认证':'未认证';
                        // let verifiedTypeStr = '';
                        //     var vType = wbItem.from.extend.verified_type;
                        //     if(vType != null){
                        //         if(vType == 0)
                        //             verifiedTypeStr = '个人橙V认证';
                        //         else if(vType == 1 || vType == 2 || vType == 3 )
                        //             verifiedTypeStr = '企业蓝V认证';
                        //         else if(vType == 220)
                        //             verifiedTypeStr = '微博达人';
                        //         else
                        //             verifiedTypeStr = '普通用户';
                        //     }
                        // _this.curTranslateObj.verifiedType = verifiedTypeStr;
                        }
                        _this.runningStatus = false;
                        _this.$nextTick(() => {
                            _this.startTranslate2()
                        })
                        return _this.curTranslateObj.translateText;
                    } else {
                        _this.reqStatus = false;
                        _this.reqDataErrorTry++;
                        _this.runningStatus = false;
                    }
                } else {
                    _this.reqStatus = false;
                    _this.reqDataErrorTry++;
                }
                _this.runningStatus = false;
                _this.startTranslate2()
            }).catch((err) => {
                console.log("err",err);
                _this.reqErrorTry++;
                _this.reqStatus = false;
                _this.runningStatus = false;
                _this.startTranslate2()
                return "";
            });
        }
    },
    getXiaohongshuId(currId) {
        const _this = this
        if (currId) {
            if (_this.reqErrorTry >= 2) {
                _this.curTranslateObj.translateText = "";
                _this.curTranslateObj.handleStatus = "未完成";
                _this.runningIndex++;
                _this.reqErrorTry = 0;
                console.log("请求两次失败，已跳过");
               _this.startTranslate2()
                return "";
            }
            if (_this.reqDataErrorTry >= 3) {
                _this.curTranslateObj.translateText = "";
                _this.curTranslateObj.handleStatus = "失败";
                _this.runningIndex++;
                _this.reqDataErrorTry = 0;
                console.log("请求数据两次失败，已跳过");
                _this.startTranslate2()
                return "";
            }

            let paras = {
                c: "1",
                id: currId,
                token: global_variable.token,
            };
            request({
                url: "/api/data/xiaohongshu/note",
                method: "POST",
                data: paras,
            }).then((res) => {
                if (res.success) {
                  if (res.code === 200) {
                    _this.reqStatus = true;
                    if (_this.reqStatus) {
                        this.runningIndex++;
                    }
                    if (currId == _this.curTranslateObj.currId) {
                      let xhsItem = res.data || {};
                      let xhsItemDetail = xhsItem.note_detail || {}
                      let xhsId = xhsItemDetail.id;
                      _this.curTranslateObj.translateText = xhsId;
                      _this.curTranslateObj.platformName = '小红书';
                      _this.curTranslateObj.handleStatus = "已完成";
                      _this.curTranslateObj.date = xhsItem.gmt_publish_str;
                      _this.curTranslateObj.content = xhsItem.desc;
                      _this.curTranslateObj.attitudesCount = xhsItemDetail.liked_count;
                      _this.curTranslateObj.repostsCount = xhsItemDetail.shared_count;
                      _this.curTranslateObj.commentsCount = xhsItemDetail.comments_count;
                      _this.curTranslateObj.favoriteCount = xhsItemDetail.collected_count;
                      _this.curTranslateObj.screenName = xhsItem.author_nickname;
                      _this.curTranslateObj.location = xhsItemDetail.ip_location;

                      // _this.curTranslateObj.translateText = xhsId;
                      // _this.curTranslateObj.platformName = '小红书';
                      // _this.curTranslateObj.handleStatus = "已完成";
                      // _this.curTranslateObj.date = xhsItem.publishDateStr;
                      // _this.curTranslateObj.content = xhsItem.content;
                      // _this.curTranslateObj.attitudesCount = xhsItem.likeCount;
                      // _this.curTranslateObj.repostsCount = xhsItem.shareCount;
                      // _this.curTranslateObj.commentsCount = xhsItem.commentCount;
                      // _this.curTranslateObj.favoriteCount = xhsItem.favoriteCount;
                      // _this.curTranslateObj.screenName = xhsItem.posterScreenName;
                      // _this.curTranslateObj.location = xhsItem.location;

                    }
                    _this.runningStatus = false;
                    _this.$nextTick(() => {
                        _this.startTranslate2()
                    })
                    return _this.curTranslateObj.translateText;
                  } else {
                      _this.reqStatus = false;
                      _this.reqDataErrorTry++;
                      _this.runningStatus = false;
                  }
                } else {
                  _this.reqStatus = false;
                  _this.reqDataErrorTry++;
                }
                _this.runningStatus = false;
                _this.startTranslate2()
            }).catch((err) => {
                console.log("err", err);
                // if (!err.response.data.success) {
                //     _this.reqErrorTry++;
                // }
                _this.reqErrorTry++;
                _this.reqStatus = false;
                _this.runningStatus = false;
                _this.startTranslate2()
                return "";
            });
        }
    },  
    downloadData(){
        let fileName = '批量解析_' + moment(new Date()).format('YYYYMMDDHHmmss');
        let tHeader = ['序号','平台','链接','ID','日期','内容','点赞','转发','评论','收藏','昵称','地区','性别','粉丝','是否认证','认证类型','状态'];
        let filterVal = ['num','platformName','originText','translateText','date','content','attitudesCount','repostsCount','commentsCount','favoriteCount','screenName','location','gender','followersCount','verified','verifiedType','handleStatus'];
        global_variable.exportExcel(this.translateData, fileName,tHeader, filterVal);
    }
  },
  components: {
    Navigate,
  },
};
</script>
<style>
.translate-con {
  width: 96%;
  padding: 20px;
  text-align: left;
}
.translate-status {
  padding: 0 0 0 20px;
}
.translate-go {
  width: 200px;
}
.download-span {
  margin-left: 20px;
}
</style>