
<template>
    <div class="container-water-fall">
        <div class="searchBar" id="searchBar" :style="{display:searchBarDisplay}">
          <ul :class="searchBarFixed == true ? 'isFixed' :''">
            <div class="search-result">
              <span>检索到<span>{{data.length}}</span>条数据</span>
              <span style="margin-left:20px;"><a @click="downloadData()"  :style="{color:platColor}" > <i class="el-icon-download"></i>下载</a></span>
              
            </div>
          </ul>
        </div>
        <el-dialog
          title="下载进度"
          :visible.sync="dialogVisible"
          width="80%"
          :close-on-click-modal="handleClose" 
          :show-close="false"
          >
          <div style="padding:10px 0;">
                  <el-progress :percentage="complatedProgress"></el-progress>
              </div>
          <span slot="footer" class="dialog-footer">
            <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
            <el-button type="primary" @click="closeFun()">终止下载</el-button>
          </span>
        </el-dialog>
        <!-- <waterfall :col="col" :data="data" >
        <template> -->

            <!-- <div class="cell-item" v-for="(item,index) in data" :key="'info'+index" style="position: relative;" v-show="false">
              <div class="search-account-panel" v-if="false">
                
                  <div class="profile-body">
                      <div  class="profile-hr" >
                          <div class="profile-left"><img :src="item.avatarUrl" /></div>
                          <div class="profile-right">
                              <div class="profile-scname">{{item.screenName}}</div>
                              <div class="profile-des">抖音号：{{item.douyinID}}&nbsp;&nbsp;/&nbsp;&nbsp;粉丝数：{{item.fansCount}}</div>
                              <el-tooltip class="item" effect="dark" :content="item.description" placement="bottom">
                                  <div class="profile-des">简介：{{item.description==''?'-':acc.description}}</div>
                              </el-tooltip>
                          </div>
                          <div class="clear">&nbsp;</div>
                      </div>
                  </div>
                  
              </div>
            </div> -->
            
          <div class="ddd">

            <div v-if="(searchPlat == 'weibo' || searchPlat == 'zhihu')?false:true">
              <div class="cell-item" v-for="(item,index) in data" :key="'index2' + index" style="position: relative;" >

                    <el-tag v-if="compareDate(item.publishDate)" effect="dark" type="danger" class="newest-tag" :title="item.publishDateStr" :alt="item.publishDateStr">最新</el-tag>

                      <a :href="item.webUrl" style="text-decoration-line: none;" target="_blank">
                        <span class="video-action" v-if="(searchPlat=='xiaohongshu' || searchPlat=='weibo') ?false:true"><i class="el-icon-video-play"></i></span>
                        <div v-if="searchPlat=='kuaishou'?true:false" class="div-item-video">
                          <video style="width:100%;height: 100%;background-color:#010000;" :src="item.videoUrls"></video>
                        </div>
                        <div v-if="searchPlat=='kuaishou'?false:true" class="div-item-img">
                          <img :src="item.coverUrl" alt=""> 
                        </div>
                      </a>
                    
                    
                    <div class="item-body">
                      <div class="item-desc" v-light="[item.description, light]"><a :href="item.webUrl" style="text-decoration-line: none;color:#696969;" target="_blank">{{searchPlat=='xiaohongshu'?item.title:item.description}}</a></div>
                      <div class="item-footer" >
                          <div
                              v-if="false"
                              class="avatar"
                              :style="{backgroundImage : `url('')` }"
                          ></div>
                          <div class="name"> <a :href="item.profileUrl" target="_blank">@{{item.posterScreenName}}</a> </div>
                          <div class="like">
                              <div class="like-total">{{item.publishDateStr}}</div>
                          </div>
                          
                      </div>
                      <div style="margin-top: 8px;">
                          <div >
                            <span class="view-count"  v-if="searchPlat=='kuaishou' || searchPlat=='xigua'?true:false" >播放{{numberAbbreviation(item.viewCount)}}</span>
                            <span class="view-count" >点赞{{numberAbbreviation(item.likeCount)}}</span><span class="view-count" >转发{{numberAbbreviation(item.shareCount)}}</span><span class="view-count" >评论{{numberAbbreviation(item.commentCount)}}</span> </div>
                      </div>
                      
                    </div>
                </div>

            </div>

            <div v-for="(item,index) in data" :key="index" style="position: relative;" >
              <div class="search-account-panel-weibo" v-if="searchPlat == 'weibo'?true:false">
                  <div class="profile-body-weibo">
                      <div  class="profile-hr-weibo" >
                          <div class="profile-left-weibo"> <a :href="item.profileUrl" target="_blank"><img :src="item.avatarUrl" /></a> </div>
                          <div class="profile-right-weibo">
                              <div><span class="profile-scname-weibo label-left-weibo"><a :href="item.profileUrl" target="_blank">{{item.posterScreenName}}</a>  </span> <span class="profile-des-weibo label-right-weibo">粉丝数：{{item.fansCount}}</span> </div>
                              <div class="clear">&nbsp;</div>
                              <!-- <el-tooltip class="item" effect="dark" :content="item.description" placement="bottom"> -->
                              <div class="profile-des-weibo" v-light="[item.description, light]" :title="item.description">
                              </div>

                              <div class="original-weibo-panel" style="background-color:#f2f2f5;" v-if="item.original == '转发'?true:false">
                                  <div><span class="profile-scname-weibo label-left-weibo"><a :href="item.profileUrl" target="_blank">@{{item.originalPosterScreenName}}</a>  </span> <span class="profile-des-weibo label-right-weibo"></span> </div>
                                  <div class="clear">&nbsp;</div>
                                  <div class="profile-des-weibo" v-light="[item.originalText, light]" :title="item.originalText">
                                  </div>

                                  <div v-if="(item.imageUrls&&item.imageUrls.length>0)?true:false">
                                    <img :src="item.firstImage" alt="" class="label-left img-con-weibo" @click="viewLargeImage(item.imageUrls)">
                                    <el-image-viewer 
                                      v-if="showViewer" 
                                      :z-index="viewerZindex"
                                      :on-close="closeViewer"
                                      :url-list="viewerData" />
                                  </div>
                                  <div class="clear">&nbsp;</div>
                                  <div class="profile-des-weibo">
                                    <span class="label-left-weibo"><a :href="item.originalUrl" target="_blank">{{item.originalPublishDateStr}}</a>
                                    </span> 
                                    <span v-if="item.source?true:false">来自 <span v-html="item.originalSource"></span></span> 
                                    <span class="label-right-weibo"><span>转发&nbsp;{{item.originalShareCount}}</span>  
                                    <span>评论&nbsp; {{item.originalCommentsCount}}</span> 
                                    <span>点赞&nbsp;{{item.originalLikeCount}}</span>  </span> 
                                  </div>

                              </div>

                              <!-- </el-tooltip> -->
                              <div v-if="(item.imageUrls&&item.imageUrls.length>0)?true:false">
                                <img :src="item.firstImage" alt="" class="label-left img-con-weibo" @click="viewLargeImage(item.imageUrls)">
                                <el-image-viewer 
                                  v-if="showViewer" 
                                  :z-index="viewerZindex"
                                  :on-close="closeViewer"
                                  :url-list="viewerData" />
                              </div>
                              <div class="clear">&nbsp;</div>
                              <div class="profile-des-weibo">
                                <span class="label-left-weibo"><el-tag :type="item.original=='原创'?'':'info'" class="tag-small">{{item.original}}</el-tag>&nbsp;<a :href="item.webUrl" target="_blank">{{item.publishDateStr}}</a>
                                </span> 
                                 <span v-if="item.source?true:false">来自 <span v-html="item.source"></span></span> 
                                 <span class="label-right-weibo"><span>转发&nbsp;{{item.shareCount}}</span>  
                                 <span>评论&nbsp; {{item.commentCount}}</span> 
                                 <span>点赞&nbsp;{{item.likeCount}}</span>  </span> 
                              </div>
                              
                          </div>
                          <div class="clear">&nbsp;</div>
                      </div>
                  </div>
                    
                </div>
            </div>

            <div v-if="zhihuTableVisible">
              <el-table
                :data="data"
                style="width: 100%"
                :default-sort = "{prop: 'date', order: 'descending'}"
                >
                <el-table-column
                  label="标题"
                  sortable
                  width="280">
                    <template slot-scope="scope">
                      <p ><a :href="scope.row.webUrl" target="_blank" style="color:#409eff;"><span v-light="[scope.row.description, light]">{{ scope.row.description }}</span> </a> </p>
                    </template>
                </el-table-column>
                <el-table-column
                  prop="publishDateStr"
                  label="日期"
                  sortable
                  width="180">
                </el-table-column>
                <el-table-column
                  prop="viewCount"
                  label="浏览"
                  sortable
                  :formatter="formatter">
                </el-table-column>
                <el-table-column
                  prop="followerCount"
                  label="关注"
                  sortable
                  :formatter="formatter">
                </el-table-column>
                <el-table-column
                  prop="answerCount"
                  label="回答"
                  sortable
                  :formatter="formatter">
                </el-table-column>
                <el-table-column
                  prop="commentCount"
                  label="评论"
                  sortable
                  :formatter="formatter">
                </el-table-column>
                <el-table-column
                  prop="tags"
                  label="标签"
                  :formatter="formatter">
                  <template slot-scope="scope">
                      <p ><span v-light="[scope.row.tags, light]">{{ scope.row.tags }}</span></p>
                    </template>
                </el-table-column>
              </el-table>
            </div>

          </div>
<!-- 
        </template>
        </waterfall> -->
        <loading :show="loading"/>
    </div>
</template>

<script>
import axios from 'axios';
import loading from "./loading";
import Vue from 'vue'
import VueHighLight from 'vue-light-word'
import moment from 'moment'

import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import global_variable from '../common/global_variable';

// import Blob from './excel/Blob'
const {export_json_to_excel}  = require('../excel/Export2Excel')
require('script-loader!file-saver');
require('../excel/Blob.js'); //改成相对路径
require('script-loader!xlsx/dist/xlsx.core.min');

VueHighLight(Vue, {defaultClass: 'default-class'})

export default {
  props: ['title','dataList','searchBarDisplay','light','searchPlat','searchCommand','platColor'],
  components: {
    loading,
    ElImageViewer
  },
  data() {
    return {
      data: [],
      col: 4,
      loading: false,
      apiUrl : process.env.VUE_APP_URL,
      cellItem:'cell-item',
      gitHubData: {},
      originData: [],
      showViewer : false,
      viewerData : [],
      viewerZindex: 10000,
      searchBarFixed:false,
      dataIndex:0,
      newDataList:[],
      complatedProgress:0,
      dialogVisible:false,
      handleClose:false,
      downloadStatus:true,
      reqErrorCount:0,
      zhihuTableVisible:false
    };
  },
  computed: {
    itemWidth() {
      return 133 * 0.5 * (document.documentElement.clientWidth / 375);
    },
    gutterWidth() {
      return 10 * 0.5 * (document.documentElement.clientWidth / 375);
    }
  },
  methods: {
    downloadXiaohongshu2(){
      if(this.downloadStatus){
        if(this.dataList &&this.dataList.length > 0){
          let item = this.dataList[this.dataIndex];
          if(item){
              let paras = {
                // c:'1',// 表示调用供应商接口
                id:item.id,
                token:global_variable.token
              }
              //如果是重试，则添加c=1参数，表示请求供应商接口
              if(this.reqErrorCount>0){
                // this.$delete(paras, 'c');
                paras.c = 1
              }
              let reqUrl = this.apiUrl + '/api/data/xiaohongshu/note';
              axios.post(reqUrl, paras).then(res=>{
                if(res.data.retcode == '000000' || res.data.success){
                  if(res.data.data.length>0){
                    let term = res.data.data[0];
                    //'description', 'publishDateStr', 'webUrl','original','likeCount','shareCount','commentCount','posterScreenName','gender','verified','verifiedType','location','id','profileUrl'
                    let pds = moment(term.publishDate*1000).format('YYYY-MM-DD HH:mm:ss');
                    this.newDataList.push(Object.assign(item,{description:term.title, publishDateStr:pds, likeCount:term.likeCount, shareCount:term.shareCount, commentCount:term.commentCount, posterScreenName:term.posterScreenName, profileUrl:term.posterId, content:term.content, favoriteCount:term.favoriteCount  }));
                  }
                  // else if(res.data.success){
                  //   let term = res.data.data[0];
                  //   //'description', 'publishDateStr', 'webUrl','original','likeCount','shareCount','commentCount','posterScreenName','gender','verified','verifiedType','location','id','profileUrl'
                  //   let pds = moment(term.publishDate*1000).format('YYYY-MM-DD HH:mm:ss');
                  //   this.newDataList.push(Object.assign(item,{description:term.title, publishDateStr:pds, likeCount:term.likeCount, shareCount:term.shareCount, commentCount:term.commentCount, posterScreenName:term.posterScreenName, profileUrl:term.posterId, content:term.content, favoriteCount:term.favoriteCount  }));
                  // }
                  

                  // // 请求成功清空错误
                  this.reqErrorCount = 0;
                  this.dataIndex++;
                  if(this.dataIndex<this.dataList.length){
                    this.complatedProgress = (this.dataIndex/this.dataList.length*100).toFixed(2);
                    // this.downloadXiaohongshu2();
                    this.downloadXiaohongshuTimeout();
                  }
                  else{
                    this.dataList = this.newDataList;
                    this.createExport();
                    this.complatedProgress = 100;
                    this.dialogVisible = false;
                  }

                } else {
                  this.handleReloadXiaohongshu()
                }
              }).catch((err)=>{
                console.log(err);
                this.handleReloadXiaohongshu()
                // // 同一id连续3次错误，跳过
                // if(this.reqErrorCount<=2){
                //   //切换接口重试
                //   this.reqErrorCount++;//接口请求出错计数，失败一次后，切换供应商接口
                //   // this.downloadXiaohongshu2();
                //   this.downloadXiaohongshuTimeout();
                // }
                // else{
                //   console.log(err);
                //   this.dataIndex++;
                //   if(this.dataIndex<this.dataList.length){
                //     // this.downloadXiaohongshu2();
                //     this.downloadXiaohongshuTimeout();
                //   }
                //   else{
                //     this.dataList = this.newDataList;
                //     this.createExport();
                //   }
                // }
                
              })
              // this.dataIndex++;
              // if(this.dataIndex<this.dataList.length){
              //   this.downloadXiaohongshu2();
              // }
              // else{
              //   this.dataList = this.newDataList;
              //   this.createExport();
              // }
            }


        }
      }
      else{
        console.log('stoped downloading');
      }
      
    },

    
    handleReloadXiaohongshu() {
      // 同一id连续3次错误，跳过
      if(this.reqErrorCount<=2){
        //切换接口重试
        this.reqErrorCount++;//接口请求出错计数，失败一次后，切换供应商接口
        // this.downloadXiaohongshu2();
        this.downloadXiaohongshuTimeout();
      } else{
        this.dataIndex++;
        if(this.dataIndex<this.dataList.length){
          // this.downloadXiaohongshu2();
          this.downloadXiaohongshuTimeout();
        }
        else{
          this.dataList = this.newDataList;
          this.createExport();
        }
      }
    },

    downloadXiaohongshuTimeout() {
      const timeNum = Math.floor(Math.random() * 6 + 5)
      setTimeout(() => {
        this.downloadXiaohongshu2()
      }, timeNum * 1000)
    },
    
    closeFun(){
      if(confirm('关闭弹窗将无法继续下载，确认关闭？')){
        this.downloadStatus = false;
        this.dialogVisible = false;
        console.log('downloading');
      }
      else{
        this.downloadStatus = true;
        this.dialogVisible = true;
        return;
      }
    },
    downloadXiaohongshu(){
      let newData = [];

      if(this.dataList &&this.dataList.length > 0){
        console.log('begin downloading xhs...');
        this.dataList.map((item)=>{
          if(item){
            let paras = {
              c:'1',//表示用梅花接口
              id:item.id,
              token:global_variable.token
            }
            let reqUrl = this.apiUrl + '/api/data/xiaohongshu/note';
            axios.post(reqUrl, paras).then(res=>{
              if(res){
                if(res.data.retcode == '000000' && res.data.data.length>0){
                  let term = res.data.data[0];
                  //'description', 'publishDateStr', 'webUrl','original','likeCount','shareCount','commentCount','posterScreenName','gender','verified','verifiedType','location','id','profileUrl'
                  let pds = moment(term.publishDate*1000).format('YYYY-MM-DD HH:mm:ss');
                  newData.push(Object.assign(item,{description:term.title, publishDateStr:pds, likeCount:term.likeCount, shareCount:term.shareCount, commentCount:term.commentCount, posterScreenName:term.posterScreenName, profileUrl:term.posterId, content:term.content, favoriteCount:term.favoriteCount  }));
                }
              }
            }).catch((err)=>{
              console.log(err)
            })
          }

        });

        this.dataList = newData;

      }
      else{
        this.$message('暂无需要下载的数据');
      }



    },
    compareDate(timestamp){
      let nowStamp = Date.now()/1000;
      // console.log(nowStamp + '-' + parseInt(timestamp) )
      if(nowStamp - parseInt(timestamp) <= 172800)
        return true;
      else
        return false;
    },
    clickTest(){
        console.log(this.dataList)
        this.data = this.dataList;
        axios.get()
    },
    viewLargeImage(viewData){
      this.viewerData = viewData;
      this.showViewer = true;
      console.log('')
    },
    // 关闭查看器
    closeViewer() {
      this.viewerData = [];
      this.showViewer = false;

    },
    numberAbbreviation(num){
      let returnNum = num;
      if(num){
        var n = parseInt(num);
        if(n > 10000){
          returnNum = (n/10000).toFixed(1) + 'W';
        }

      }
      return returnNum;
    },
    downloadData(){
      if(this.searchPlat == 'xiaohongshu'){
        // this.$message('暂不支持下载小红书数据.');
        if(confirm('因小红书下载更多字段需逐条下载，可能需要一些等待时间，是否确认下载？\r\n点击取消则仅下载标题、链接、ID、用户ID字段数据。')){
          console.log('downloading');
          this.dialogVisible = true;
          this.downloadXiaohongshu2();
        }
        else{
          this.createExport();
          this.dialogVisible = false;
          return;
        }
          
      }
      else{
        this.createExport();
      }

    },
    createExport(){
      let fileName = '';
      let tHeader = ['标题','日期', '链接','昵称','点赞','转发','评论','ID','用户主页','用户ID'];
      let filterVal = [ 'description', 'publishDateStr', 'webUrl','posterScreenName','likeCount','shareCount','commentCount','id','profileUrl','uid'];
      if(this.searchPlat == 'douyin'){
        fileName += '抖音';
      }
      else if(this.searchPlat == 'kuaishou'){
        fileName += '快手';
      }
      else if(this.searchPlat == 'bilibili'){
        fileName += '哔哩哔哩';
        tHeader = ['标题','日期', '链接','昵称','点赞','转发','评论','弹幕','投币','查看','收藏','ID','用户主页','用户ID'];
        filterVal = [ 'description', 'publishDateStr', 'webUrl','posterScreenName','likeCount','shareCount','commentCount','danmakuCount','coinCount','viewCount','favoriteCount','id','profileUrl','posterId'];
      }
      else if(this.searchPlat == 'xiaohongshu'){
        fileName += '小红书';
        tHeader = ['标题','日期', '链接','正文','昵称','播放量','点赞','转发','评论','收藏','ID','用户主页','用户ID'];
        filterVal = [ 'description', 'publishDateStr', 'webUrl','content','posterScreenName','viewCount','likeCount','shareCount','commentCount','favoriteCount','id','profileUrl','uid'];
      }
      else if(this.searchPlat == 'xigua'){
        fileName += '西瓜视频';
        if(this.searchCommand == 'account'){
          this.light = '特定号';
        }
      }
      else if(this.searchPlat == 'weibo'){
        if(this.searchCommand == 'account'){
          this.light = '特定号';
        }
        fileName += '微博';
        filterVal = [ 'description', 'publishDateStr', 'webUrl','original','likeCount','shareCount','commentCount','posterScreenName','gender','verified','verifiedType','location','id','profileUrl'];
        tHeader = ['标题','日期', '链接','原创/转载','点赞','转发','评论','昵称','性别','是否认证','认证类型','地区','微博ID','用户主页'];
      }
      else if(this.searchPlat == 'zhihu'){
        fileName += '知乎';
        tHeader = ['标题','日期', '链接','正文','浏览','关注','回答','评论','ID','标签'];
        filterVal = [ 'description', 'publishDateStr', 'webUrl','content','viewCount','followerCount','answerCount','commentCount','id','tags'];
      }
      fileName += '_' + this.light + '_' + moment(new Date()).format('YYYYMMDDHHmmss');
      
      this.exportExcel(fileName, tHeader, filterVal);
    }, 
    //下载为Excel
    exportExcel(fileName, tHeader, filterVal) {
      require.ensure([], () => {
        // 设置Excel的表格第一行的标题
        // const tHeader = ['标题','日期', '链接','昵称','播放量','点赞','转发','评论','ID','用户ID'];
      //  'date', 'struct1', 'struct2'对象的属性
        // const filterVal = [ 'description', 'publishDateStr', 'webUrl','posterScreenName','viewCount','likeCount','shareCount','commentCount','id','userName'];
        
        // 数据集
        const list = this.data;  
        const data = this.formatJson(filterVal, list);
        // excel名称可自定义
        const excelName = fileName;
        export_json_to_excel(tHeader, data, excelName);
      });
    },
    //格式化json
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      let offsetTop = document.querySelector('#searchBar').offsetTop
      scrollTop > offsetTop ? this.searchBarFixed = true : this.searchBarFixed = false;

      // let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      // //变量scrollHeight是滚动条的总高度
      // let scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;

      // if(scrollTop+windowHeight >= scrollHeight){
      //     console.log('load')
      // }
    },
    toGitHub() {
      window.open(
        "https://github.com/Rise-Devin/vue-waterfall2/blob/master/README.md",
        "_blank"
      );
    },

  },
  mounted() {
        window.addEventListener('scroll', this.handleScroll)
        this.data = this.originData;
        setInterval(() => {
        let newData = [];
        if(this.dataList){
          this.dataList.map((item)=>{
            if(this.searchPlat == 'kuaishou'){
              /**动态为数组对象添加新属性 */
              newData.push(Object.assign(item,{webUrl:'https://live.kuaishou.com/u/' + item.userName + '/' + item.otherIds, uid:item.userName}));
            }
            else if(this.searchPlat == 'douyin'){
              // newData.push(Object.assign(item,{webUrl:item.url,publishDateStr:moment(item.publishDate*1000).format('YYYY-MM-DD HH:mm:ss')}));
              newData.push(item)
            }
            else if(this.searchPlat == 'xiaohongshu'){
              newData.push(Object.assign(item,{webUrl:'https://www.xiaohongshu.com/discovery/item/' + item.id}));
            }
            else if(this.searchPlat == 'weibo'){
              newData.push(item);
            }
            else if(this.searchPlat == 'zhihu'){
              newData.push(item);
            }
            else{
              newData.push(item);
            }

          });
          this.data = newData;
          
        }
        if(this.data.length>0 && this.searchPlat == 'zhihu'){
          this.zhihuTableVisible = true;
        }
        else{
          this.zhihuTableVisible = false;
        }
        this.loading = false;
      }, 1000);
  },
  beforeDestroy(){
    window.removeEventListener('scroll', this.handleScroll)
  }
};
</script>

<style  lang="less" scoped>
* {
  margin: 0;
}
.original-weibo-panel{
  background-color: rgb(242, 242, 245);
  padding: 5px 0 5px 0;
}
.container-water-fall {
  padding:10px 0;
  width: 100%;
  box-sizing: border-box;
  h4 {
    padding-top: 56px;
    padding-bottom: 28px;
    font-family: PingFangSC-Medium;
    font-size: 36px;
    color: #000000;
    letter-spacing: 1px;
    text-align: justify;
  }
  button {
    // background-color: #ff0;
    // color: #24292e;
    // border: 1px solid rgba(27, 31, 35, 0.2);
    // border-radius: 0.25em;
    // width: 100px;
    // line-height: 26px;
    // font-size: 13px;
    // margin: 4px 0;
    // margin-right: 4px;
    //cursor: pointer;
    // outline: none;
    // &.blue-light {
    //   background: #27fbc2;
    // }
  }
.tag-small{
  line-height: 22px;
  height: 22px;
  padding: 0px 5px;
  // background-color: #ecf5ff;
  // border-color: #d9ecff;
  // color: #409EFF;
}
.search-account-panel-weibo{
    position: relative;
    top:10px;
    left: 0;
    background-color: #fff;
    z-index: 1000;
    padding:0 10px 10px 10px;
    // border: 1px solid #f7f7f7;
    min-width: 400px;
    // max-height: 364px;
}
.label-left-weibo{
    float: left;
}
.label-right-weibo{
    float: right;
}
.profile-body-weibo{
    margin-top: 0;
}
.profile-hr-weibo{
    border-bottom: solid 1px #eee;
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #F0F0F0;
    border-radius: 12px;
    overflow: hidden;
    box-sizing: border-box;
    transition: box-Shadow 0.5s, transform 0.5s;
}
.profile-hr-weibo:hover{
    // background-color: aliceblue;
    box-shadow: 0 4px 12px 0 rgba(52, 63, 75, 0.06), 0 10px 40px 0 rgba(52, 63, 75, 0.08), 0 0 0 1px rgba(52, 63, 75, 0.02);
    transform: translateY(-5px); 
}
.profile-left-weibo{
    float: left;
    width: 10%;
    min-width: 59px;
}
.profile-right-weibo{
    float: left;
    width: 89%;
}
.profile-left-weibo img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: solid 2px #cccccc;
    background-color: #f2f6fc;
}
.profile-right-weibo img{
    width: 40px;
    height: 40px;
}
.profile-right-weibo .profile-scname-weibo{
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    padding: 0 10px 10px 10px;
}
.profile-scname-weibo a{
  color: #333 !important;
  text-decoration-line: none;
}
.profile-right-weibo .profile-des-weibo{
    overflow: hidden;
    margin-top: 10px;
    text-align: left;
    padding: 0 5px 5px 10px;
    color: #8c8c8c;
    max-height: 100px;
}
.img-con-weibo{
  margin-left: 10px;
  width: 110px !important;
  height: 110px !important;
  cursor: pointer;
}
.profile-des-weibo a{
    color:#808080 !important;
    text-decoration-line: none !important;
}
.profile-des-weibo span{
  // margin-left: 5px;
}
  // button:hover {
  //   // background-image: linear-gradient(-180deg, #fafbfc, #ccc 90%);
  // }
  .default-class{
    color: red;
}
  .cell-item:hover {
    box-shadow: 0 4px 12px 0 rgba(52, 63, 75, 0.06), 0 10px 40px 0 rgba(52, 63, 75, 0.08), 0 0 0 1px rgba(52, 63, 75, 0.02);
    transform: translateY(-5px); }
  .cell-item {
    // width: 24vw;
    // height: 51vw;
    width: 286px;
    height: 611px;
    float: left;
    // margin-bottom: 18px;
    margin-right: 8px;
    margin-bottom: 20px;
    background: #ffffff;
    border: 1px solid #F0F0F0;
    border-radius: 12px 12px 12px 12px;
    overflow: hidden;
    box-sizing: border-box;
    transition: box-Shadow 0.5s, transform .5s;
    img {
      // border-radius: 12px 12px 0 0;
      width: 100%;
      // height: auto;
      max-height:100%;
      display: block;
    }
    .item-body {
      // border: 1px solid #F0F0F0;
      padding: 8px;
      .item-desc {
        font-size: 14px;
        color: #333333;
        line-height: 16px;
        font-weight: bold;
        text-align: left;
        max-height: 30px;
        overflow: auto;
      }
      .item-footer {
        margin-top: 8px;
        position: relative;
        display: flex;
        align-items: center;
        .avatar {
          width: 44px;
          height: 44px;
          border-radius: 50%;
          background-repeat: no-repeat;
          background-size: contain;
        }
        .name {
          max-width: 150px;
          font-size: 13px;
          color: #999999;
          overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
        }
        .name a{
          color: #999999;
          text-decoration-line: none;
        }
        .like {
          position: absolute;
          right: 0;
          display: flex;
          align-items: center;
          &.active {
            i {
            }
            .like-total {
              color: #FF4479;
            }
          }
          i {
            width: 28px;
            display: block;
          }
          .like-total {
            margin-left: 10px;
            font-size: 12px;
            color: #696969;
          }
          a {
              text-decoration-line: none;
          }
        }
      }
    }
  }
}
.div-item-video{
  width:100%;
  max-height: 100%;
  height:83%;
  background-color:#010000;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items:center;
}
.div-item-img{
  width:100%;
  height: 83%;
  background-color:#010000;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items:center;
}
.githubdata {
  float: right;
  margin-right: 90px;
  img {
    width: 14px;
    // height: 16px;
  }
}
.video-action{
    position: absolute;
    top: 35%;
    left: 40%;
}
.video-action i{
    font-size: 50px;
    color:#d9e5ff;
}
.view-count{
    font-size: 12px;
    color: #696969;
    margin-right: 14px;
}
.search-result{
  text-align: left;
}
.newest-tag{
  position: absolute;
  margin: 6px;
  line-height: 20px;
  height: 22px;
  left: 0;
}
.stickyTop {
    top: 0;
    z-index: 10;
  }
  .tab {
    height: 30px;
    line-height: 30px;
    background-color: greenyellow;
  }
  .searchBar{
  .isFixed{
    position:fixed;
    background-color:#Fff;
    top:0;
    z-index:999;
  }
  ul {
    width:100%;
    height: 40px;
    line-height: 40px;
    display: flex;
    padding-left: 0;
    li {
      font-size: 0.8rem;
      text-align: center;
      flex: 1;
      i {
        font-size: 0.9rem;
        padding-left: 5px;
        color: #ccc;
      }
    }
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 10px;
  }
}
</style>