let showDataList = []
let token = ''
let userInfo = {}
//配置抖音接口使用梅花到还是IData，meihua/idata
let useDouyinApi = 'meihua'
let global_logo_path = '../static/meihua-logo-black.png'
let global_company = '梅花网'

const { export_json_to_excel, formatJson } = require('../excel/Export2Excel')

//下载为Excel
const exportExcel = function (dataList, fileName, tHeader, filterVal) {
  require.ensure([], () => {
    // 设置Excel的表格第一行的标题
    // const tHeader = ['标题','日期', '链接','昵称','播放量','点赞','转发','评论','ID','用户ID'];
    //  'date', 'struct1', 'struct2'对象的属性
    // const filterVal = [ 'description', 'publishDateStr', 'webUrl','posterScreenName','viewCount','likeCount','shareCount','commentCount','id','userName'];

    // 数据集
    const list = dataList;
    const data = formatJson(filterVal, list);
    // excel名称可自定义
    const excelName = fileName;

    export_json_to_excel(tHeader, data, excelName);
  });
}

export default {
  showDataList,
  token,
  userInfo,
  useDouyinApi,
  global_logo_path,
  global_company,
  exportExcel
}