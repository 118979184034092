<template>
  <div class="search-wrap">
    <el-dropdown
      :hide-on-click="false"
      trigger="click"
      placement="bottom-start"
    >
      <span class="el-dropdown-link">
        <el-input v-model="keywords" @input="handleInputChange"></el-input>
      </span>
      <el-dropdown-menu slot="dropdown" class="search-dropdown-menu">
        <el-dropdown-item v-for="item in filterData" :key="item.id">
          <a :href="item.url" target="_blank" v-if="item.isBlank">
            <div class="dropdown-item-wrap">
              <div class="avatar">
                <img :src="item.avatar" />
              </div>
              <div class="intro">
                <div class="name" v-html="item.name"></div>
                <div class="desc">{{ item.desc }}</div>
              </div>
            </div>
          </a>
          <router-link :to="item.url || '/'" v-else>
            <div class="dropdown-item-wrap">
              <div class="avatar">
                <img :src="item.avatar" />
              </div>
              <div class="intro">
                <div class="name" v-html="item.name"></div>
                <div class="desc">{{ item.desc }}</div>
              </div>
            </div>
          </router-link>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import {utils}  from '@/utils'
export default {
  props: {
    searchData: {
      type: Array,
    },
  },
  data() {
    return {
      keywords: "",
      filterData: this.searchData,
    };
  },
  mounted() {
    // console.log(this.$props)
    // this.filterData = this.$props.searchData
  },
  methods: {
    handleInputChange(value) {
      const filterData = this.$props.searchData.filter(
        (item) => item.name.indexOf(value) >= 0
      );
      const mapData = filterData.map(item => ({...item, name: utils.highlightKeywords(item.name, value, '#0772FF')}))
      this.filterData = mapData
    },
  },
};
</script>

<style lang="scss" scoped>
.search-wrap {
  display: inline-block;
}
.search-dropdown-menu {
  min-width: 400px;
  max-height: 540px;
  overflow-y: scroll;

  a {
    text-decoration: none;
  }
}
.dropdown-item-wrap {
  padding: 15px 0;
  display: flex;

  .avatar {
    margin-right: 12px;
    width: 32px;
    height: 32px;
  }
  
  .name {
    font-family: PingFangSC-Medium;
    font-size: 16px;
    color: #333333;
    line-height: 1.2;
  }
  .desc {
    margin-top: 5px;
    font-size: 14px;
    color: #333333;
    line-height: 1.7;
  }
}
</style>