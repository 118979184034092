export default {
  /**
   * 给关键字高亮
   * @param {*} str 
   * @param {*} keywords 
   * @param {*} color 
   */
  highlightKeywords(str, keywords, color = '#ff0000') {
    if (!keywords) {
      return str
    }
    // 直接定义为待选正则表达式
    const blacklist = new RegExp(keywords, 'gi');
    const text = str.replace(blacklist, function (sMatch) {
      // 为所匹配的单词添加红色和下划线
      return sMatch.replace(sMatch, `<span style="color: ${color}">${sMatch}</span>`);
    });
    return text
  }
}