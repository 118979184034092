
import Vue from 'vue'
import Router from 'vue-router'
// 导入刚才编写的组件
import Search from '@/pages/Search'
import Login from '@/pages/Login'
// import Index from '@/pages/Index'
// import Translate from '@/pages/Translate'
import Dashboard from '@/pages/Dashboard'
import Parse from '@/pages/Parse'
import IndexTrend from '@/pages/IndexTrend'
import Translates from '@/pages/Translates'
import Home from '@/pages/Home'
 
Vue.use(Router)
 
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}
export default new Router({
  // mode: 'history',
  routes: [
    // 下面都是固定的写法d
    {
      path: '/',
      name: 'Index',
      component: Home,
      meta: {
        requireAuth: true,
        title:'工具中心'
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        title: '登录'
      }
    },
    {
      path: '/index',
      name: 'Index',
      component: IndexTrend,
      meta: {
        requireAuth: true,
        title:'工具中心'
      }
    },
    {
      path: '/sds',
      name: 'Search',
      component: Search,
      meta: {
        requireAuth: true,
        title:'社交数据搜索'
      }
    },
    {
      path: '/translate',
      name: 'Translate',
      component: Translates,
      meta: {
        requireAuth: true,
        title:'文本翻译'
      }
    },
    {
      path: '/parse',
      name: 'Parse',
      component: Parse,
      meta: {
        requireAuth: true,
        title:'微博解析'
      }
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
      meta: {
        requireAuth: true,
        title:'Dashboard'
      }
    },
    {
      path: '/trend',
      name: 'IndexTrend',
      component: IndexTrend,
      meta: {
        requireAuth: true,
        title: '指数趋势',
      }
    }, {
      path: '/trans',
      name: 'Translates',
      component: Translates,
      meta: {
        requireAuth: true,
        title: '批量翻译'
      }
    }, {
      path: '/home',
      name: 'Home',
      component: Home,
      meta: {
        requireAuth: true,
        title: '工具中心 - 首页'
      }
    }
  ]
})