import Vue from 'vue'
import App from './App.vue'
import moment from 'moment'
// 引入vuex
import store from './store'
import router from './router'
import GLOBAL from './common/global_variable'

import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/element-variables.scss'

import waterfall from './components/waterfall.vue'
import echarts from 'echarts'
import china from 'echarts/map/json/china.json'

import defaultLogo from '@/assets/logo.svg'

echarts.registerMap('china', china)
// import {request} from '../network/request.js'

import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

Vue.use(ElementUI);
Vue.use(waterfall);
Vue.use(ElImageViewer);
Vue.prototype.$echarts = echarts;

// 定义一个全局过滤器实现日期格式化
Vue.filter('datefmt', function (input, fmtstring) {
  return moment.unix(input).format(fmtstring)
})

// 设置反向代理，前端请求默认发送到 http://localhost:8443/api
var axios = require('axios')
// axios.defaults.baseURL = 'http://v3.mice.meihua.info';
axios.defaults.baseURL = 'https://v3mice.meihua.info';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// const config = {
//   headers:{
//     'Content-Type':'application/x-www-form-urlencoded'
//   }
// }
//  axios.request(config)

// axios.defaults.headers.common['token'] = 'andytoken';
// 全局注册，之后可在其他组件中通过 this.$axios 发送数据
Vue.prototype.$axios = axios;
GLOBAL.token = store.state.user.token;
GLOBAL.userInfo = store.state.user.detail;

Vue.config.productionTip = false;
// document.title = "Social Data Search";

var location = window.location.href;
var favUrl = '/favicon.ico';
const focuxyLogo = '../static/logo_focuxy.png';
if(location.indexOf('o=f')>-1){
  favUrl = 'http://www.focuxy.com/img/icons/focuxy/favicon.ico';
  GLOBAL.global_logo_path = focuxyLogo;
  GLOBAL.global_company = '新智科技';
}
else{
  GLOBAL.global_logo_path = defaultLogo;
  GLOBAL.global_company = '梅花网';
}

if(location.indexOf('dy=i')>-1){
  GLOBAL.useDouyinApi = 'idata';
}
else{
  GLOBAL.useDouyinApi = 'meihua';
}

// console.log(GLOBAL.useDouyinApi);

var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = favUrl;
    document.getElementsByTagName('head')[0].appendChild(link);

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.meta.requireAuth) {
    if (store.state.user.token) {
      next()
    } else {
      next({
        path: 'login',
        query: {redirect: to.fullPath}
      })
    }
  } else {
    next()
  }
}
)
// new Vue({
//   render: h => h(App),
// }).$mount('#app');
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
