import Vue from 'vue'
import Vuex from 'vuex'
 
Vue.use(Vuex)
 
const store = new Vuex.Store({
    state: {
      user: {
        token: window.localStorage.getItem('user' || '[]') == null ? '' : JSON.parse(window.localStorage.getItem('user' || '[]')).token,
        detail : window.localStorage.getItem('user' || '[]') == null ? '' : JSON.parse(window.localStorage.getItem('user' || '[]'))
      },
    },
    mutations: {
      login (state, user) {
        state.user = user;
        window.localStorage.setItem('user', JSON.stringify(user))
      },
      logout(state, user){
        state.user = user;
        window.localStorage.removeItem('user');
      }
    }
  })
  
export default store