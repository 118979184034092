<template>
  <div class="layout">
    <Navigate :naviTitle="naviTitle" />
    <div class="container translate-main">
      <div class="translate-form-wrap">
        <h2 class="title">翻译文本</h2>
        <div class="desc">
          请在下方文本框输入您需要翻译到文本内容，多条需回车换行
        </div>
        <el-form class="translate-form" :model="formValue">
          <el-form-item>
            <el-input
              type="textarea"
              :rows="9"
              v-model="formValue.content"
              placeholder="请输入..."
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button 
              type="primary" 
              :loading="transLoading" 
              @click="startTranslate"
              :disabled="!formValue.content.trim()"
            >开始翻译</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="translate-table-wrap" v-if="translateDetailVisible">
        <div class="translate-progress">
          <div class="desc">共解析到【{{translateData.length}}】条待翻译文本，完成进度【{{curRunningIndex}}/{{translateData.length}}】...</div>
          <el-progress
            :percentage="complatedProgress"
            :show-text="false"
            :stroke-width="10"
          ></el-progress>
        </div>
        <div class="translate-table">
          <el-table :data="translateData" style="width: 100%">
            <el-table-column prop="num" label="编号" width="100">
            </el-table-column>
            <el-table-column prop="originText" label="原文本" width="400">
            </el-table-column>
            <el-table-column prop="transText" label="翻译文本">
            </el-table-column>
            <el-table-column prop="status" label="状态" :width="100">
              <template slot-scope="scope">
                <el-tag
                  :type="scope.row.status === 2 ? 'success' : 'info'"
                  disable-transitions
                  >{{ scope.row.status === 2 ? "已完成" : "未完成" }}</el-tag
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="translate-download">
          <el-button type="primary" v-show="downloadBtn" @click="downloadData"
            ><i class="el-icon-download"></i> 下载</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import global_variable from "@/common/global_variable";
import Navigate from "@/components/Navigate.vue";
import moment from "moment";
let runningStatus = false;
let runningIndex = 0;
let lastTranslateList = [];
let reqStatus = true;
let curTranslateObj = {};
let time1 = {};

var axios2 = require("axios");
// axios2.defaults.baseURL = "http://v3.mice.meihua.info";
axios2.defaults.baseURL = "https://v3mice.meihua.info";
axios2.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

const translate2 = function (input) {
  if (input) {
    runningStatus = true;
    // let content = encodeURIComponent(input);
    let apiUrl = `/api/tools/translate`
    const param = {
      token: global_variable.token,
      content: input,
    }
    axios2
      .post(apiUrl, param)
      .then((res) => {
        if (res.data) {
          if (res.data.success) {
            reqStatus = true;
            if (reqStatus) {
              runningIndex++;
            }
            if (input == curTranslateObj.originText) {
              curTranslateObj.transText = res.data.output_content;
              curTranslateObj.status = 2;
              // console.log(curTranslateObj);
            }
            runningStatus = false;
            return res.data.output_content;
          } else {
            reqStatus = false;
          }
        } else {
          reqStatus = false;
        }
        runningStatus = false;
      })
      .catch((err) => {
        console.log(err);
        reqStatus = false;
        runningStatus = false;
        return "";
      });
  }

  return "";
};

export default {
  data() {
    return {
      naviTitle: "批量翻译",
      formValue: {
        content: "",
      },
      translateData: [
        // {
        //   num: 1,
        //   originText: "基础全网监测服务",
        //   transText: "Basic network monitoring service",
        //   status: 1,
        // },
      ],
      complatedProgress: 0,
      curRunningIndex: 0,
      transLoading: false,
      downloadBtn: false,
      translateDetailVisible: false,
    };
  },
  components: {
    Navigate,
  },
  mounted() {},
  methods: {
    parseTranslateLine() {
      let isOk = false
      let areaText = this.formValue.content;
      if (areaText) {
        let texts = this.formValue.content.split("\n");
        var lineList = [];
        for (var index in texts) {
          let item = texts[index];
          if (item) {
            let lineObj = {};
            lineObj.num = parseInt(index) + 1;
            lineObj.originText = item;
            lineObj.transText = "";
            lineObj.status = 1;

            lineList.push(lineObj);
          }
        }
        this.translateData = lineList;
        isOk = true
      } else {
        this.$message("请输入待翻译文本");
        this.$refs["transTextarea"].focus();
      }
      return isOk
    },
    startTranslate() {
      if (this.parseTranslateLine()) {
        this.transLoading = true;
        this.translateDetailVisible = true
        lastTranslateList = this.translateData;
        time1 = setInterval(() => {
          console.log("once again...");
          if (!runningStatus && lastTranslateList.length > 0) {
            this.curRunningIndex = runningIndex;
            if (runningIndex < lastTranslateList.length) {
              curTranslateObj = lastTranslateList[runningIndex];
              translate2(lastTranslateList[runningIndex].originText);
              this.complatedProgress = parseFloat((
                (runningIndex / lastTranslateList.length) *
                100
              ).toFixed(2));
            } else {
              this.complatedProgress = 100;
              console.log("endding...");
              this.downloadBtn = true;
              this.transLoading = false;
              window.clearInterval(time1);
            }
          } else {
            this.transLoading = false;
            // runningStatus = false;
          }
        }, 1000);
      }
    },
    startTranslate_: async function () {
      if (this.translateData) {
        // await Promise.all(this.translateData.map((item)=>{
        //     const ouputContent = this.translate(item.originText);
        //     if(ouputContent){
        //         item.transText = ouputContent;
        //     }
        //     console.log(item.transText)
        // }))
        // for(let i=0;i<this.translateData.length;i++){
        //     let item = this.translateData[i];
        //     let ouputContent = await this.translate(item.originText);
        //     if(ouputContent){
        //         item.transText = ouputContent;
        //     }
        //     else{
        //         ouputContent =await this.translate(item.originText);
        //         item.transText  = ouputContent;
        //     }
        //     console.log(item.transText)
        // }
        //  this.translateData.map(async (item) => {
        //     //console.log(item.originText);
        //     let ouputContent = await this.translate(item.originText);
        //     if(ouputContent){
        //         item.transText = ouputContent;
        //     }
        //     else{
        //         ouputContent = await this.translate(item.originText);
        //         item.transText  = ouputContent;
        //     }
        //     console.log(item.transText)
        // });
      }
    },
    downloadData() {
      let fileName =
        "文本批量翻译_" + moment(new Date()).format("YYYYMMDDHHmmss");
      let tHeader = ["序号", "原文本", "翻译文本", "状态"];
      let filterVal = ["num", "originText", "transText", "status"];
      global_variable.exportExcel(
        this.translateData,
        fileName,
        tHeader,
        filterVal
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.translate-main {
  padding: 40px 0;

  .el-button {
    min-width: 160px;
    height: 48px;
  }
}
.translate-form-wrap {
  .title {
    margin-top: 0;
    font-size: 18px;
    color: #333333;
  }
  .desc {
    font-size: 14px;
    color: #333333;
  }
}
.translate-form {
  margin-top: 30px;

  .el-textarea {
    ::v-deep .el-textarea__inner {
      padding: 18px 28px;
      background: #fafbfd;
      border: 1px solid #eeeeee;
      border-radius: 4px;
      font-size: 14px;
      color: #333333;
      line-height: 2.2;
    }
  }
}
.translate-table-wrap {
  margin-top: 50px;
}
.translate-progress {
  margin-bottom: 20px;

  .desc {
    margin-bottom: 12px;
  }
  .progressbar {
  }
}
.translate-table {
  margin-top: 30px;
}
.translate-download {
  margin-top: 50px;
}
</style>