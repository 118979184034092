<template>
    <div class="dash-con">
        <!-- <div>
            <Navigate :naviTitle="naviTitle" />
        </div>
        <div class="top-blank">
            &nbsp;
        </div> -->
        <div>
            <div class="dialog-panel" v-show="dialogVisible" >
                <div class="dialog-panel-close" @click="hideLargeChart('largeChart')"> <span><i class="el-icon-error"></i></span></div>
                <div id="largeChart"  :style="{width: '1000px', height: '600px'}"></div>
                <!-- <el-dialog
                    title="提示"
                    :visible.sync="dialogVisible"
                    width="90%"
                    
                    >
                    <div id="largeChart" class="top-con chart-panel"  :style="{width: '800px', height: '400px'}"></div>
                </el-dialog> -->
            </div>
            <div class="dash-title"> <span><i class="el-icon-data-line"></i></span>&nbsp;&nbsp;华为云数据驾驶仓</div>
            <div>
                <el-row :gutter="20">
                    <el-col :span="17">
                        <div>
                            <div class="top-con">
                                <el-row>
                                    <el-col :span="5">
                                        <div class="realtime-con">
                                            <div class="rt-panel-title-left">实时概览</div>
                                            <div class="clear">&nbsp;</div>
                                            <div class="realtime-row">
                                                <div class="realtime-dotted1 icon-left "><span>●</span></div>
                                                <div class="realtime-label icon-left realtime-dotted-color1">实时声量</div><div class="clear">&nbsp;</div>
                                                <div class="realtime-number">304,808</div>
                                            </div>
                                            <div class="realtime-row">
                                                <div class="realtime-dotted2 icon-left"><span>●</span></div>
                                                <div class="realtime-label icon-left realtime-dotted-color2">积极声量</div> <div class="clear">&nbsp;</div>
                                                <div class="realtime-number">293,439</div>
                                            </div>
                                            <div class="realtime-row">
                                                <div class="realtime-dotted3 icon-left"><span>●</span></div>
                                                <div class="realtime-label icon-left realtime-dotted-color3">敏感声量</div> <div class="clear">&nbsp;</div>
                                                <div class="realtime-number">13090</div>
                                            </div>
                                            <div class="realtime-line">&nbsp;</div>
                                            <div class="realtime-row">
                                                <div class="realtime-dotted4 icon-left"><span>●</span></div>
                                                <div class="realtime-label icon-left realtime-dotted-color4">提及量</div> <div class="clear">&nbsp;</div>
                                                <div class="realtime-number">1,029,300</div>
                                            </div>
                                        </div>
                                    </el-col>
                                    <el-col :span="19">
                                        <div ><div class="rt-panel-icon-left">声量地域分布</div>  <div class="rt-panel-icon-right"> <span @click="showLargeChart('myChart','largeChart')"><i class="el-icon-rank"></i></span> </div></div>
                                        <div class="clear">&nbsp;</div>
                                        <div id="myChart" class="chart-panel"  :style="{width: '800px', height: '400px'}"></div>
                                    </el-col>
                                </el-row>
                            </div>
                            
                            <el-row>
                                <el-col :span="8">
                                    <div class="top-con" >
                                        <!-- <div class="rt-panel-title-left">媒体热度</div> -->
                                        <div ><div class="rt-panel-icon-left">媒体热度</div>  <div class="rt-panel-icon-right"> <span @click="showLargeChart('hotMediaChart','largeChart')"><i class="el-icon-rank"></i></span> </div></div>
                                        <div class="clear">&nbsp;</div>
                                        <div id="hotMediaChart" class="chart-panel" :style="{width: '100%', height: '300px'}"></div>
                                    </div>
                                </el-col>
                                <el-col :span="16">
                                    <div class="top-con" >
                                        <!-- <div class="rt-panel-title-left">热点词云</div> -->
                                        <div ><div class="rt-panel-icon-left">热点词云</div>  <div class="rt-panel-icon-right"> <span @click="showLargeChart('wordCloudChart','largeChart')"><i class="el-icon-rank"></i></span> </div></div>
                                        <div class="clear">&nbsp;</div>
                                        <div id="wordCloudChart" class="chart-panel" :style="{width: '100%', height: '300px'}"></div>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <div class="top-con" >
                                        <!-- <div class="rt-panel-title-left">平台矩阵</div> -->
                                        <div ><div class="rt-panel-icon-left">平台矩阵</div>  <div class="rt-panel-icon-right"> <span @click="showLargeChart('platTypeChart','largeChart')"><i class="el-icon-rank"></i></span> </div></div>
                                        <div class="clear">&nbsp;</div>
                                        <div id="platTypeChart" class="chart-panel" :style="{width: '100%', height: '300px'}"></div>
                                    </div>
                                </el-col>
                                <el-col :span="16">
                                    <div class="top-con" >
                                        <div class="rt-panel-title-left">传播趋势</div>
                                        <div class="clear">&nbsp;</div>
                                        <div id="dataTrendChart" class="chart-panel" :style="{width: '100%', height: '300px'}"></div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :span="7">
                        <div class="right-panel-con">
                            <!-- <div class="rt-panel-title-left">舆情健康度</div> -->
                            <div ><div class="rt-panel-icon-left">舆情健康度</div>  <div class="rt-panel-icon-right"> <span @click="showLargeChart('healthChart','largeChart')"><i class="el-icon-rank"></i></span> </div></div>
                            <div class="clear">&nbsp;</div>
                            <div id="healthChart" class="chart-panel"  :style="{width: '100%', height: '200px'}"></div>
                        </div>
                        <div class="right-panel-con">
                            <div class="rt-panel-title-left">热门舆情</div>
                            <div class="clear">&nbsp;</div>
                            <div id="hotTopsChart" class="chart-panel"  :style="{width: '100%', height: '400px'}"></div>
                        </div>
                        <div class="right-panel-con">
                            <div class="rt-panel-title-left">敏感舆情</div>
                            <div class="clear">&nbsp;</div>
                            <div id="negativeTopsChart" class="chart-panel"  :style="{width: '100%', height: '400px'}"></div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>
// import Navigate from '../components/Navigate.vue'
export default {
    name:'Dashboard',
    data(){
        return{
            naviTitle:'Dashboard',
            chartBgacolor : 'transparent',//#080c31
            dialogVisible:false
        }
    },
    methods:{
        drawLine(){
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(document.getElementById('myChart'))
            // 绘制图表
            myChart.setOption({
                title: { text: '在Vue中使用echarts' },
                tooltip: {},
                xAxis: {
                    data: ["衬衫","羊毛衫","雪纺衫","裤子","高跟鞋","袜子"]
                },
                yAxis: {},
                series: [{
                    name: '销量',
                    type: 'bar',
                    data: [5, 20, 36, 10, 10, 20]
                }]
            });
        },
        loadAreaMapChart(domId){
            let myChart = this.$echarts.init(document.getElementById(domId))
            var namedata = [{name:'今日头条'},{name:'新闻新闻'},{name:'凤凰网'},{name:'新浪网'},{name:'百家号'}];
            var option = null;
            var geoCoordMap = {
                '上海': [119.1803, 31.2891],
                "福建": [119.4543, 25.9222],
                "重庆": [108.384366, 30.439702],
                '北京': [116.4551, 40.2539],
                "辽宁": [123.1238, 42.1216],
                "河北": [114.4995, 38.1006],
                "天津": [117.4219, 39.4189],
                "山西": [112.3352, 37.9413],
                "陕西": [109.1162, 34.2004],
                "甘肃": [103.5901, 36.3043],
                "宁夏": [106.3586, 38.1775],
                "青海": [101.4038, 36.8207],
                "新疆": [87.9236, 43.5883],
                "西藏": [91.11, 29.97],
                "四川": [103.9526, 30.7617],
                "吉林": [125.8154, 44.2584],
                "山东": [117.1582, 36.8701],
                "河南": [113.4668, 34.6234],
                "江苏": [118.8062, 31.9208],
                "安徽": [117.29, 32.0581],
                "湖北": [114.3896, 30.6628],
                "浙江": [119.5313, 29.8773],
                '内蒙古': [110.3467, 41.4899],
                "江西": [116.0046, 28.6633],
                "湖南": [113.0823, 28.2568],
                "贵州": [106.6992, 26.7682],
                "云南": [102.9199, 25.4663],
                "广东": [113.12244, 23.009505],
                "广西": [108.479, 23.1152],
                "海南": [110.3893, 19.8516],
                '黑龙江': [127.9688, 45.368],
                '台湾': [121.4648, 25.5630]
            };
            var chinaDatas = [
                {
                    name: '北京',
                    value: 86
                },
                {
                    name: '福建',
                    value: 65
                },
                {
                    name: '广东',
                    value: 53
                },
                {
                    name: '上海',
                    value: 48
                },
                
                {
                    name: '河北',
                    value: 2
                },
                {
                    name: '天津',
                    value: 6
                },
                {
                    name: '山西',
                    value: 12
                },
                {
                    name: '陕西',
                    value: 2
                },
                {
                    name: '甘肃',
                    value: 4
                },
                {
                    name: '宁夏',
                    value: 5
                }
            ];
            var convertData = function(data, type) {
                /*type:1 地图参数；type:2数据参数*/
                var res = [];
                for (var i = 0; i < data.length; i++) {
                    var geoCoord = geoCoordMap[data[i].name];
                    if (geoCoord) {
                        if (type == 2) {
                            res.push({
                                name: data[i].name,
                                value: geoCoord.concat(data[i].value),
                                username: data[i].username,
                                telphone: data[i].telphone,
                                address: data[i].address
                            });
                        } else {
                            res.push({
                                name: data[i].name,
                                value: geoCoord.concat(data[i].value)
                            });
                        }
                    }
                }
                //console.log(res);
                return res;
            };
            var yData = [];
            var barData = chinaDatas;
            barData = barData.sort(function(a,b){
                return b.value-a.value;
            });
            for(var j =0;j<barData.length;j++){
                if(j<10){
                    yData.push('0'+j + barData[j].name);
                }else{
                    yData.push(j + barData[j].name);
                }
            }

            option = {
                backgroundColor: this.chartBgacolor,
                title: [{
                    show: true,
                    text: '舆情地域分布排行',
                    subtext: '单位：篇',
                    subtextStyle:{
                        color:'#ffffff',
                        lineHeight:20
                    },
                    textStyle: {
                        color: '#fff',
                        fontSize: 18
                    },
                    right: 160,
                    top: 20
                }],
                grid: {
                    right: 30,
                    top: 80,
                    bottom: 20,
                    width: '200'
                },
                xAxis: {
                    show: false
                },
                yAxis: {
                    type: 'category',
                    inverse: true,
                    nameGap: 16,
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#ddd'
                        }
                    },
                    axisTick: {
                        show: false,
                        lineStyle: {
                            color: '#ddd'
                        }
                    },
                    axisLabel: {
                        interval: 0,
                        margin: 85,
                        textStyle: {
                            color: '#fff',
                            align: 'left',
                            fontSize: 14
                        },
                        rich: {
                            a: {
                                color: '#fff',
                                backgroundColor: '#f0515e',
                                width: 20,
                                height: 20,
                                align: 'center',
                                borderRadius: 2
                            },
                            b: {
                                color: '#fff',
                                backgroundColor: '#24a5cd',
                                width: 20,
                                height: 20,
                                align: 'center',
                                borderRadius: 2
                            }
                        },
                        formatter: function(params) {
                            if (parseInt(params.slice(0, 2)) < 3) {
                                return [
                                    '{a|' + (parseInt(params.slice(0, 2)) + 1) + '}' + '  ' + params.slice(2)
                                ].join('\n')
                            } else {
                                return [
                                    '{b|' + (parseInt(params.slice(0, 2)) + 1) + '}' + '  ' + params.slice(2)
                                ].join('\n')
                            }
                        }
                    },
                    data: yData
                },
                geo: {
                    map: 'china',
                    label: {
                        show: true,
                        color: '#ffffff',
                        emphasis: {
                            color: 'white',
                            show: false
                        }
                    },
                    roam: false,//是否允许缩放
                    top: 20,
                    left: 'left',
                    right: '350',
                    zoom: 1, //默认显示级别
                    scaleLimit: {
                        min: 0,
                        max: 3
                    }, //缩放级别
                    itemStyle: {
                        normal: {
                            borderColor: 'rgba(26,82,231, 1)',
                            borderWidth: 1,
                            areaColor: {
                                type: 'radial',
                                x: 0.5,
                                y: 0.5,
                                r: 0.8,
                                colorStops: [{
                                    offset: 0,
                                    color: 'rgba(14, 101, 247, .1)' // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: 'rgba(125, 183, 252, .1)' // 100% 处的颜色
                                }],
                                globalCoord: false // 缺省为 false
                            },
                            shadowColor: 'rgba(255, 255, 255, 0)',
                            shadowOffsetX: -2,
                            shadowOffsetY: 2,
                            shadowBlur: 10
                        },
                        emphasis: {
                            areaColor: 'rgba(249,157,51, .)',
                            borderWidth: 0
                        }
                    },
                    //是否显示南海诸岛
                    /*regions: [{
                        name: "南海诸岛",
                        value: 0,
                        itemStyle: {
                            normal: {
                                opacity: 0,
                                label: {
                                    show: false
                                }
                            }
                        }
                    }],*/
                    tooltip: {
                        show: true
                    }
                },
                series: [
                    {
                        type: 'effectScatter',
                        coordinateSystem: 'geo',
                        z: 1,
                        data: [],
                        symbolSize: 14,
                        label: {
                            normal: {
                                show: true,
                                formatter: function(params) {
                                    return '{fline|媒体：'+params.data.username+'}\n{tline|'+params.data.address+'}';
                                },
                                position: 'top',
                                backgroundColor: 'rgba(254,174,33,.8)',
                                padding: [0, 0],
                                borderRadius: 3,
                                lineHeight: 32,
                                color: '#f7fafb',
                                rich:{
                                    fline:{
                                        padding: [0, 10, 10, 10],
                                        color:'#ffffff'
                                    },
                                    tline:{
                                        padding: [10, 10, 0, 10],
                                        color:'#ffffff'
                                    }
                                }
                            },
                            emphasis: {
                                show: true
                            }
                        },
                        itemStyle: {
                            color: '#feae21',
                        }
                    },
                    {
                        type: 'effectScatter',
                        coordinateSystem: 'geo',
                        z: 1,
                        data: [],
                        symbolSize: 14,
                        label: {
                            normal: {
                                show: true,
                                formatter: function(params) {
                                    return '{fline|媒体：'+params.data.username+'}\n{tline|'+params.data.address+'}';
                                },
                                position: 'top',
                                backgroundColor: 'rgba(233,63,66,.9)',
                                padding: [0, 0],
                                borderRadius: 3,
                                lineHeight: 32,
                                color: '#ffffff',
                                rich:{
                                    fline:{
                                        padding: [0, 10, 10, 10],
                                        color:'#ffffff'
                                    },
                                    tline:{
                                        padding: [10, 10, 0, 10],
                                        color:'#ffffff'
                                    }
                                }
                            },
                            emphasis: {
                                show: true
                            }
                        },
                        itemStyle: {
                            color: '#e93f42',
                        }
                    },
                    {
                        type: 'effectScatter',
                        coordinateSystem: 'geo',
                        z: 1,
                        data: [],
                        symbolSize: 14,
                        label: {
                            normal: {
                                show: true,
                                formatter: function(params) {
                                    return '{fline|媒体：'+params.data.username+'}\n{tline|'+params.data.address+'}';
                                },
                                position: 'top',
                                backgroundColor: 'rgba(8,186,236,.9)',
                                padding: [0, 0],
                                borderRadius: 3,
                                lineHeight: 32,
                                color: '#ffffff',
                                rich:{
                                    fline:{
                                        padding: [0, 10, 10, 10],
                                        color:'#ffffff'
                                    },
                                    tline:{
                                        padding: [10, 10, 0, 10],
                                        color:'#ffffff'
                                    }
                                }
                            },
                            emphasis: {
                                show: true
                            }
                        },
                        itemStyle: {
                            color: '#08baec',
                        }
                    },
                    //地图
                    {
                        type: 'map',
                        mapType: 'china',
                        geoIndex: 0,
                        z: 0,
                        data: convertData(chinaDatas, 1)
                    },
                    {
                    name: 'barSer',
                    type: 'bar',
                    roam: true,
                    visualMap: false,
                    zlevel: 2,
                    barMaxWidth: 16,
                    barGap: 0,
                    itemStyle: {
                        normal: {
                            color: function(params) {
                                var colorList = [{
                                        colorStops: [{
                                            offset: 0,
                                            color: '#f0515e'
                                        }, {
                                            offset: 1,
                                            color: '#ef8554'
                                        }]
                                    },
                                    {
                                        colorStops: [{
                                            offset: 0,
                                            color: '#3c38e4'
                                        }, {
                                            offset: 1,
                                            color: '#24a5cd'
                                        }]
                                    }
                                ];
                                if (params.dataIndex < 3) {
                                    return colorList[0]
                                } else {
                                    return colorList[1]
                                }
                            },
                            barBorderRadius: [0,15,15,0]
                        }
                    },
                    label:{
                        normal: {
                            show: true,
                            textBorderColor: '#333',
                            textBorderWidth: 2
                        }
                    },
                    data: barData.sort((a,b)=>{
                        return b.value-a.value;
                    })
                }]
            };
            if (option && typeof option === "object") {
                myChart.setOption(option, true);
            }
            var getTel= function(){
                let telstr = '';
                // var n = 2,telstr = '1';
                // while(n<12){
                //     if(n<3){
                //        while(n>0){
                //             var nums = Math.floor(Math.random()*10);
                //             if(nums!==0&&nums!==1&&nums!==2&&nums!==3&&nums!==4&&nums!==6){
                //                 telstr+=nums;
                //                 break;
                //             }
                //         }
                        
                //     }
                //     else if(n>3&&n<8){
                //         telstr+='*';
                //     }
                //     else{
                //         telstr+=Math.floor(Math.random()*10);
                //     }
                //     n++;
                // }
                return telstr;
            }
            var getName = function(type){
                var name = '';
                var roundnum = Math.floor(Math.random()*10);
                if(roundnum >= 5){
                    roundnum = 4;
                }
                switch (type) {
                    case 1:
                        name = namedata[roundnum].name+'';
                        break;
                    case 2:
                        name = namedata[roundnum].name+'';
                        break;
                    default:
                        name = namedata[roundnum].name+'';
                        break;
                }
                return name;
            }
            var getAddress = function(num,type){
                var addstr = '';
                switch (type) {
                    case 1:
                        addstr = '所在地：'+chinaDatas[num].name+'';
                        break;
                    case 2:
                        addstr = '所在地：'+chinaDatas[num].name+'';
                        break;
                    default:
                        addstr = '所在地：'+chinaDatas[num].name+'';
                        break;
                }
                return addstr;
            }
            setInterval(()=>{
                
                //数据情况重绘，清除formatter移动效果，也可根据自身需求是否需要，删除这两行代码
                /*option.series[seriesjson[runidx].createType-1].data = [];
                myChart.setOption(option, true);*/
                var runidx = Math.floor(Math.random()*3);
                var typeidx = Math.floor(Math.random()*3);
                var dataidx = Math.floor(Math.random()*10);
                var ranval = Math.floor(Math.random()*10);
                chinaDatas[dataidx].value = chinaDatas[dataidx].value+ranval;
                var valarr = geoCoordMap[chinaDatas[dataidx].name];
                valarr.push(ranval);
                option.series[typeidx].data = [{
                    name:'',
                    username: getName(runidx),
                    telphone: getTel(),
                    address: getAddress(dataidx,typeidx),
                    value: valarr
                }];
                option.series[4].data = option.series[4].data.sort(function(a,b){
                    return b.value-a.value;
                });
                myChart.setOption(option, true);
                
                setInterval(function (){
                    window.onresize = function () {
                        myChart.resize();
                    }
                },500);

            },3000);

            
        },
        loadHealthChart(domId){
            let myChart = this.$echarts.init(document.getElementById(domId));
            let option = {
                backgroundColor: this.chartBgacolor,
                tooltip: {
                    trigger:'item',
                    formatter: "{b}{c}"
                },
                gird:{
                    width:'auto',
                    height:'auto',
                    top:40,
                    right:0,
                    left:0
                },
                series: [{
                    tooltip: {
                        show: false
                    },
                    "name": 'wrap',
                    "type": 'pie',
                    "hoverAnimation": false,
                    "legendHoverLink": false,
                    center: ['40%','60%'],
                    "radius": ['0%', '7%'],
                    "z": 5,
                    "label": {
                        "normal": {
                            "show": false,
                            "position": 'center'
                        },
                        "emphasis": {
                            "show": false
                        }
                    },
                    "labelLine": {
                        "normal": {
                            "show": false
                        }
                    },
                    "data": [{
                        "value": 100,
                        itemStyle: {
                            normal: {
                                color: "#072B79"
                            },
                            emphasis: {
                                color: "#072B79"
                            }
                        }
                    }]
                }, {
                    tooltip: {
                        show: false
                    },
                    "name": 'wrap',
                    "type": 'pie',
                    "hoverAnimation": false,
                    "legendHoverLink": false,
                    center: ['50%','60%'],
                    "radius": ['6%', '8%'],
                    "z": 5,
                    "label": {
                        "normal": {
                            "show": false,
                            "position": 'center'
                        },
                        "emphasis": {
                            "show": false
                        }
                    },
                    "labelLine": {
                        "normal": {
                            "show": false
                        }
                    },
                    "data": [{
                        "value": 100,
                        itemStyle: {
                            normal: {
                                color: "#24D8E7"
                            },
                            emphasis: {
                                color: "#24D8E7"
                            }
                        }
                    }]
                }, {
                    tooltip: {
                        show: false
                    },
                    name: '刻度',
                    type: 'gauge',
                    radius: '83%',
                    z: 1,
                    min: 0,
                    max: 1,
                    center: ['50%','60%'],
                    splitNumber: 5, //刻度数量
                    startAngle: 180,
                    endAngle: 0,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            width: 5,
                            color: [
                                [0.12, '#E71A6D'],
                                [0.35, '#F88168'],
                                [0.63, '#FBF76B'],
                                [0.8, '#7AD4DF'],
                                [1, '#70C27E'],
                            ]
                        }
                    }, //仪表盘轴线
                    axisLabel: {
                        show: false
                    }, //刻度标签。
                    axisTick: {
                        show: true,
                        lineStyle: {
                            color: 'auto',
                            width: 0
                        },
                        length: -15
                    }, //刻度样式
                    splitLine: {
                        show: true,
                        length: 0,
                        lineStyle: {
                            color: 'auto',
                            width: 2
                        }
                    }, //分隔线样式
                    detail: {
                        show: false
                    },
                    pointer: {
                        show: false
                    }
                }, {
                    name: '本期',
                    type: 'gauge',
                    radius: '80%',
                    min: 0,
                    max: 1,
                    center: ['50%','60%'],
                    data: [{
                        value: 0.89,
                        name: ''
                    }],
                    splitNumber: 12, //刻度数量
                    startAngle: 180,
                    endAngle: 0,
                    z: 5,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            width: 0,
                            color: [
                                [0.12, '#E71A6D'],
                                [0.35, '#F88168'],
                                [0.63, '#FBF76B'],
                                [0.8, '#7AD4DF'],
                                [1, '#70C27E'],
                            ]
                        }
                    }, //仪表盘轴线
                    axisLabel: {
                        show: true,
                        color: '#fff',
                        fontSize:20,
                        distance: -70,
                        formatter:function(params){
                            var value = params.toFixed(2)
                            
                            if(value == 0.00){
                                return '危'
                            }else if(value == 0.25){
                                return '差'
                            }else if(value == 0.50){
                                return '中'
                            }else if(value == 0.75){
                                return '良'
                            }else if(value == 1.00){
                                return '优'
                            }else{
                                return ''
                            }
                        }
                    }, //刻度标签。
                    axisTick: {
                        splitNumber: 10,
                        show: true,
                        lineStyle: {
                            color: 'auto',
                            width: 2
                        },
                        length: 20,
                    }, //刻度样式
                    splitLine: {
                        show: true,
                        length: 25,
                        lineStyle: {
                            color: 'auto',
                            width: 5
                        }
                    }, //分隔线样式

                    "itemStyle": {
                        "normal": {
                            "color": "#24D8E7" //指针颜色
                        }
                    },
                    pointer: {
                        width: 10,
                        length: '80%'
                    },
                    detail: {
                        formatter: function(params){
                            return (params*100).toFixed(0) + '%'
                        },
                        fontSize: 50,
                        color: "#fff",
                        offsetCenter: ['0%', '-35%']
                    },
                    title: {
                        offsetCenter: ['0', '-60%'], 
                        fontSize: 40,
                        color: "#fff",
                        show: true
                    },
                }]
            };

            myChart.setOption(option);

            setInterval(function (){
                window.onresize = function () {
                    myChart.resize();
                }
            },500)
        },
        loadWordCloudChart(domId){
            let myChart = this.$echarts.init(document.getElementById(domId));
            let echarts = this.$echarts;
            var plantCap = [{
                name: '阿里云',
                value: '22342'
            }, {
                name: '腾讯云',
                value: '29821'
            }, {
                name: '华为云',
                value: '12919'
            }, {
                name: '天翼云',
                value: '22314'
            }, {
                name: '七牛云',
                value: '22903'
            }, {
                name: '明道云',
                value: '22391'
            }, {
                name: '青云',
                value: '15781'
            }];

            var datalist = [{
                offset: [50, 50],
                symbolSize: 120,
                opacity: .95,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#29c0fb'
                }, {
                    offset: 1,
                    color: '#2dc5b9'
                }]),
            }, {
                offset: [38, 70],
                symbolSize: 95,
                opacity: .95,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#35d17e'
                }, {
                    offset: 1,
                    color: '#49ddb2'
                }]),
            }, {
                offset: [23, 43],
                symbolSize: 90,
                opacity: .95,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#e5d273'
                }, {
                    offset: 1,
                    color: '#e4a37f'
                }]),
            }, {
                offset: [68, 40],
                symbolSize: 90,
                opacity: .95,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#277aec'
                }, {
                    offset: 1,
                    color: '#57c5ec'
                }]),
            }, {
                offset: [38, 20],
                symbolSize: 65,
                opacity: .95,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#e54948'
                }, {
                    offset: 1,
                    color: '#f08456'
                }]),
            }, {
                offset: [56, 15],
                symbolSize: 68,
                opacity: .7,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#11c46e'
                }, {
                    offset: 1,
                    color: '#f08456'
                }]),
            }, {
                offset: [65, 75],
                symbolSize: 65,
                opacity: .68,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#ff4141'
                }, {
                    offset: 1,
                    color: '#ff8989'
                }]),
            }];
            var datas = [];
            for (var i = 0; i < plantCap.length; i++) {
                var item = plantCap[i];
                var itemToStyle = datalist[i];
                datas.push({
                    name: item.name+'\n'+item.value,
                    value: itemToStyle.offset,
                    symbolSize: itemToStyle.symbolSize,
                    label: {
                        normal: {
                            textStyle: {
                                fontSize: 13,
                                lineHeight: 17,
                            }
                        }
                    },
                    itemStyle: {
                        normal: {
                            color: itemToStyle.color,
                            opacity: itemToStyle.opacity
                        }
                    },
                })
            }
            let option = {
                backgroundColor: this.chartBgacolor,
                grid: {
                    show: false,
                    top: 10,
                    bottom: 10
                },
                tooltip:{
                    trigger: 'item'
                },
                xAxis: [{
                    gridIndex: 0,
                    type: 'value',
                    show: false,
                    min: 0,
                    max: 100,
                    nameLocation: 'middle',
                    nameGap: 5
                }],
                yAxis: [{
                    gridIndex: 0,
                    min: 0,
                    show: false,
                    max: 100,
                    nameLocation: 'middle',
                    nameGap: 30
                }],
                series: [{
                    type: 'scatter',
                    symbol: 'circle',
                    symbolSize: 120,
                    label: {
                        normal: {
                            show: true,
                            formatter: '{b}',
                            color: '#fff',
                            textStyle: {
                                fontSize: '20'
                            }
                        },
                    },
                    itemStyle: {
                        normal: {
                            color: '#00acea'
                        }
                    },
                    data: datas
                }]
            };

            myChart.setOption(option);

            setInterval(function (){
                window.onresize = function () {
                    myChart.resize();
                }
            },500)

        },
        loadPlatTypeChart(domId){
            let myChart = this.$echarts.init(document.getElementById(domId))
            let dashedPic = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAM8AAAAOBAMAAAB6G1V9AAAAD1BMVEX////Kysrk5OTj4+TJycoJ0iFPAAAAG0lEQVQ4y2MYBaNgGAMTQQVFOiABhlEwCugOAMqzCykGOeENAAAAAElFTkSuQmCC';
            let color = ['#FF8700', '#ffc300', '#00e473', '#009DFF', '#0034ff'];
            let chartData = [{
                    name: "今日头条",
                    value: 40083,
                    unit: '列'
                },
                {
                    name: "微信",
                    value: 33974,
                    unit: '列'
                },
                {
                    name: "腾讯新闻",
                    value: 15400,
                    unit: '列'
                },
                {
                    name: "凤凰新闻",
                    value: 11021,
                    unit: '列'
                },
                {
                    name: "百家号",
                    value: 30696,
                    unit: '列'
                }
            ];
            let arrName = [];
            let arrValue = [];
            let sum = 0;
            let pieSeries = [],
                lineYAxis = [];

            // 数据处理
            chartData.forEach((v, i) => {
                console.log(i)
                arrName.push(v.name);
                arrValue.push(v.value);
                sum = sum + v.value;
            })

            // 图表option整理
            chartData.forEach((v, i) => {
                pieSeries.push({
                    name: '沪昆线到达晚点情况',
                    type: 'pie',
                    clockWise: false,
                    hoverAnimation: false,
                    radius: [75 - i * 15 + '%', 67 - i * 15 + '%'],
                    center: ["40%", "50%"],
                    label: {
                        show: false
                    },
                    data: [{
                        value: v.value,
                        name: v.name
                    }, {
                        value: sum - v.value,
                        name: '',
                        itemStyle: {
                            color: "rgba(0,0,0,0)"
                        }
                    }]
                });
                pieSeries.push({
                    name: '',
                    type: 'pie',
                    silent: true,
                    z: 1,
                    clockWise: false, //顺时加载
                    hoverAnimation: false, //鼠标移入变大
                    radius: [75 - i * 15 + '%',67 - i * 15 + '%'],
                    center: ["40%", "50%"],
                    label: {
                        show: false
                    },
                    data: [{
                        value: 7.5,
                        itemStyle: {
                            color: "#E3F0FF"
                        }
                    }, {
                        value: 2.5,
                        name: '',
                        itemStyle: {
                            color: "rgba(0,0,0,0)"
                        }
                    }]
                });
                v.percent = (v.value / sum * 100).toFixed(1) + "%";
                lineYAxis.push({
                    value: i,
                    textStyle: {
                        rich: {
                            circle: {
                                color: color[i],
                                padding: [0, 5]
                            }
                        }
                    }
                });
            })

            let option = {
                backgroundColor: this.chartBgacolor,
                color: color,
                grid: {
                    top: '12%',
                    bottom: '54%',
                    left: "40%",
                    containLabel: false
                },
                yAxis: [{
                    type: 'category',
                    inverse: true,
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        formatter: function(params) {
                            let item = chartData[params];
                            console.log(item)
                            return '{line|}{circle|●}{name|'+ item.name +'}{bd||}{percent|'+item.percent+'}{value|'+ item.value+'}{unit|列}'
                        },
                        interval: 0,
                        inside: true,
                        textStyle: {
                            color: "#fff",
                            fontSize: 14,
                            rich: {
                                line: {
                                    width: 170,
                                    height: 10,
                                    backgroundColor: {image: dashedPic}
                                },
                                name: {
                                    color: '#fff',
                                    fontSize: 14,
                                },
                                bd: {
                                    color: '#fff',
                                    padding: [0, 5],
                                    fontSize: 14,
                                },
                                percent:{
                                    color: '#fff',
                                    fontSize: 14,
                                },
                                value: {
                                    color: '#fff',
                                    fontSize: 16,
                                    fontWeight: 500,
                                    padding: [0, 0, 0, 20]
                                },
                                unit: {
                                    fontSize: 14
                                }
                            }
                        },
                        show: true
                    },
                    data: lineYAxis
                }],
                xAxis: [{
                    show: false
                }],
                series: pieSeries
            };
            myChart.setOption(option);

            setTimeout(function (){
                window.onresize = function () {
                    myChart.resize();
                }
            },500)
        },
        loadHotMediaChart(domId){
            let myChart = this.$echarts.init(document.getElementById(domId))

            let option = {
                backgroundColor: this.chartBgacolor,
                grid: {
                    left: '12%',
                    top: '5%',
                    bottom: '12%',
                    right: '8%'
                },
                tooltip:{
                    trigger: 'item'
                },
                xAxis: {
                    data: ['今日头条', '微信', '新浪网', '凤凰新闻', '腾讯新闻', '百度贴吧', '知乎', '百家号'],
                axisTick: {
                        show: false
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(255, 129, 109,.1)',
                            width: 1 //这里是为了突出显示加上的
                        }
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#999',
                            fontSize: 12
                        },
                        rotate:40,
                        interval: 0
                    }
                },
                yAxis: [{
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            lineStyle: {
                                color: 'rgba(255, 129, 109, 0.1)',
                                width: 1 //这里是为了突出显示加上的
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#999'
                            }
                        },
                        splitArea: {
                            areaStyle: {
                                color: 'rgba(255,255,255,.5)'
                            }
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgba(255, 129, 109, 0.1)',
                                width: 0.5,
                                type: 'dashed'
                            }
                        }
                    }
                ],
                series: [{
                    type: 'pictorialBar',
                    barCategoryGap: '0%',
                    symbol: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
                    label: {
                        show: true,
                        position: 'top',
                        distance: 15,
                        color: '#08DFFE',
                        fontWeight: 'bolder',
                        fontSize: 15,
                    },
                    itemStyle: {
                        normal: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                        offset: 0,
                                        color:'#9A11FF'
                                    },
                                    {
                                        offset: 1,
                                        color:'#08DFFE'
                                    }
                                ],
                                global: false //  缺省为  false
                            }
                        },
                        emphasis: {
                            opacity: 1
                        }
                    },
                    data: [123, 60, 25, 18, 12, 9, 2, 1]
                }]
            };

            myChart.setOption(option);

            setInterval(function (){
                window.onresize = function () {
                    myChart.resize();
                }
            },500)
        },
        loadDataTrendChart(domId){
            let myChart = this.$echarts.init(document.getElementById(domId));
            let echarts = this.$echarts;
            let option = {
                backgroundColor: this.chartBgacolor,
                title: {
                    text: '',
                    textStyle: {
                        align: 'center',
                        color: '#fff',
                        fontSize: 20,
                    },
                    top: '5%',
                    left: 'center',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        lineStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0,
                                    color: 'rgba(0, 255, 233,0)'
                                }, {
                                    offset: 0.5,
                                    color: 'rgba(255, 255, 255,1)',
                                }, {
                                    offset: 1,
                                    color: 'rgba(0, 255, 233,0)'
                                }],
                                global: false
                            }
                        },
                    },
                },
                grid: {
                    top: '15%',
                    left: '5%',
                    right: '5%',
                    bottom: '15%',
                    containLabel: true
                },
                xAxis: [{
                    type: 'category',
                    axisLine: {
                        show: true
                    },
                    splitArea: {
                        // show: true,
                        color: '#f00',
                        lineStyle: {
                            color: '#f00'
                        },
                    },
                    axisLabel: {
                        color: '#fff'
                    },
                    splitLine: {
                        show: false
                    },
                    boundaryGap: false,
                    data: ['11/01', '11/02', '11/03', '11/04', '11/05', '11/06'],

                }],

                yAxis: [{
                    type: 'value',
                    min: 0,
                    // max: 140,
                    splitNumber: 4,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: 'rgba(255,255,255,0.1)'
                        }
                    },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                        margin: 20,
                        textStyle: {
                            color: '#d1e6eb',

                        },
                    },
                    axisTick: {
                        show: false,
                    },
                }],
                series: [{
                        name: '',
                        type: 'line',
                        smooth: true, //是否平滑
                        showAllSymbol: true,
                        // symbol: 'image://./static/images/guang-circle.png',
                        symbol: 'circle',
                        symbolSize: 15,
                        lineStyle: {
                            normal: {
                                color: "#00b3f4",
                                shadowColor: 'rgba(0, 0, 0, .3)',
                                shadowBlur: 0,
                                shadowOffsetY: 5,
                                shadowOffsetX: 5,
                            },
                        },
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: '#00b3f4',
                            }
                        },
                        itemStyle: {
                            color: "#00b3f4",
                            borderColor: "#fff",
                            borderWidth: 3,
                            shadowColor: 'rgba(0, 0, 0, .3)',
                            shadowBlur: 0,
                            shadowOffsetY: 2,
                            shadowOffsetX: 2,
                        },
                        tooltip: {
                            show: true
                        },
                        areaStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: 'rgba(0,179,244,0.3)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(0,179,244,0)'
                                    }
                                ], false),
                                shadowColor: 'rgba(0,179,244, 0.9)',
                                shadowBlur: 20
                            }
                        },
                        data: [502, 205, 332, 281, 398, 214, ]
                    },
                    {
                        name: '',
                        type: 'line',
                        smooth: true, //是否平滑
                        showAllSymbol: true,
                        // symbol: 'image://./static/images/guang-circle.png',
                        symbol: 'circle',
                        symbolSize: 15,
                        lineStyle: {
                            normal: {
                                color: "#00ca95",
                                shadowColor: 'rgba(0, 0, 0, .3)',
                                shadowBlur: 0,
                                shadowOffsetY: 5,
                                shadowOffsetX: 5,
                            },
                        },
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: '#00ca95',
                            }
                        },

                        itemStyle: {
                            color: "#00ca95",
                            borderColor: "#fff",
                            borderWidth: 3,
                            shadowColor: 'rgba(0, 0, 0, .3)',
                            shadowBlur: 0,
                            shadowOffsetY: 2,
                            shadowOffsetX: 2,
                        },
                        tooltip: {
                            show: true
                        },
                        areaStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: 'rgba(0,202,149,0.3)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(0,202,149,0)'
                                    }
                                ], false),
                                shadowColor: 'rgba(0,202,149, 0.9)',
                                shadowBlur: 20
                            }
                        },
                        data: [281, 398, 214, 179, 289, 356, ],
                    },
                    {
                        name: '',
                        type: 'line',
                        smooth: true, //是否平滑
                        showAllSymbol: true,
                        // symbol: 'image://./static/images/guang-circle.png',
                        symbol: 'circle',
                        symbolSize: 15,
                        lineStyle: {
                            normal: {
                                color: "#a71a2b",
                                shadowColor: 'rgba(0, 0, 0, .3)',
                                shadowBlur: 0,
                                shadowOffsetY: 5,
                                shadowOffsetX: 5,
                            },
                        },
                        label: {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: '#a71a2b',
                            }
                        },

                        itemStyle: {
                            color: "#a71a2b",
                            borderColor: "#fff",
                            borderWidth: 3,
                            shadowColor: 'rgba(0, 0, 0, .3)',
                            shadowBlur: 0,
                            shadowOffsetY: 2,
                            shadowOffsetX: 2,
                        },
                        tooltip: {
                            show: true
                        },
                        areaStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: 'rgba(0,202,149,0.3)'
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(0,202,149,0)'
                                    }
                                ], false),
                                shadowColor: 'rgba(0,202,149, 0.9)',
                                shadowBlur: 20
                            }
                        },
                        data: [181, 298, 294, 175, 219, 206, ],
                    }
                ]
            };
            myChart.setOption(option);

            setInterval(function (){
                window.onresize = function() {
                    myChart.resize();
                }
            },500)
        },
        loadHotTopsChart(domId){
            let myChart = this.$echarts.init(document.getElementById(domId));
            let echarts = this.$echarts;
            let data = [{
                    name: '三大能力创新升级 华为云加速云原生产业落地实践',
                    value: 10,
                    sum: 10
                },
                {
                    name: '华为云WeLink 设计体验再升级,品牌年轻化趋势明显',
                    value: 20,
                    sum: 10
                },
                {
                    name: '华为云贾永利:AI赋能千行百业再次腾飞',
                    value: 23,
                    sum: 50
                },
                {
                    name: '“泰国华为云与连接峰会”在曼谷举行',
                    value: 44,
                    sum: 60
                }, {
                    name: '华为云容器服务再获满分',
                    value: 10,
                    sum: 10
                },
                {
                    name: '华为云11.11上云嘉年华火热进行中,火速围观华为云“买家秀”',
                    value: 20,
                    sum: 10
                }
            ];
            var getArrByKey = (data, k) => {
                let key = k || "value";
                let res = [];
                if (data) {
                    data.forEach(function(t) {
                        res.push(t[key]);
                    });
                }
                return res;
            };
            var getSymbolData = (data) => {
                let arr = [];
                for (var i = 0; i < data.length; i++) {
                    arr.push({
                        value: data[i].value,
                        symbolPosition: 'end'
                    })
                }
                return arr;
            }
            let opt = {
                index: 0
            }
            let color = ['#A71A2B'];
            data = data.sort((a, b) => {
                return b.value - a.value
            });
            console.log(getSymbolData(data));
            console.log(opt);
            console.log(color);
            let option = {
                backgroundColor: this.chartBgacolor,
                grid: {
                    top: '2%',
                    bottom: -15,
                    right: 20,
                    left: 20,
                    containLabel: false
                },
                tooltip:{
                    trigger: 'item'
                },
                xAxis: {
                    show: false
                },
                yAxis: [{
                    triggerEvent: true,
                    show: true,
                    inverse: true,
                    data: getArrByKey(data, 'name'),
                    axisLine: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        interval: 0,
                        color: '#fff',
                        align: 'left',
                        margin: 80,
                        fontSize: 13,
                        formatter: function(value, index) {
                            console.log(index);
                            return '{title|' + value + '}'
                        },
                        rich: {
                            title: {
                                width: 165
                            }
                        }
                    },
                }, {
                    triggerEvent: true,
                    show: true,
                    inverse: true,
                    data: getArrByKey(data, 'name'),
                    axisLine: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        interval: 0,
                        shadowOffsetX: '-20px',
                        color: ['#fff'],
                        align: 'right',
                        verticalAlign: 'bottom',
                        lineHeight: 30,
                        fontSize: 13,
                        formatter: function(value, index) {
                            // return (data[index].value / data[index].sum) * 100
                            return data[index].value;
                        },
                    }
                }],
                series: [{
                    name: '',
                    type: 'pictorialBar',
                    symbol: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA6CAMAAADWZboaAAAAZlBMVEUAAABe3uVe3+Vf3uVf3+Zf3uVg3+Zg3+Zf3+Vi4OZh4OZg3+Z86/Bh3+Zi4Odj4Odi4OZ86/B76/B86/Bj4ed56+9x5+xn4umB7/N87PB36e+A7/N+7fF/7vJ/7vJ+7fGA7/OB7/PReX+lAAAAIXRSTlMABQkVDREmIhk3MR10LEFFPHh7cUprXE35h2XnqMLAp+mHAG9cAAAB5ElEQVRIx83WjU7CMBQFYIoiKMqU/XUboHv/l/Tce7t2XamDNSacETEmX86tlK2rx4py150o+MstMBLwWRfHKo6JCVxLnvmFGBjFQ58oF1//sUZhGy/ClSTWObgnL4O+bkeN4nY2okfNMbkRt9/vtxz8InoTsWplJSCzFxPmO8+GpSIByX3YQAuGDWtRKhKjCnxDXhF6Z4yxnZ20Wgko7BMRDmxtSGVaI4kdTIgb+zTYoJQlIMlDlmUFgrcDWWC201qSayqlTkiCddWWeV62VU0YlnpRi9VOKaSUsiyq/N0krwq2Ugt7lVpZl5BfHNiytjagMi+XYp0kCR45hMlivVQrE/uU5pXSrCB5bM6d1t2lOZItMqmliT3q5uVxqxzyW/ccfYLNKx7ZTeykMvNyac2yt2Fbc61MHLSC0rwoxbiNdlQ3GBm1NLHQsHUrtEXppR/ljNpW6DbSCoqlFiVoN6YdaFlgsSFVPs1BdT8OaB5QyQzVcaqWDows/zepxR8ObLglTrdtCRVuRNj4Rrxh+//0ke2f8KVL+Kon3GCSbmsJN9OUW3j6g0Ns+LgCij2u0h+Sghc8mlMPBMgdx5DFh59VmOVHrvmDnoNxCz3J7MFWsMuaLyR089xz/xhlfijvwutR8gv3zk6BLUUeCgAAAABJRU5ErkJggg==',
                    symbolSize: [50, 50],
                    symbolOffset: [20, 0],
                    z: 12,
                    itemStyle: {
                        normal: {
                            color: '#14b1eb'
                        }
                    },
                    data: getSymbolData(data)
                }, {
                    name: '',
                    type: 'bar',
                    showBackground: true,
                    barBorderRadius: 30,
                    yAxisIndex: 0,
                    data: data,
                    barWidth: 10,
                    // align: left,
                    itemStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(
                                0,
                                0,
                                1,
                                0,
                                [{
                                        offset: 0,
                                        color: '#25a5cd'
                                    },
                                    {
                                        offset: 0.7,
                                        color: '#25a5cd'
                                    },
                                    {
                                        offset: 1,
                                        color: '#5EDEE5'
                                    }
                                ],
                                false
                            ),
                            barBorderRadius: 10
                        },
                        // color: '#A71A2B',
                        barBorderRadius: 4,
                    },
                    label: {
                        normal: {
                            color: '#fff',
                            show: true,
                            position: [0, '-20px'],
                            textStyle: {
                                fontSize: 16
                            },
                            formatter: function(a, b) {
                                console.log(b)
                                return a.name
                            }
                        }
                    }
                }]
            };

            myChart.setOption(option);
            setTimeout(function (){
                window.onresize = function () {
                    myChart.resize();
                }
            },500)
        },
        loadNegativeTopsChart(domId){
            let myChart = this.$echarts.init(document.getElementById(domId));
            let echarts = this.$echarts;
            let data = [{
                    name: '华为云崩溃:华为式危机公关,以客户为中心',
                    value: 10,
                    sum: 10
                },
                {
                    name: '每经20点丨印度或将有三成餐厅倒闭,整体利润减少一半;华为:华为云',
                    value: 20,
                    sum: 10
                },
                {
                    name: '华为云宕机事件,折射华为式危机公关的得与失',
                    value: 23,
                    sum: 50
                },
                {
                    name: '华为云宕机事件,折射华为式危机公关的得与失',
                    value: 44,
                    sum: 60
                }, {
                    name: '华为云突发大面积崩溃 网友炸锅:快撑不住了',
                    value: 10,
                    sum: 10
                },
                {
                    name: '华为云电脑会泄露信息_华为云电脑登录华为云',
                    value: 20,
                    sum: 10
                }
            ];
            var getArrByKey = (data, k) => {
                let key = k || "value";
                let res = [];
                if (data) {
                    data.forEach(function(t) {
                        res.push(t[key]);
                    });
                }
                return res;
            };
            var getSymbolData = (data) => {
                let arr = [];
                for (var i = 0; i < data.length; i++) {
                    arr.push({
                        value: data[i].value,
                        symbolPosition: 'end'
                    })
                }
                return arr;
            }
            let opt = {
                index: 0
            }
            let color = ['#A71A2B'];
            data = data.sort((a, b) => {
                return b.value - a.value
            });
            console.log(getSymbolData(data));
            console.log(opt);
            console.log(color);
            let option = {
                backgroundColor: this.chartBgacolor,
                grid: {
                    top: '2%',
                    bottom: -15,
                    right: 20,
                    left: 20,
                    containLabel: false
                },
                tooltip:{
                    trigger: 'item'
                },
                xAxis: {
                    show: false
                },
                yAxis: [{
                    triggerEvent: true,
                    show: true,
                    inverse: true,
                    data: getArrByKey(data, 'name'),
                    axisLine: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        interval: 0,
                        color: '#fff',
                        align: 'left',
                        margin: 80,
                        fontSize: 13,
                        formatter: function(value, index) {
                            console.log(index);
                            return '{title|' + value + '}'
                        },
                        rich: {
                            title: {
                                width: 165
                            }
                        }
                    },
                }, {
                    triggerEvent: true,
                    show: true,
                    inverse: true,
                    data: getArrByKey(data, 'name'),
                    axisLine: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        interval: 0,
                        shadowOffsetX: '-20px',
                        color: ['#fff'],
                        align: 'right',
                        verticalAlign: 'bottom',
                        lineHeight: 30,
                        fontSize: 13,
                        formatter: function(value, index) {
                            // return (data[index].value / data[index].sum) * 100;
                            return data[index].value;
                        },
                    }
                }],
                series: [{
                    name: '',
                    type: 'pictorialBar',
                    symbol: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA6CAMAAADWZboaAAAAZlBMVEUAAABe3uVe3+Vf3uVf3+Zf3uVg3+Zg3+Zf3+Vi4OZh4OZg3+Z86/Bh3+Zi4Odj4Odi4OZ86/B76/B86/Bj4ed56+9x5+xn4umB7/N87PB36e+A7/N+7fF/7vJ/7vJ+7fGA7/OB7/PReX+lAAAAIXRSTlMABQkVDREmIhk3MR10LEFFPHh7cUprXE35h2XnqMLAp+mHAG9cAAAB5ElEQVRIx83WjU7CMBQFYIoiKMqU/XUboHv/l/Tce7t2XamDNSacETEmX86tlK2rx4py150o+MstMBLwWRfHKo6JCVxLnvmFGBjFQ58oF1//sUZhGy/ClSTWObgnL4O+bkeN4nY2okfNMbkRt9/vtxz8InoTsWplJSCzFxPmO8+GpSIByX3YQAuGDWtRKhKjCnxDXhF6Z4yxnZ20Wgko7BMRDmxtSGVaI4kdTIgb+zTYoJQlIMlDlmUFgrcDWWC201qSayqlTkiCddWWeV62VU0YlnpRi9VOKaSUsiyq/N0krwq2Ugt7lVpZl5BfHNiytjagMi+XYp0kCR45hMlivVQrE/uU5pXSrCB5bM6d1t2lOZItMqmliT3q5uVxqxzyW/ccfYLNKx7ZTeykMvNyac2yt2Fbc61MHLSC0rwoxbiNdlQ3GBm1NLHQsHUrtEXppR/ljNpW6DbSCoqlFiVoN6YdaFlgsSFVPs1BdT8OaB5QyQzVcaqWDows/zepxR8ObLglTrdtCRVuRNj4Rrxh+//0ke2f8KVL+Kon3GCSbmsJN9OUW3j6g0Ns+LgCij2u0h+Sghc8mlMPBMgdx5DFh59VmOVHrvmDnoNxCz3J7MFWsMuaLyR089xz/xhlfijvwutR8gv3zk6BLUUeCgAAAABJRU5ErkJggg==',
                    symbolSize: [50, 50],
                    symbolOffset: [20, 0],
                    z: 12,
                    itemStyle: {
                        normal: {
                            color: '#14b1eb'
                        }
                    },
                    data: getSymbolData(data)
                }, {
                    name: '',
                    type: 'bar',
                    showBackground: true,
                    barBorderRadius: 30,
                    yAxisIndex: 0,
                    data: data,
                    barWidth: 10,
                    // align: left,
                    itemStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(
                                0,
                                0,
                                1,
                                0,
                                [{
                                        offset: 0,
                                        color: '#A71A2B'
                                    },
                                    {
                                        offset: 0.7,
                                        color: '#A71A2B'
                                    },
                                    {
                                        offset: 1,
                                        color: '#5EDEE5'
                                    }
                                ],
                                false
                            ),
                            barBorderRadius: 10
                        },
                        // color: '#A71A2B',
                        barBorderRadius: 4,
                    },
                    label: {
                        normal: {
                            color: '#fff',
                            show: true,
                            position: [0, '-20px'],
                            textStyle: {
                                fontSize: 16
                            },
                            formatter: function(a, b) {
                                console.log(b)
                                return a.name
                            }
                        }
                    }
                }]
            };

            myChart.setOption(option);
            setTimeout(function (){
                window.onresize = function () {
                    myChart.resize();
                }
            },500)
        },
        showLargeChart(chartType, domId){
            this.dialogVisible = true;
            if(chartType == 'myChart'){
                this.loadAreaMapChart(domId);
            }
            else if(chartType == 'healthChart'){
                this.loadHealthChart(domId);
            }
            else if(chartType == 'wordCloudChart'){
                this.loadWordCloudChart(domId);
            }
            else if(chartType == 'platTypeChart'){
                this.loadPlatTypeChart(domId);
            }
            else if(chartType == 'hotMediaChart'){
                this.loadHotMediaChart(domId);
            }
            else if(chartType == 'dataTrendChart'){
                this.loadDataTrendChart(domId);
            }
            else if(chartType == 'negativeChart'){
                this.loadNegativeTopsChart(domId);
            }
            this.dialogVisible = true;
        },
        hideLargeChart(domId){
            let myChart = this.$echarts.init(document.getElementById(domId));
            myChart.dispose();
            this.dialogVisible = false;
        }

    },
    components:{
        // Navigate
    },
    mounted(){
        this.loadAreaMapChart('myChart');
        this.loadHealthChart('healthChart');
        this.loadWordCloudChart('wordCloudChart');
        this.loadPlatTypeChart('platTypeChart');
        this.loadHotMediaChart('hotMediaChart');
        this.loadDataTrendChart('dataTrendChart');
        this.loadHotTopsChart('hotTopsChart');
        this.loadNegativeTopsChart('negativeTopsChart');
    }
}
</script>
<style scoped>
a{
    color: unset;
    text-decoration-line: none;
}
.dash-title{
    font-size:20px;
    font-weight: bold;
    color: #fff;
    padding: 10px 0 10px 0;
}
.chart-panel{
    border-radius: 5px;
}
.dash-con{
    /* height: 1024px; */
    background-color: #012248 !important;
}
.bg-purple{
    background-color: green;
}
.realtime-con{
    text-align: left;
    margin-left: 15px;
}
.realtime-row{
    margin: 20px 0;
}
.top-con{
    border: solid 1px #364196;
    padding: 10px;
    color: #fff;
    margin: 0 0 20px 20px;
    border-radius: 10px;
    background-image: linear-gradient(#080c31, #202ec5);
    /* background-color: #080c31; */
}
.right-panel-con{
    border: solid 1px #364196;
    padding: 10px;
    color: #fff;
    margin: 0 30px 20px 0;
    border-radius: 10px;
    background-image: linear-gradient(#080c31, #202ec5);
    /* background-color: #080c31; */
}
.realtime-dotted1{
    color: #0066cc;
    font-size: 20px;
    margin-right: 10px;
}
.realtime-dotted2{
    color: #00cc00;
    font-size: 20px;
    margin-right: 10px;
}
.realtime-dotted3{
    color: #ff3300;
    font-size: 20px;
    margin-right: 10px;
}
.realtime-dotted4{
    color: #0066cc;
    font-size: 20px;
    margin-right: 10px;
}
.realtime-label{
    width:80px;
    z-index: 100;
    display: inline;
    color: #ffffff;
    font-weight: 500;
    text-decoration: none;
    background-repeat: no-repeat;
    background-position: 0 106%;
    background-size: 60px 9px;
    -webkit-transition: background-size .3s;
    transition: background-size .3s;
}
.realtime-dotted-color1{
    background-color: transparent;
    background-image: -webkit-gradient(linear,left top,left bottom,from(#0066cc),to(#0066cc));
    background-image: linear-gradient(#0066cc,#0066cc);
}
.realtime-dotted-color2{
    background-color: transparent;
    background-image: -webkit-gradient(linear,left top,left bottom,from(#00cc00),to(#00cc00));
    background-image: linear-gradient(#00cc00,#00cc00);
}
.realtime-dotted-color3{
    background-color: transparent;
    background-image: -webkit-gradient(linear,left top,left bottom,from(#ff3300),to(#ff3300));
    background-image: linear-gradient(#ff3300,#ff3300);
}
.realtime-dotted-color4{
    background-color: transparent;
    background-image: -webkit-gradient(linear,left top,left bottom,from(#0066cc),to(#0066cc));
    background-image: linear-gradient(#0066cc,#0066cc);
}
.icon-left{
    float: left;
}
.realtime-number{
    font-size: 24px;
    font-weight: lighter;
    margin-left: 20px;
    font-family: 'fantasy','PingFang SC';
}
.realtime-line{
    height: 1px;
    width: 100px;
    background-color: #0066cc;
}
.rt-panel-title-left{
    float: left;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}
.rt-panel-icon-right{
    float: right;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
    opacity: 0.6;
}
.rt-panel-icon-left{
    float: left;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}
.dialogChart{
    background-color: #080c31;
}
.el-dialog.el-dialog--center,.el-dialog{
    background-image: linear-gradient(#080c31, #202ec5) !important;
}
.dialog-panel{
    position: fixed;
    z-index: 10000;
    padding: 10% 10%;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(#080c31, #202ec5) !important;
}
.dialog-panel-close{
    position: fixed;
    top: 20px;
    right: 20px;
    color: #fff;
    font-size: 26px;
    cursor: pointer;
}
</style>