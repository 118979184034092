<template>
  <div class="layout">
    <Navigate :naviTitle="naviTitle" :hasSide="true" >
      <div class="nav-center-wrap">
        <SearchInput :searchData="searchData" />
      </div>
    </Navigate>
    <div class="home-layout">
      <div class="side">
        <div class="side-wrap">
          <div class="logo">
            <router-link to="/">
              <img :src="logo" alt="首页">
            </router-link>
          </div>
          <div class="side-menu">
            <el-menu
              default-active="1"
              class="el-menu-vertical-demo"
              :default-openeds="['1']"
            >
              <el-submenu index="1">
                <template slot="title">
                  <i class="el-icon-suitcase"></i>
                  <span>销售工具</span>
                </template>
                <el-menu-item index="1-1" @click="handleMenuItemClick('m-1-1')">社交媒体</el-menu-item>
                <el-menu-item index="1-2" @click="handleMenuItemClick('m-1-2')">实时分析</el-menu-item>
                <el-menu-item index="1-3" @click="handleMenuItemClick('m-1-3')">港澳台媒</el-menu-item>
                <el-menu-item index="1-4" @click="handleMenuItemClick('m-1-4')">行业数据</el-menu-item>
                <el-menu-item index="1-5" @click="handleMenuItemClick('m-1-5')">开通试用</el-menu-item>
                <el-menu-item index="1-5" ><a :href="lorealTestUrl" target="_blank">功能测试</a></el-menu-item>
                <!-- <el-menu-item index="1-5" ><a href="https://lorealfinance.mohodata.com/sso/loreal" target="_blank">功能测试</a></el-menu-item> -->
                <!-- <el-menu-item index="1-5" ><a href="https://hubapi.mohodata.com/mohodata/app/sso/loreal/redirect" target="_blank">功能测试</a></el-menu-item> -->
                <!-- <el-menu-item index="1-5" @click="handleTestJump"><a >功能测试</a></el-menu-item> -->
              </el-submenu>
              <el-menu-item index="2" @click="handleMenuItemClick('m-2-1')">
                <i class="el-icon-timer"></i>
                <span slot="title">效率工具</span>
              </el-menu-item>
              <el-menu-item index="4" @click="handleMenuItemClick('m-3-1')">
                <i class="el-icon-folder"></i>
                <span slot="title">资源库</span>
              </el-menu-item>
            </el-menu>
          </div>
        </div>
      </div>
      <div class="main">
        <div class="container home-main">
          <div class="section section-social" v-for="pageModule in moduleData" :key="pageModule.id">
            <div class="section-wrap" v-for="section in pageModule.sectionData" :key="section.id" :id="`section-${section.id}`">
              <div class="section-head">
                <img :src="section.icon" :alt="section.name" class="icon">
                <h2 class="title">
                  {{section.name}}
                </h2>
                <span class="desc">{{section.desc}}</span>
              </div>
              <div class="section-content">
                <div class="search-data-list">
                  <div class="search-data-item" v-for="item in section.data" :key="item.id">
                    <a :href="item.url" target="_blank" v-if="item.isBlank">
                      <div class="head">
                        <span class="avatar">
                          <img :src="item.avatar" :alt="item.name">
                        </span>
                        <span class="name">{{item.name}}</span>
                      </div>
                      <div class="desc">{{item.desc}}</div>
                    </a>
                    <router-link :to="item.url || '/'" v-else>
                      <div class="head">
                        <span class="avatar">
                          <img :src="item.avatar" :alt="item.name">
                        </span>
                        <span class="name">{{item.name}}</span>
                      </div>
                      <div class="desc">{{item.desc}}</div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigate from '@/components/Navigate'
import SearchInput from '@/components/SearchInput'

import logo from '@/assets/logo.svg'

import imgDouyin from '@/assets/avatar/douyin.svg'
// import imgKuaishou from '@/assets/avatar/kuaishou.svg'
import imgXiaohongshu from '@/assets/avatar/xiaohongshu.svg'
// import imgXigua from '@/assets/avatar/xigua.svg'
// import imgWeibo from '@/assets/avatar/weibo.svg'
import imgWeb from '@/assets/avatar/web.svg'
// import imgZhihu from '@/assets/avatar/zhihu.svg'
// import imgBilibili from '@/assets/avatar/bilibili.svg'

import imgZhishu from '@/assets/avatar/zhishu.svg'
import imgRebang from '@/assets/avatar/rebang.svg'
import imgYuqing from '@/assets/avatar/yuqing.svg'
// import imgShishi from '@/assets/avatar/shishi.svg'
// import imgGangaotai from '@/assets/avatar/gangaotai.svg'
// import imgYiliao from '@/assets/avatar/yiliao.svg'
// import imgWaimei from '@/assets/avatar/waimei.svg'
import imgMeihua from '@/assets/avatar/mohodata.svg'
import imgFanyi from '@/assets/avatar/fanyi.svg'
// import imgMeiti from '@/assets/avatar/meiti.svg'
import imgBaogao from '@/assets/avatar/baogao.svg'
import imgChuanmeiku from '@/assets/avatar/chuanmeiku.svg'
import imgMeiti2 from '@/assets/avatar/meiti2.svg'
import imgDianshi from '@/assets/avatar/dianshi.svg'

import iconMessage from '@/assets/icon/message.svg'
import iconOther from '@/assets/icon/other.svg'
import iconEdit from '@/assets/icon/edit.svg'
import iconClock from '@/assets/icon/clock.svg'
import iconFolder from '@/assets/icon/folder.svg'

const socialData = [
  {id: 1001, name: '抖音', avatar: imgDouyin, desc: '国内知名的短视频分享平台', url: '/sds?t=douyin'},
  // {id: 1002, name: '快手', avatar: imgKuaishou, desc: '国内知名的短视频分享平台', url: '/sds?t=kuaishou'},
  {id: 1003, name: '小红书', avatar: imgXiaohongshu, desc: '年轻人的生活方式平台', url: '/sds?t=xiaohongshu'},
  // {id: 1004, name: '西瓜视频', avatar: imgXigua, desc: '字节跳动旗下中视频平台', url: '/sds?t=xigua'},
  // {id: 1005, name: '微博', avatar: imgWeibo, desc: '实时信息的即时分享、传播互动平台', url: '/sds?t=weibo'},
  // {id: 1006, name: '知乎', avatar: imgZhihu, desc: '国内知名的原创内容分享及问答社区', url: '/sds?t=zhihu'},
  // {id: 1007, name: '哔哩哔哩', avatar: imgBilibili, desc: '国内知名的视频弹幕网站', url: '/sds?t=bilibili'},
]

const otherData = [
  // {id: 2002, name: '实时数据检索与分析', avatar: imgShishi, desc: '实时检索全网舆情数据（除微博）', url: 'http://data.meihua.info/', isBlank: true},
  {id: 2004, name: '舆情分析', avatar: imgYuqing, desc: '全网舆情数据分析、下载及舆情报告分享', url: 'https://yq.mohodata.com', isBlank: true},
  {id: 2003, name: '热榜分析', avatar: imgRebang, desc: '速览全网热点话题及传播分析', url: 'https://rb.mohodata.com', isBlank: true},
  {id: 2001, name: '指数分析', avatar: imgZhishu, desc: '实时查询微博及百度指数数据', url: '/index'},
  // {id: 2004, name: '外媒数据', avatar: imgWaimei, desc: '字节跳动旗下中视频平台', url: 'http://i.meihua.info/', isBlank: true},
]
// const mediaData = [
//   // {id: 2011, name: '港澳台媒数据', avatar: imgGangaotai, desc: '覆盖港澳台主流媒体', url: 'http://fm.focuxy.com/', isBlank: true},
// ]
// const industryData = [
//   // {id: 2021, name: '医疗在线问政数据', avatar: imgYiliao, desc: '主流医疗问诊平台数据查询', url: 'http://data.meihua.info/medical', isBlank: true},
// ]

const miceData = [
  {id: 3001, name: '新版试用申请', avatar: imgMeihua, desc: '申请开通新版V3专属平台', url: 'https://www.mingdao.com/form/09571bd5e4714e27bf0062e1056f5174', isBlank: true},
]

const toolData = [
  {id: 4001, name: '批量翻译', avatar: imgFanyi, desc: '自动翻译成英文，并输出Excel文件', url: '/translate'},
  {id: 4002, name: '网址批量解析', avatar: imgWeb, desc: '获取部分社媒转评赞及博主相关信息', url: '/parse'},
  // {id: 4003, name: '媒体信息查询', avatar: imgMeiti, desc: '批量媒体信息查询，包括PV、UV等'},
]
const resourceData = [
  {id: 5001, name: '行业案例报告', avatar: imgBaogao, desc: '持续更新各行业舆情案例报告', url: 'https://781b0769e46c1cae.share.mingdao.net/apps/kcshareFolder/5fad10493860ff0001a2c713', isBlank: true},
  {id: 5002, name: '中国传媒库', avatar: imgChuanmeiku, desc: '嫁接广告主和媒体市场供求', url: 'http://mediasearch.meihua.info', isBlank: true},
  {id: 5003, name: '媒体资源网', avatar: imgMeiti2, desc: '权威的广告媒体交易平台', url: 'http://www.allchina.cn', isBlank: true},
  {id: 5004, name: '电视眼', avatar: imgDianshi, desc: '全国电视收视率及排行榜', url: 'http://www.tvyan.com/top/', isBlank: true},
]

const lorealToken = 'E5/OJ/9mhD+pUeMV+i7TNR/8aK1dtu+fs0KkB4/dSUZiAMcvTYlLiFWSUxCoNmdfAItmZ2tazj6q78zuD1PJtFBWRhj863cVZz6Cx+FHOJrXtsxhV/mda4Go+iZQCsbDXWzMPdw5onhE9dZs2/9/D667xc/l7UDuIgmUo5uFrZk='

export default {
  data() {
    return {
      naviTitle: '首页',
      logo: logo,
      searchData: [...socialData, ...otherData, ...miceData, ...toolData, ...resourceData],
      moduleData: [
        {
          id: 'm-1',
          sectionData: [
            {
              id: 'm-1-1',
              name: '社交媒体',
              desc: '支持在线实时关键词和账户搜索，并支持一键快速完成搜索结果的下载导出',
              icon: iconMessage,
              data: socialData,
            },
            {
              id: 'm-1-2',
              name: '实时分析',
              desc: '',
              icon: iconOther,
              data: otherData,
            },
            // {
            //   id: 'm-1-3',
            //   name: '港澳台媒',
            //   desc: '',
            //   icon: iconOther,
            //   data: mediaData,
            // },
            // {
            //   id: 'm-1-4',
            //   name: '行业数据',
            //   desc: '',
            //   icon: iconOther,
            //   data: industryData,
            // },
            {
              id: 'm-1-5',
              name: '开通试用',
              desc: '',
              icon: iconEdit,
              data: miceData,
            },
          ],
        },
        {
          id: 'm-2',
          sectionData: [
            {
              id: 'm-2-1',
              name: '效率工具',
              desc: '',
              icon: iconClock,
              data: toolData,
            },
          ],
        },
        {
          id: 'm-3',
          sectionData: [
            {
              id: 'm-3-1',
              name: '资源库',
              desc: '持续更新的资源库',
              icon: iconFolder,
              data: resourceData,
            },
          ],
        },
      ],


      lorealTestUrl: `https://lorealfinance.mohodata.com/sso/loreal?token=${lorealToken}`
    }
  },
  components: {
    Navigate,
    SearchInput,
  },
  mounted() {
  },
  methods: {
    handleMenuItemClick(val) {
      const moduleElement = document.querySelector(`#section-${val}`)
      if (moduleElement) {
        const offsetTop = moduleElement.offsetTop
        window.scrollTo({
          top: offsetTop - 20,
          behavior: 'smooth',
        })
      }
    },

    handleTestJump() {
      window.open('https://hubapi.mohodata.com/mohodata/app/sso/loreal/redirect')
    }
  },
}
</script>

<style lang="scss" scoped>
.layout {
  background-color: #f9f9f9;
}
.nav-center-wrap {
  text-align: center;

  .search-wrap {
    max-width: 1100px;
    width: 100%;
  }
  
  ::v-deep .el-dropdown {
    width: 100%;
  }
  ::v-deep .el-input__inner {
    height: 50px;
  }
}

.home-layout {
  display: flex;
}
.side {
  position: relative;
  margin-top: -80px;
  width: 230px;
  background-color: white;

  .logo {
    padding: 20px;
    text-align: center;

    img {
      max-width: 100%;
    }
  }
}
.side-wrap {
  position: fixed;
  width: 230px;
}
.side-menu {

  .el-menu {
    border-right: none;
  }

  ::v-deep .el-menu-item {
    a {
      display: block;
      color: #333333;
    }
  }
}
.main {
  flex: 1;
}

.home-main {
  padding-bottom: 50px;
}
.section {
  margin-top: 20px;
  padding: 20px;
  border-radius: 4px;
  background-color: white;

  .section-wrap {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
.section-head {
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  .icon {
    margin-right: 10px;
  }
  .title {
    margin: 0;
    display: inline;
    font-size: 16px;
    color: #666666;
    font-weight: normal;

    
  }
  .desc {
    margin-left: 20px;
    font-size: 14px;
    color: #999999;
  }
}
.section-content {
  padding: 20px 0 0;
}
.search-data-list {
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
  flex-wrap: wrap;
}
.search-data-item {
  margin: 0 10px 20px;
  padding: 15px 12px;
  width: 250px;
  background: #FAFBFD;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  cursor: pointer;
  transition: transform .3s, border-color .3s;

  &:hover {
    border-color: #0772FF;
    transform: translateY(-5px);
    box-shadow: 0 4px 20px rgba($color: #000000, $alpha: 0.1);
  }

  a {
    text-decoration: none;
  }
  
  .head {
    display: flex;
    align-items: center;
  }
  .avatar {
    margin-right: 12px;
    width: 32px;

    img {
      max-width: 100%;
    }
  }
  .name {
    font-size: 16px;
    color: #333333;
  }
  .desc {
    margin-top: 12px;
    font-size: 14px;
    color: #333333;
  }
}
</style>