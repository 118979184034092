<template>
<div class="layout">
  <Navigate :naviTitle="naviTitle" />
  <div class="container trend-main">
    <div class="trend-header">
      <div class="header-left">
        <h2 class="title">指数趋势</h2>
        <span class="desc">实时查询具有一定声量的品牌或人名关键词等，在微博、百度及微信的指数趋势变化...</span>
      </div>
      <div class="header-right">
          <el-form :inline="true" :model="formValue" class="search-form">
            <el-form-item>
              <el-input v-model="formValue.keywords1" placeholder="关键词1" />
            </el-form-item>
            <el-form-item>
              <el-input v-model="formValue.keywords2" placeholder="关键词2" />
            </el-form-item>
            <el-form-item>
              <el-input v-model="formValue.keywords3" placeholder="关键词3" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">搜索</el-button>
            </el-form-item>
          </el-form>
      </div>
    </div>
    <div class="trend-content">
      <div class="trend-filter-box">
        <div class="time-tabs">
          <div 
            class="time-tab" 
            :class="{active: tabTimeIndex === item.id}" 
            v-for="item in tabTimes" 
            :key="item.id"
            @click="handleTimeTabChange(item.id)"
          >
            <span>{{item.name}}</span>
          </div>
        </div>
        <div class="extra">
          <a @click="handleDownload" v-if="searchLoaded || socialLoaded"><i class="el-icon-download"></i> 下载</a>
        </div>
      </div>
      <div class="trend-chart-panel-wrap">
        <div class="trend-chart-loading" v-if="isLoading">
          <i class="el-icon-loading"></i>
        </div>
        <div class="empty-box" v-show="!isDataLoaded">
          <img :src="imgEmpty">
          <div class="desc">请输入关键词</div>
        </div>
        <div class="trend-chart-panel">
          <div class="chart-box" v-loading="socialLoading">
            <div class="chart-title">
              微博指数 <i class="el-icon-loading" v-if="socialLoading"></i>
            </div>
            <div class="chart-table">
              <el-table
                id="socialTable"
                :data="socialTableData"
              >
                <el-table-column
                  prop="name"
                  label=""
                ></el-table-column>
                <el-table-column
                  prop="newIndex"
                  label="最新指数"
                ></el-table-column>
                <el-table-column
                  prop="prevIndex"
                  label="上期指数"
                ></el-table-column>
                <el-table-column
                  prop="comparison"
                  label="环比趋势"
                >
                  <template slot-scope="scope">
                    <span class="percent red" v-if="scope.row.comparison > 0"><i class="el-icon-top"></i> {{Math.abs(scope.row.comparison)}}%</span>
                    <span class="percent green" v-if="scope.row.comparison < 0"><i class="el-icon-bottom"></i> {{Math.abs(scope.row.comparison)}}%</span>
                    <span class="percent gray" v-if="scope.row.comparison === 0">-</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="chart-wrap">
              <div id="socialChart" class="chart"></div>
              <span class="icon-fullscreen" v-if="socialLoaded">
                <i class="el-icon-full-screen" v-if="socialLoaded" @click="handleDialogOpen('social')"></i>
                <span class="tip-text">
                  满屏查看
                </span>
              </span>
            </div>
          </div>
          <div class="chart-box" v-loading="searchLoading">
            <div class="chart-title">
              百度指数 <i class="el-icon-loading" v-if="searchLoading"></i>
            </div>
            <div class="chart-table">
              <el-table
                id="searchTable"
                :data="searchTableData"
              >
                <el-table-column
                  prop="name"
                  label=""
                ></el-table-column>
                <el-table-column
                  prop="allAvg"
                  label="整体日均"
                ></el-table-column>
                <el-table-column
                  prop="allQoq"
                  label="整体日均环比"
                >
                  <template slot-scope="scope">
                    <span class="percent red" v-if="scope.row.allQoq > 0"><i class="el-icon-top"></i> {{Math.abs(scope.row.allQoq)}}%</span>
                    <span class="percent green" v-if="scope.row.allQoq < 0"><i class="el-icon-bottom"></i> {{Math.abs(scope.row.allQoq)}}%</span>
                    <span class="percent gray" v-if="scope.row.allQoq === '-'">{{scope.row.allQoq}}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="wiseAvg"
                  label="移动日均"
                ></el-table-column>
                <el-table-column
                  prop="wiseQoq"
                  label="移动环比日均"
                >
                  <template slot-scope="scope">
                    <span class="percent red" v-if="scope.row.wiseQoq > 0"><i class="el-icon-top"></i> {{Math.abs(scope.row.wiseQoq)}}%</span>
                    <span class="percent green" v-if="scope.row.wiseQoq < 0"><i class="el-icon-bottom"></i> {{Math.abs(scope.row.wiseQoq)}}%</span>
                    <span class="percent gray" v-if="scope.row.wiseQoq === '-'">{{scope.row.wiseQoq}}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="chart-wrap">
              <div id="searchChart" class="chart"></div>
              <span class="icon-fullscreen" v-if="searchLoaded">
                <i class="el-icon-full-screen" @click="handleDialogOpen('search')"></i>
                <span class="tip-text">
                  满屏查看
                </span>
              </span>
            </div>
          </div>
          <div class="chart-box" v-loading="wechatLoading">
            <div class="chart-title">
              微信指数 <i class="el-icon-loading" v-if="wechatLoading"></i>
            </div>
            <div class="chart-table">
              <el-table
                id="socialTable"
                :data="wechatTableData"
              >
                <el-table-column
                  prop="name"
                  label=""
                ></el-table-column>
                <el-table-column
                  prop="newIndex"
                  label="最新指数"
                ></el-table-column>
                <el-table-column
                  prop="prevIndex"
                  label="上期指数"
                ></el-table-column>
                <el-table-column
                  prop="comparison"
                  label="环比趋势"
                >
                  <template slot-scope="scope">
                    <span class="percent red" v-if="scope.row.comparison > 0"><i class="el-icon-top"></i> {{Math.abs(scope.row.comparison)}}%</span>
                    <span class="percent green" v-if="scope.row.comparison < 0"><i class="el-icon-bottom"></i> {{Math.abs(scope.row.comparison)}}%</span>
                    <span class="percent gray" v-if="scope.row.comparison === 0">-</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="chart-wrap">
              <div id="wechatChart" class="chart"></div>
              <span class="icon-fullscreen" v-if="socialLoaded">
                <i class="el-icon-full-screen" v-if="socialLoaded" @click="handleDialogOpen('wechat')"></i>
                <span class="tip-text">
                  满屏查看
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <el-dialog
    :visible.sync="dialogVisible"
    :fullscreen="true"
    :before-close="handleDialogClose">
    <div ref="trendFullscreen" class="trend-fullscreen">

    </div>
  </el-dialog>
</div>
</template>

<script>
import Navigate from '@/components/Navigate.vue'
import axios from 'axios'
import moment from 'moment'
import GLOBAL from '../common/global_variable'
import { export_json_to_excel, formatJson } from '@/excel/Export2Excel'

import imgEmpty from '@/assets/empty.png'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

let _socialChart
let _searchChart
let _wechatChart
let _fullscreenChart
const _colors = ['#22A6EA','#00AD62','#f14e01']
const SearchType = {
  // 搜索（百度）
  SEARCH: 1,
  // 社媒（微博）
  SOCIAL: 2,
  // 微信
  WECHAT: 3,
}
const DatetimeType = {
  REAL_TIME: 1,
  DAY: 2,
  MONTH: 3,
  QUARTER: 4,
}

export default {
  data() {
    return {
      naviTitle: '指数趋势',
      imgEmpty,
      tabTimeIndex: DatetimeType.MONTH,
      formValue: {
        keywords1: '',
        keywords2: '',
        keywords3: '',
      },
      tabTimes: [
        {id: DatetimeType.REAL_TIME, name: '实时', value: ''},
        {id: DatetimeType.DAY, name: '近24小时', value: ''},
        {id: DatetimeType.MONTH, name: '近30天', value: ''},
        {id: DatetimeType.QUARTER, name: '近90天', value: ''},
      ],
      socialTrendData: [],
      searchTrendData: {},
      wechatTrendData: [],
      socialLoading: false,
      searchLoading: false,
      wechatLoading: false,
      socialLoaded: false,
      searchLoaded: false,
      wechatLoaded: false,

      dialogVisible: false,
    }
  },
  components: {
    Navigate,
  },
  mounted() {
    this.initLineChart()
  },
  computed: {
    keywordData() {
      const formValue = this.formValue
      let keywords = []
      if (formValue.keywords1) {
        keywords.push(formValue.keywords1)
      }
      if (formValue.keywords2) {
        keywords.push(formValue.keywords2)
      }
      if (formValue.keywords3) {
        keywords.push(formValue.keywords3)
      }
      return keywords
    },

    isKeywordEmpty() {
      const formValue = this.formValue
      return formValue.keywords1.trim() === '' && formValue.keywords2.trim() === '' && formValue.keywords3.trim() === ''
    },
    isLoading() {
      return this.socialLoading && this.searchLoading
    },
    isDataLoaded() {
      return this.socialLoaded || this.searchLoaded
    },

    socialTableData() {
      const socialTableData = this.socialTrendData.map((item,index) => {
        const trend = item.trend || {}
        const prevIndex = trend.prev_last.num
        const newIndex = trend.last.num
        return {
          id: index,
          name: trend.name,
          newIndex,
          prevIndex,
          comparison: parseFloat(((newIndex - prevIndex) / prevIndex * 100).toFixed(2)),
        }
      })
      return socialTableData
    },
    searchTableData() {
      const generalRatio = this.searchTrendData.generalRatio || []
      const searchTableData = generalRatio.map((item, index) => {
        const allAvg = item.all.avg
        const wiseAvg = item.wise.avg
        const allQoq = item.all.qoq
        const wiseQoq = item.wise.qoq
        return {
          id: index,
          name: item.word[0].name,
          allAvg,
          wiseAvg,
          allQoq,
          wiseQoq,
        }
      })
      return searchTableData
    },
    // wechatTableData() {
    //   const wechatTableData = this.wechatTrendData.map((item, idx) => {
    //     const dataArr = item.wxindex_str ? item.wxindex_str.split(',') : []
    //     const dataArrLen = dataArr.length
    //     const prevIndex = dataArr[dataArrLen - 2]
    //     const newIndex = dataArr[dataArrLen - 1]
    //     return {
    //       id: idx,
    //       name: item.query,
    //       prevIndex,
    //       newIndex,
    //       comparison: parseFloat(((newIndex - prevIndex) / prevIndex * 100).toFixed(2)),
    //     }
    //   })
    //   return wechatTableData
    // },
    wechatTableData() {
      const wechatTableData = this.wechatTrendData.map((item, idx) => {
        const dataArr = item.timeIndexes || []
        const dataArrLen = dataArr.length
        const prevIndex = (dataArr[dataArrLen - 2] || {}).score || 0
        const newIndex = (dataArr[dataArrLen - 1] || {}).score || 0
        return {
          id: idx,
          name: item.query,
          prevIndex,
          newIndex,
          comparison: parseFloat(((newIndex - prevIndex) / prevIndex * 100).toFixed(2)),
        }
      })
      return wechatTableData
    },
  },
  methods: {
    initLineChart() {
      _socialChart = this.$echarts.init(document.querySelector('#socialChart'))
      _searchChart = this.$echarts.init(document.querySelector('#searchChart'))
      _wechatChart = this.$echarts.init(document.querySelector('#wechatChart'))
    },

    getChartOption() {
      const option = {
       color: _colors,
       title: {
         text: '微博指数变化趋势',
          textStyle: {
            fontWeight: 'normal',
            fontSize: 14,
          },
          left: -5,
       },
       tooltip: {
         trigger: 'axis',
         axisPointer: {
           type: 'cross',
           label: {
             backgroundColor: '#6a7985'
           }
         }
       },
       legend: {
        //  right: '0%',
        top: 26,
        data: this.keywordData
       },
       toolbox: {
         right: 20,
          feature: {
            // dataZoom: {
            //     yAxisIndex: 'none'
            // },
            dataView: {readOnly: false},
            restore: {},
            saveAsImage: {}
          }
       },
      dataZoom: [
        {
            show: true,
            realtime: true,
            start: 0,
            end: 100
        },
        {
            type: 'inside',
            realtime: true,
            start: 0,
            end: 100
        }
      ],
       grid: {
         left: '0%',
         right: '5px',
         bottom: '50px',
         containLabel: true,
       },
       xAxis: [
         {
           type: 'category',
           boundaryGap: false,
           data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
         }
       ],
       yAxis: [
         {
           type: 'value',
           //设置网格线颜色
           splitLine: {
             show: true,
             lineStyle:{
               color: ['#F4F4F4'],
               width: 1,
               type: 'solid'
             }
           }
         }
       ],
       series: [],
     }
     return option
    },

    getDateList(start, end) {
      const dateList = [`${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`]
      while(start <= end){  
        const newDate = start.setDate(start.getDate() + 1);
        start = new Date(newDate);
        if (start <= end) {
          dateList.push(`${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`)
        }
      }
      return dateList
    },

    getSearchChartOption() {
      const trendData = this.searchTrendData.userIndexes || []
      const firstTrend = (trendData.length <= 0 ? {} : trendData[0]).all || {}
      const startDate = firstTrend.startDate
      const endDate = firstTrend.endDate
      let start = new Date(startDate);
      let end = new Date(endDate);
      const xAxisData = this.getDateList(start, end)
      // const xAxisData = [`${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`]
      // while(start <= end){  
      //   const newDate = start.setDate(start.getDate() + 1);
      //   start = new Date(newDate);
      //   if (start <= end) {
      //     xAxisData.push(`${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`)
      //   }
      // }
      // console.log(xAxisData)
      // const xAxisData = firstTrend.data || []
      const series = trendData.map((item, index) => {
        const trend = item.all || {}
        const data = trend.data.split(',')
        const word = item.word[0] || {}
        return {
          name: word.name,
          type: 'line',
          smooth: true,
          // stack: '总量',
          areaStyle: {
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: _colors[index],
            }, {
                offset: 1,
                color: '#ffe'
            }])
          },
          data,
        }
      })

      const defaultOption = this.getChartOption()
      const option = {
        ...defaultOption,
        title: {
          ...defaultOption.title,
          text: '百度指数变化趋势',
        },
        xAxis: [
         {
           type: 'category',
           boundaryGap: false,
           axisLabel: {
            rotate: 40,
           },
           data: xAxisData,
         }
       ],
       series,
      }
      return option
    },

    getSocialChartOption() {
      const trendData = this.socialTrendData
      const firstTrend = (trendData.length <= 0 ? {} : trendData[0]).trend || {}
      const xAxisData = firstTrend.x || []
      const series = trendData.map((item, index) => {
        const trend = item.trend || {}
        return {
          name: trend.name,
          type: 'line',
          smooth: true,
          // stack: '总量',
          areaStyle: {
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: _colors[index],
            }, {
                offset: 1,
                color: '#ffe'
            }])
          },
          data: trend.s,
        }
      })

      const defaultOption = this.getChartOption()
      const option = {
        ...defaultOption,
        title: {
          ...defaultOption.title,
          text: '微博指数变化趋势',
        },
        xAxis: [
         {
           type: 'category',
           boundaryGap: false,
           axisLabel: {
            rotate: 40,
           },
           data: xAxisData,
         }
       ],
       series,
      }
      return option
    },

    // getWechatChartOption() {
    //   const trendData = this.wechatTrendData || []
    //   const totalLength = trendData.length
    //   const dateRange = this.getSelectRange()
    //   const startDate = dateRange.sd
    //   const endDate = dateRange.ed
      
    //   let start = new Date(startDate);
    //   let end = new Date(endDate.setDate(endDate.getDate() - 1));  // 排除搜索当天
    //   const xAxisData = this.getDateList(start, end)
      
    //   const series = trendData.map((item, index) => {
    //     const data = item.wxindex_str.split(',')
    //     let tData = []
    //     switch (this.tabTimeIndex) {
    //       case DatetimeType.REAL_TIME:
    //       case DatetimeType.DAY:
    //         tData = data.slice(totalLength - 1)
    //         break
    //       case DatetimeType.MONTH:
    //         tData = data.slice(totalLength - 31)
    //         break
    //       case DatetimeType.QUARTER:
    //         tData = data.slice(totalLength - 91)
    //         break
    //     }
    //     const name = item.query
    //     return {
    //       name,
    //       type: 'line',
    //       smooth: true,
    //       // stack: '总量',
    //       areaStyle: {
    //         color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
    //           offset: 0,
    //           color: _colors[index],
    //         }, {
    //           offset: 1,
    //           color: '#ffe'
    //         }])
    //       },
    //       data: tData,
    //     }
    //   })

    //   const defaultOption = this.getChartOption()
    //   const option = {
    //     ...defaultOption,
    //     title: {
    //       ...defaultOption.title,
    //       text: '微信指数变化趋势',
    //     },
    //     xAxis: [
    //      {
    //        type: 'category',
    //        boundaryGap: false,
    //        axisLabel: {
    //         rotate: 40,
    //        },
    //        data: xAxisData,
    //      }
    //    ],
    //    series,
    //   }
    //   return option
    // },
    getWechatChartOption() {
      const trendData = this.wechatTrendData || []
      
      // const dateRange = this.getSelectRange()
      // const startDate = dateRange.sd
      // const endDate = dateRange.ed
      // let start = new Date(startDate);
      // let end = new Date(endDate.setDate(endDate.getDate() - 1));  // 排除搜索当天
      // const xAxisData = this.getDateList(start, end)

      const firstIndexs = (trendData[0] || []).timeIndexes || []
      const xAxisData = firstIndexs.map(item => item.time)
      
      const series = trendData.map((item, index) => {
        const indexes = item.indexes || []
        const data = (indexes[0] || {}).time_indexes || []
        const tData = data.map(item => item.score)
        const name = item.query
        return {
          name,
          type: 'line',
          smooth: true,
          // stack: '总量',
          areaStyle: {
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: _colors[index],
            }, {
              offset: 1,
              color: '#ffe'
            }])
          },
          data: tData,
        }
      })

      const defaultOption = this.getChartOption()
      const option = {
        ...defaultOption,
        title: {
          ...defaultOption.title,
          text: '微信指数变化趋势',
        },
        xAxis: [
         {
           type: 'category',
           boundaryGap: false,
           axisLabel: {
            rotate: 40,
           },
           data: xAxisData,
         }
       ],
       series,
      }
      return option
    },

    setSearchChart() {
      const option = this.getSearchChartOption()
      _searchChart.clear()
      _searchChart.setOption(option)
    },
    setSocialChart() {
      const option = this.getSocialChartOption()
      _socialChart.clear()
      _socialChart.setOption(option)
    },
    setWechatChart() {
      const option = this.getWechatChartOption()
      _wechatChart.clear()
      _wechatChart.setOption(option)
    },

    async requestTrend(type) {
      const isSocial = type === SearchType.SOCIAL
      const isBaidu = type === SearchType.SEARCH
      const isWechat = type === SearchType.WECHAT
      const socialTimes = {
        [DatetimeType.REAL_TIME]: '1hour',
        [DatetimeType.DAY]: '1day',
        [DatetimeType.MONTH]: '1month',
        [DatetimeType.QUARTER]: '3month',
      }
      const param = {
        token: GLOBAL.token,
        name: this.keywordData.join(','),
        c: type,
      }
      
      if (isSocial) {
        param.time_range = socialTimes[this.tabTimeIndex]
      } else if (isBaidu) {
        // const endDate = new Date()
        // let startDate = new Date(new Date().setDate((new Date().getDate()-1)))
        // switch (this.tabTimeIndex) {
        //   case 1:
        //   case 2:
        //     startDate = new Date(new Date().setDate((new Date().getDate()-1)))
        //     break
        //   case 3:
        //     startDate = new Date(new Date().setDate((new Date().getDate()-30)))
        //     // startDate = new Date(new Date().setMonth((new Date().getMonth()-1)))
        //     break
        //   case 4:
        //     startDate = new Date(new Date().setDate((new Date().getDate()-90)))
        //     break
        // }
        const dateRange = this.getSelectRange()
        const startDate = dateRange.sd
        const endDate = dateRange.ed
        param.start_date = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
        param.end_date = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`
        // delete param.start_date
        // delete param.end_date
        // param.time_range = 1
      } else if (isWechat) {
        const dateRange = this.getSelectRange()
        const startDate = dateRange.sd
        const endDate = dateRange.ed
        // param.start_date = `${startDate.getFullYear()}${startDate.getMonth() + 1}${startDate.getDate()}`
        // param.end_date = `${endDate.getFullYear()}${endDate.getMonth() + 1}${endDate.getDate()}`
        param.start_date = Number(moment(startDate).format('YYYYMMDD'))
        param.end_date = Number(moment(endDate).format('YYYYMMDD'))
      }
      // let paramStr = ''
      // for (let k in param) {
      //   paramStr += `&${k}=${param[k]}`;
      // }
      try {
        this.handleLoading(true, type)
        const res = await this.$axios.post('/api/data/index/search', param)
        const result = res.data 
        if (result.success) {
          if (isSocial) {
            this.socialLoaded = true
            this.socialTrendData = result.data || []
            this.setSocialChart()
          } else if (isBaidu) {
            this.searchLoaded = true
            this.searchTrendData = result.data || {}
            this.setSearchChart()
          } else if (isWechat) {
            this.wechatLoaded = true
            // this.wechatTrendData = result.data?.data?.group_wxindex || []
            this.wechatTrendData = (result.data?.content?.resp_list || []).map(item => ({...item, timeIndexes: ((item.indexes || [])[0] || {}).time_indexes || []}))
            // this.wechatTrendData = ((result.data || {}).data || {}).group_wxindex || [];
            this.setWechatChart()
          }
        } else {
          if (isSocial) {
            this.$message('获取微博指数数据失败')
          } else if (isBaidu) {
            this.$message('获取百度指数数据失败')
          } else if (isWechat) {
            this.$message('获取微信指数数据失败')
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.handleLoading(false, type)
      }
    },

    getSelectRange() {
      const endDate = new Date()
      let startDate = new Date(new Date().setDate((new Date().getDate()-1)))
      switch (this.tabTimeIndex) {
        case DatetimeType.REAL_TIME:
        case DatetimeType.DAY:
          startDate = new Date(new Date().setDate((new Date().getDate()-1)))
          break
        case DatetimeType.MONTH:
          startDate = new Date(new Date().setDate((new Date().getDate()-30)))
          break
        case DatetimeType.QUARTER:
          startDate = new Date(new Date().setDate((new Date().getDate()-90)))
          break
      }
      return {
        sd: startDate,
        ed: endDate,
      }
    },

    handleLoading(flag, type) {
      switch (type) {
        case SearchType.SOCIAL:
          this.socialLoading = flag
          break
        case SearchType.SEARCH:
          this.searchLoading = flag
          break
        case SearchType.WECHAT:
          this.wechatLoading = flag
          break
      }
    },

    handleTimeTabChange(index) {
      if (index !== this.tabTimeIndex) {
        this.tabTimeIndex = index
        if (!this.isKeywordEmpty) {
          this.requestTrend(SearchType.SOCIAL)
          this.requestTrend(SearchType.SEARCH)
          this.requestTrend(SearchType.WECHAT)
        }
      }
    },

    onSubmit() {
      if (this.isKeywordEmpty) {
        this.$message('请至少输入一个关键词')
        return 
      }
      this.requestTrend(SearchType.SOCIAL)
      this.requestTrend(SearchType.SEARCH)
      this.requestTrend(SearchType.WECHAT)
    },

    getDateStartAndEnd() {
      let start = ''
      let end = moment().format('YYYY-MM-DD HH:mm')
      switch (this.tabTimeIndex) {
        case 1:
          start = moment().subtract(1, 'hours').format('YYYY-MM-DD HH:mm')
          break
        case 2:
          start = moment().subtract(24, 'hours').format('YYYY-MM-DD HH:mm')
          break
        case 3:
          start = moment().subtract(30, 'day').format('YYYY-MM-DD HH:mm')
          break
        case 4:
          start = moment().subtract(90, 'day').format('YYYY-MM-DD HH:mm')
          break
      }
      return {start, end}
    },

    getSocialTrendExcelData() {
      const trendData = this.socialTrendData
      const firstTrend = (trendData.length <= 0 ? {} : trendData[0]).trend || {}
      const dateData = firstTrend.x || []
      const headData = trendData.map(item => item.trend.name)
      const headerData = ['', ...headData]
      const socialData = dateData.map((dateV, dateIndex) => {
        const trendArr = trendData.map(t => t.trend.s[dateIndex])
        return [dateV, ...trendArr]
      })
      const title = ['指数明细']
      return [title, headerData, ...socialData]
    },
    getSocialTableExcelData() {
      const headerData = ["序号", "关键词名称", "最新指数", "上期指数", "环比趋势（%）"];
      const filterVal = ["id", "name", "newIndex", "prevIndex", "comparison"];
      const socialTable = this.socialTableData;
      const socialData = formatJson(filterVal, socialTable);
      const { start, end } = this.getDateStartAndEnd()
      const title = ['微博指数', `日期范围：${start}~${end}`]
      return [title, headerData, ...socialData]
    },
    getSocialExcelData() {
      const socialTrendData = this.getSocialTrendExcelData()
      const socialTableData = this.getSocialTableExcelData()
      return [...socialTableData,[], ...socialTrendData]
    },

    getSearchTrendExcelData() {
      const trendData = this.searchTrendData.userIndexes || []
      const firstTrend = (trendData.length <= 0 ? {} : trendData[0]).all || {}
      const startDate = firstTrend.startDate
      const endDate = firstTrend.endDate
      let start = new Date(startDate);
      let end = new Date(endDate);
      const dateData = this.getDateList(start, end)
      const headData = trendData.map(item => (item.word[0] || {}).name)
      const headerData = ['', ...headData]

      const searchData = dateData.map((dateV, dateIndex) => {
        const trendArr = trendData.map(t => (t.all || {}).data.split(',')[dateIndex])
        return [dateV, ...trendArr]
      })
      const title = ['指数明细']
      return [title, headerData, ...searchData]
    },
    getSearchTableExcelData() {
      const headerData = ["序号", "关键词名称", "整体日均", "整体环比日均（%）", "移动日均", "移动环比日均（%）"];
      const filterVal = ["id", "name", "allAvg", "allQoq", "wiseAvg", "wiseQoq"];
      const searchTable = this.searchTableData;
      const searchData = formatJson(filterVal, searchTable);
      const { start, end } = this.getDateStartAndEnd()
      const title = ['百度指数', `日期范围：${start}~${end}`]
      return [title, headerData, ...searchData]
    },
    getSearchExcelData() {
      const searchTrendData = this.getSearchTrendExcelData()
      const searchTableData = this.getSearchTableExcelData()
      return [...searchTableData,[], ...searchTrendData]
    },

    
    // getWechatTrendExcelData() {
    //   const trendData = this.socialTrendData
    //   const firstTrend = (trendData.length <= 0 ? {} : trendData[0]).trend || {}
    //   const dateData = firstTrend.x || []
    //   const headData = trendData.map(item => item.trend.name)
    //   const headerData = ['', ...headData]
    //   const socialData = dateData.map((dateV, dateIndex) => {
    //     const trendArr = trendData.map(t => t.trend.s[dateIndex])
    //     return [dateV, ...trendArr]
    //   })
    //   const title = ['指数明细']
    //   return [title, headerData, ...socialData]
    // },
    getWechatTrendExcelData() {
      const trendData = this.wechatTrendData
      const firstTrend = trendData.length <= 0 ? {} : trendData[0]
      const dateData = (firstTrend.timeIndexes || []).map(item => item.time)
      const headData = trendData.map(item => item.query)
      const headerData = ['', ...headData]
      const socialData = dateData.map((dateV) => {
        const dateStr = String(dateV)
        const formatDateV = dateStr.substring(0,4) + '-' + dateStr.substring(4,6) + '-' + dateStr.substring(6,8)
        const trendArr = trendData.map(t => (t.timeIndexes.find(idxes => idxes.time === dateV) || {}).score || 0)
        return [formatDateV, ...trendArr]
      })
      const title = ['指数明细']
      return [title, headerData, ...socialData]
    },
    getWechatTableExcelData() {
      const headerData = ["序号", "关键词名称", "最新指数", "上期指数", "环比趋势（%）"];
      const filterVal = ["id", "name", "newIndex", "prevIndex", "comparison"];
      const wechatTable = this.wechatTableData;
      const wechatData = formatJson(filterVal, wechatTable);
      const { start, end } = this.getDateStartAndEnd()
      const title = ['微信指数', `日期范围：${start}~${end}`]
      return [title, headerData, ...wechatData]
    },
    getWechatExcelData() {
      const wechatTrendData = this.getWechatTrendExcelData()
      const wechatTableData = this.getWechatTableExcelData()
      return [...wechatTableData,[], ...wechatTrendData]
    },

    handleDownload() {
      const keyStr = this.keywordData.join('_')
      const fileName = "指数分析_" + keyStr + "_" + moment(new Date()).format("YYYYMMDDHHmmss");
      const socialExcelData = this.getSocialExcelData()
      const searchExcelData = this.getSearchExcelData()
      const wechatExcelData = this.getWechatExcelData()
      const excelData = [...socialExcelData, [], [], ...searchExcelData, [], [], ...wechatExcelData]
      export_json_to_excel(['指数分析'], excelData, fileName);
    },

    handleDialogOpen(type) {
      // const isSocial = type === 'social'
      this.dialogVisible = true

      setTimeout(() => {
        _fullscreenChart = this.$echarts.init(this.$refs.trendFullscreen)
        // const option = isSocial ? this.getSocialChartOption() : this.getSearchChartOption()
        let option = {}
        if (type === 'social') {
          option = this.getSocialChartOption()
        } else if (type === 'wechat') {
          option = this.getWechatChartOption()
        } else {
          option = this.getSearchChartOption()
        }
        _fullscreenChart.setOption(option)
      }, 50)
    },
    handleDialogClose() {
      this.dialogVisible = false
      if (_fullscreenChart) {
        _fullscreenChart.dispose()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.layout {
  background-color: #f9f9f9;
}
.trend-main {
  padding-bottom: 50px;
}
  .container {
    margin: 0px 50px;
    min-width: 1100px;
    max-width: initial;
  }
  .trend-header {
    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      margin: 0;
      display: inline-block;
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #333333;
    }
    .desc {
      margin-left: 12px;
      font-size: 14px;
      color: #999999; 
    }
  }
  .search-form {
    .el-form-item {
      margin-bottom: 0;

      .el-input {
        width: 130px;
      }

      .el-button {
        width: 80px;
      }
    }
  }

  .trend-filter-box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .extra {
      font-size: 14px;
      a {
        // color: #0772FF;
        color: $color-primary;
      }
    }
  }
  .time-tabs {
    .time-tab {
      display: inline-block;
      width: 82px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 14px;
      color: #666666;
      background-color: #EDEFF2;
      cursor: pointer;

      &:hover {
        color: $color-primary;
      }
      &.active {
        color: $color-primary;
        background-color: white;
      }

      &:nth-child(n + 1) {
        border-left: 1px solid white;
      }
    }
  }
  .trend-chart-panel-wrap {
    position: relative;
  }
  .trend-chart-panel {
    padding: 30px 40px 50px;
    display: flex;
    flex-wrap: wrap;
    background-color: white;
  }
  .chart-box {
    margin-bottom: 40px;
    width: 50%;

    &:nth-child(2n - 1) {
      padding-right: 25px;
    }
    &:nth-child(2n) {
      padding-left: 25px;
    }
  }
  .chart-title {
    margin-bottom: 10px;
    padding-left: 10px;
    font-size: 18px;
    color: #333333;
    text-align: left;
    line-height: 20px;
    border-left: 4px solid $color-primary;
  }
  .chart-table {

    ::v-deep .el-table__header {
      
      th > .cell{
        font-size: 12px;
        color: #999999;
      }
    }

    ::v-deep .el-table__body-wrapper {
      td > .cell {
        font-family: PingFangSC-Medium;
        font-size: 18px;
      }

      tr > td:first-child {
        .cell {
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #333333;
        }
      }
    }

    .percent {

      &.red {
        color: #FF5300;
      }
      &.green {
        color: #00AD62;
      }
      &.gray {
        color: #cccccc;
      }
    }
  }
  .chart-wrap {
    position: relative;
    margin-top: 50px;
    .chart {
      width: 100%;
      height: 270px;
    }

    .icon-fullscreen {
      position: absolute;
      top: 6px;
      right: 0px;
      width: 18px;
      height: 18px;
      font-size: 18px;
      color: #666666;
      line-height: 1;
      cursor: pointer;

      &:hover {
        color: #168dd7;

        .tip-text {
          display: inline;
        }
      }

      .tip-text {
        position: absolute;
        top: 24px;
        left: 50%;
        transform: translateX(-50%);
        display: none;
        font-size: 12px;
        color: #168dd7;
        white-space: nowrap;
      }
    }
  }

  .trend-fullscreen {
    height: calc(100vh - 90px);
  }

  .trend-chart-loading {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    padding: 50px 0;
    width: 100%;
    height: 100%;
    font-size: 32px;
    text-align: center;
    background-color: rgba(255,255,255,.5);
  }

  .empty-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 300px;
    text-align: center;
    width: 100%;
    background-color: white;

    .desc {
      margin-top: 15px;
      font-size: 14px;
      color: #999999;
    }
  }
</style>