<template>
<div class="month-date">
    选择月份 <el-date-picker
        v-model="wbAccountDate"
        type="month"
        value-format="yyyyMM" 
        format="yyyyMM" 
        :clearable="true"
        :change = "monthDateChange()" 
        placeholder="请选择">
    </el-date-picker>
</div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'MonthDate',
  data(){
    return{
      wbAccountDate:moment(new Date()).format('YYYYMM')
    }
    
  },
  props: {
    // msg: String
  },
  methods:{
      monthDateChange(){
          this.$emit('wbMonthDate', this.wbAccountDate)
      }
  },
  mounted(){
      this.$emit('wbMonthDate', this.wbAccountDate)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.month-date{
    text-align: left;
    margin-left: 20px;
}
.month-date .el-input__inner{
    padding-left: 40px !important;
    border-top-right-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    /* width: 125px !important; */
    border: 0;
    text-align: left;
}

/* h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
