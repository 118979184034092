<template>
    <div class="navi-con" :class="{'has-side': hasSide}">
        <div class="navi-left">
            <router-link to="/" v-if="!hasSide">
              <img :src="logo" alt="首页">
            </router-link>
        </div>
        <div class="navi-center">
          <slot>
            <div class="title">{{naviTitle}}</div>
          </slot>
        </div>
        <div class="navi-right">
            <AccountMenu />
        </div>
    </div>
</template>
<script>
import AccountMenu from "../components/AccountMenu.vue"
import logo from '@/assets/logo.svg'
export default {
    props:['naviTitle','hasSide'],
    data(){
        return{
            showNavigateMenu:true,
            indexToolTitle:'工具中心首页',
            logo,
        }
    },
    components:{
        AccountMenu
    }
}
</script>
<style lang="scss" scoped>
a{
    color: unset;
    text-decoration-line: none;
}
.navi-con{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 5px 30px;
  background-color: white;
  /* color: #fff; */
  overflow: hidden;
  box-shadow: 0 1px 5px rgba($color: #000000, $alpha: 0.08);

  &.has-side {
      margin-left: 230px;
      width: calc(100% - 230px);
      background: #F3F3F3;

    //   .navi-left {
    //   }
    //   .navi-right {
    //   }
  }
}
.navi-left{
    width: 188px;
}
.navi-center {
  flex: 1;

  .title {
    font-size: 20px;
    color: #333333;
    text-align: center;
  }
}
.navi-right{
    width: 188px;
    text-align: right;
}
.clear{
    clear: both;
}
.more-tool-menu{
    font-size: 26px;
    cursor: pointer;
    margin-right: 10px;
}
.navi-icon{
    /* vertical-align: text-top !important; */
    font-size: 18px !important;
}
.navi-icon-font{
    font-size: 20px;
}

@media screen and (max-width: 1600px){
    .navi-con {
        &.has-side {
            .navi-left {
                width: initial;
                min-width: 48px;
            }
            .navi-right {
                width: initial;
                min-width: 48px;
            }
        }
    }
}
</style>