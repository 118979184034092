<template>
  <div class="account-menu" >
    <el-dropdown @command="handleCommand">
        
        <span class="el-dropdown-link">
            <img class="avatar-img" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" />
        </span>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item disabled>Hi~ {{fullName}} <br /><span class="account-email">{{email}}</span> </el-dropdown-item>
            <el-dropdown-item divided command="logout" ><i class="el-icon-unlock"></i>退出</el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import GLOBAL from '../common/global_variable'
export default {
    name:'AccountMenu',
    data(){
        return{ 
            fullName : GLOBAL.userInfo.fullName,
            email : GLOBAL.userInfo.email
        }
    },
    methods:{
        handleCommand(command){
            //this.$message('click on item ' + command);
            if(command == 'logout'){

                this.$store.commit('logout','');
                let params = {
                    token:GLOBAL.token
                }
                this.$axios.post('/api/admin/logout', params)
                .then(res=>{
                    if(res){
                        if(res.data.success){
                            console.log(res.data.message);
                        }
                        else{
                            console.log(res.data.message);
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                });

                let o = this.$route.query.o;
                if(o == 'f'){
                    this.$router.replace({path:'login?o=f'});
                }
                else{
                    this.$router.replace({path:'login'});
                }
                
            }
        }
    },
    mounted(){
        
        // this.fullName = this.$store.state.user.detail.fullName;
        // console.log(this.$store.state.user.detail.email)
        // this.email = this.$store.state.user.detail.email;
    }

}
</script>

<style scoped>
.account-menu{
    /* position: fixed; */
    top: 6px;
    right: 15px;
    height: 28px;
}
/* .el-dropdown-link {
    line-height: 28px;
} */
.avatar-img{
    width: 28px;
    height: 28px;
}
.el-dropdown-menu__item{
    line-height: 30px;
}
.el-dropdown-menu__item.is-disabled{
    color: #606266;
}
.account-email{
    color: #a7a7a7;
    line-height: 22px;
    font-size: 13px;
}
</style>
