<template>
    <div>
        <div>
            <Navigate :naviTitle="naviTitle" />
        </div>
       
        <div class="search-comp" ref="searchApp">
            <!-- <a ><img :src="logo" class="logo" :alt="company"></a>  -->
            <el-tabs v-model="searchPlat" @tab-click="handleClick()" ref="searchTabs">
                <el-tab-pane v-for="item in tabPlatforms" :key="item.name" :label="item.label" :name="item.name"></el-tab-pane>
            </el-tabs>
            <!-- <el-input placeholder="请输入内容" v-model="keywords" class="input-with-select" id="searchInput" @keyup.enter.native="search()">
                <el-select v-model="select" slot="prepend" placeholder="请选择" >
                    <el-option label="关键词" value="1"></el-option>
                    <el-option label="特定账号" value="2"></el-option>
                </el-select>
                <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
            </el-input> -->
            <div>
                <div class="new-search-input-div" >
                    <!-- prefix-icon="el-icon-search" -->
                    <el-input class="new-search-input" ref="searchInput" :focus="changeBorderStyle()"
                        :placeholder="searchInputPlaceholder"
                        v-model="keywords" @keyup.enter.native="loadFirst('')" id="txtSearchInput">
                    </el-input>
                    <el-dropdown class="new-search-left-drop" @command="handleCommand">
                        <span class="el-dropdown-link ">
                            <span ref="searchTypeDrop">关键词</span><i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown" >
                            <el-dropdown-item command="keyword" dLabel="关键词">关键词</el-dropdown-item>
                            <el-dropdown-item command="account" dLabel="特定账号">特定账号</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div style="float:left;width:20%;">
                    <el-button @click="loadFirst('')"  class="new-search-btn" type="primary" ref="newSearchBtn" :style="{backgroundColor:platColor,borderColor:platColor}" round>搜索</el-button>
                </div>
                <div class="clear">&nbsp;</div>
                <div class="keywords-search-type weibo" v-if="searchPlat=='weibo' && searchCommand =='keyword' ?true:false">
                    <el-radio-group v-model="wbKeywordSearchType" @change="changeWbSearchType()">
                        <el-radio label="all">全部</el-radio>
                        <el-radio label="hot">热门</el-radio>
                        <el-radio label="original">原创</el-radio>
                        <el-radio label="realTime">实时</el-radio>
                    </el-radio-group>
                </div>
                <div class="keywords-search-type zhihu" v-if="searchPlat=='zhihu' && searchCommand =='keyword' ? true : false">
                    <el-radio-group v-model="zhihuKeywordSearchType" @change="changeZhihuSearchType()">
                        <el-radio label="all">时间不限</el-radio>
                        <el-radio label="a_day">1天内</el-radio>
                        <el-radio label="a_week">1周内</el-radio>
                        <el-radio label="three_months">3个月内</el-radio>
                    </el-radio-group>
                </div>
                
                <MonthDate v-if="searchPlat=='weibo' && searchCommand =='account' ?true:false" ref="wbMonthDate" />
                <div class="search-account-panel" v-if="searchAccountPanel">
                    
                    <div class="profile-body">
                        <div class="profile-head"><span class="label-left">请选择账号（仅显示第一页）</span> <span class="label-right" @click="closeSearchAccountPanel()"><i class="el-icon-close" title="关闭"></i></span>
                            <div class="clear">&nbsp;</div>
                        </div>
                        <div v-for="(acc,index) in accounts" :key="index" class="profile-hr" @click="chooseAccount(acc.screenName,acc.id,acc.userName)">
                            <div class="profile-left"><img :src="acc.avatarUrl" /></div>
                            <div class="profile-right">
                                <div class="profile-scname">{{acc.screenName}}</div>
                                <div class="profile-des">抖音号：{{acc.douyinID}}&nbsp;&nbsp;/&nbsp;&nbsp;粉丝数：{{acc.fansCount}}</div>
                                <el-tooltip class="item" effect="dark" :content="acc.biography" placement="bottom">
                                    <div class="profile-des">简介：{{acc.biography==''?'-':acc.biography}}</div>
                                </el-tooltip>
                            </div>
                            <div class="clear">&nbsp;</div>
                        </div>
                    </div>
                    
                </div>

            </div>

            <div>
                <waterfall :dataList="dataList" :searchBarDisplay="searchBarDisplay" :light="light" :searchPlat="searchPlat" :searchCommand="searchCommand" :platColor="platColor"/>
            </div>
            <div class="load-more" @click="loadMore()" :style="{display:searchBarDisplay}">加载更多...</div>
            <div @click="login" v-if="false">login</div>
        </div>
        <span class="icon-troc-info" @click="infoDialogVisible=true" title="数据声明"><i class="el-icon-info"></i></span>
        <el-dialog
            title="数据声明"
            :visible.sync="infoDialogVisible"
            width="80%"
            >
            <el-collapse v-model="activeNames">
                <el-collapse-item title="声明" name="1">
                    <div>社交数据搜索平台数据的获取原理，是通过模拟人为在app或web站点的实时请求方式来获取数据，</div>
                    <div>数据结果依赖于检索平台的搜索机制等，不保证完整性和有序性，仅 用于Demo演示及执行作业临时检索与下载。</div>
                </el-collapse-item>
                <el-collapse-item title="抖音" name="2">
                    <div>目前支持通过关键词搜索短视频，以及通过关键词搜索创建者，再选择创作者获取该创作者发布等历史视频内容。</div>
                    <div>内容排序为时间倒序，支持下载字段包括：标题、日期、链接、昵称、点赞、转发、评论、ID。</div>
                </el-collapse-item>
                <el-collapse-item title="快手" name="3">
                    <div>目前支持通过关键词搜索短视频，以及通过关键词搜索创建者，再选择创作者获取该创作者发布等历史视频内容。</div>
                    <div>内容排序为推荐排序，支持下载字段包括：标题、日期、链接、昵称、播放量、点赞、转发、评论、ID、用户ID。</div>
                </el-collapse-item>
                <el-collapse-item title="小红书" name="4">
                    <div>目前支持通过关键词搜索短视频，以及通过关键词搜索创建者，再选择创作者获取该创作者发布等历史视频内容。</div>
                    <div>内容排序为推荐倒序。下载方式支持简单版和完整版，简单版字段：标题、链接、ID、用户ID，完整版字段：</div>
                    <div>标题、日期、链接、正文、昵称、点赞、转发、评论、收藏、ID、用户ID。</div>
                </el-collapse-item>
                <el-collapse-item title="西瓜视频" name="5">
                    <div>目前支持通过关键词搜索短视频，以及通过创作者主页链接，获取该创作者发布等历史视频内容。</div>
                    <div>内容排序为时间倒序，支持下载字段包括：标题、日期、链接、昵称、播放、ID、用户主页、用户ID。</div>
                </el-collapse-item>
                <el-collapse-item title="知乎" name="6">
                    <div>目前支持通过关键词搜索知乎问题（时间一周内）。</div>
                    <div>内容排序为推荐倒序，支持下载字段包括：标题、日期、链接、正文、浏览量、关注、回答、评论、ID、标签。</div>
                </el-collapse-item>
                <el-collapse-item title="微博" name="7">
                    <div>目前支持通过关键词搜索微博，以及通过关键词搜索创建者，再选择创作者获取该创作者发布等历史微博内容。</div>
                    <div>内容排序可选全部、实时、热门、原创、可选时间范围，支持下载字段包括：标题、日期、链接、昵称、原创转发、点赞、转发、评论、性别、是否认证、认证类型、地区、ID、用户主页。</div>
                </el-collapse-item>
            </el-collapse>
            <span slot="footer" class="dialog-footer">
                <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
                <el-button type="primary" @click="infoDialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import Navigate from "../components/Navigate.vue"
import waterfall from "../components/waterfall.vue"
import MonthDate from "../components/MonthDate.vue"
import GLOBAL from '../common/global_variable'
import moment from 'moment'
import global_variable from '../common/global_variable'
import router from '@/router'

const defaultKeywordSearchTxt = '请输入关键词，如“马云”';
const defaultAccountSearchTxt = '请输入账号昵称，如“罗永浩”';
const defaultWbAccountSearchTxt = '请粘贴单条微博链接（仅限Web端链接），如“https://weibo.com/1577826897/Jp9rwgi3w”';
const defaultBilibiliAccountSearchTxt = '请粘贴哔哩哔哩用户主页链接，如“https://space.bilibili.com/2101319407/”';
const defaultWbAccountSearchAlert= '请粘贴需要查询微博账号发布的任意一条微博链接（仅限Web端链接），如“https://weibo.com/1577826897/Jp9rwgi3w”';
const defaultXiguaAccountSearchAlert= '请粘贴用户西瓜视频主页链接，如“http://www.ixigua.com/home/5469817756/”';
const defaultWbUrlErrorAlert = '您输入链接可能不是单条微博链接（仅限Web端链接），请检查输入';

const platformColorMap = {
    douyin: '#161823',
    kuaishou: '#ff5000',
    xiaohongshu: '#ff2442',
    xigua: '#fe104d',
    zhihu: '#0084ff',
    weibo: '#fa7d3c',
    bilibili: '#FB7299',
}
const tabPlatforms = [
    {name: 'douyin', label: '抖音'},
    // {name: 'kuaishou', label: '快手'},
    {name: 'xiaohongshu', label: '小红书'},
    // {name: 'xigua', label: '西瓜'},
    // {name: 'zhihu', label: '知乎'},
    // {name: 'weibo', label: '微博'},
    // {name: 'bilibili', label: '哔哩哔哩'},
]

export default {
    name : 'Search',
    data(){
        return{
            tabPlatforms,

            naviTitle:'社交数据搜索',
            logo: '',
            company:'',
            apiUrl : process.env.VUE_APP_URL,
            keywords:'',
            searchInputPlaceholder: defaultKeywordSearchTxt,
            select:'1',
            page:1,
            dataList:[],
            searchPlat:'douyin',
            searchBarDisplay:'none',
            light:'',
            platColor:'#161823',
            searchCommand:'keyword',
            tabClick:false,
            apiPlat:'1',
            pageToken:'',
            hasNext: false,
            searchAccountPanel:false,
            wbKeywordSearchType:'all',
            zhihuKeywordSearchType:'a_week',
            wbAccountDate:'',
            accountId:'',
            accounts:[],
            infoDialogVisible:false,
            activeNames: '1',

        }
    },
    created() {
        const query = this.$route.query
        if (query.t) {
            this.searchPlat = query.t
        }
    },
    methods:{
        login(){
            // let loginapi = "http://rest.bigcat.com/ucenter/admin/login";
            // let paras = new FormData();
            // paras.append('username','andyfu');
            // paras.append('password','Crm0oandyfuyamingo0');
            // axios.post(loginapi, paras).then(res=>{
            //     console.log(res)
            // }).catch(err=>{
            //     console.log(err)
            // })

        },
        changeWbSearchType(){
            this.search();
            // console.log(this.wbKeywordSearchType);
        },
        changeZhihuSearchType() {
            this.search();
        },
        getDetailById(id){
            console.log(id);
        },
        /**通过关键词查找账号 */
        searchAccountByKeyword(){
            let kw = this.strTrim(this.keywords);
            if(!kw){
                if(!this.tabClick){
                    this.$message(defaultAccountSearchTxt);
                }
                
                this.$refs.searchInput.focus();
                return;
            }

            let apiStr = "";
                if(window.location.href.indexOf('ss.meihua.info') > -1){
                    apiStr = "/api";
                }
                else{
                    apiStr = this.apiUrl;
                }

                let params = {
                    c:'1',
                    kw:this.keywords,
                    token : GLOBAL.token
                }


                var api = apiStr +  "/api/data/"+ this.searchPlat  +"/user/search";
                
                let loadText = this.loadLoadingText();
                const lod = this.$loading({
                    lock: true,
                    text: loadText,
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                    });

                this.$axios.post(api, params).then(res=>{
                    if(res){
                        lod.close();
                        // if(!res.data.retcode != '000000'){
                        //     this.$message('请求失败，请稍后再试...');
                        //     console.log(res.data.retcode);
                            
                        //     return;
                        // }
                        if(res.data.retcode){
                            if(res.data.retcode != '000000'){
                                this.$message('请求失败，请稍后再试...' + res.data.message);
                                console.log(res.data.message);
                                
                                return;
                            }
                        }
                        else{
                            if(res.data.data){
                                if(res.data.data.length == 0){
                                    this.$message('请求失败，请稍后再试...');
                                    console.log(res.data.retcode);
                                    
                                    return;
                                }
                            }
                            else{
                                this.$message('请求失败，请稍后再试...');
                                console.log(res.data.retcode);
                                
                                return;
                            }
                        }

                        this.searchAccountPanel = true;
                        console.log(this.searchPlat)
                        var userList = [];
                        if(this.searchPlat == 'kuaishou'){
                            res.data.data.map(item => {
                                let newItem = {};
                                newItem.screenName = item.screenName;
                                newItem.avatarUrl = item.coverUrl;
                                newItem.id = item.id;
                                newItem.url = item.url;
                                newItem.biography = item.description;
                                newItem.fansCount = item.fansCount;

                                userList.push(newItem);
                            });
                        }
                        else if(this.searchPlat == 'douyin'){
                            res.data.data.map(item => {
                                userList.push(item);
                            });

                        }
                        else if(this.searchPlat == 'xiaohongshu'){
                            res.data.data.map(item => {
                                let newItem = {};
                                newItem.screenName = item.screenName;
                                newItem.avatarUrl = item.avatarUrl;
                                newItem.id = item.id;
                                newItem.url = item.url;
                                newItem.biography = item.description;
                                newItem.fansCount = item.fansCount;
                                newItem.userName = item.userName;

                                userList.push(newItem);
                            });
                        }
                        
                        this.accounts = userList;
                        console.log(this.accounts);
                    }

                }).catch(err=>{
                    lod.close();
                    if(this.page>1){
                        this.page--;
                    }
                    console.log(err);

                    this.$message("请求失败，请稍后再试." + err);
                });
        },
        /**选择一个账号 */
        chooseAccount(scName,id,userName){
            if(id){
                this.keywords = scName;
                this.accountId = id;
                if(userName){
                    this.userName = userName;
                }
                
                // this.search();
                this.searchByAccount();
                this.closeSearchAccountPanel();
            }
            else
                this.$message('未选择账号或选择账号ID不存在');
            console.log(id)
        },
        /**关闭搜索账号浮窗面板 */
        closeSearchAccountPanel(){
            this.searchAccountPanel = false;
        },
        /**处理搜索类型下拉框 */
        handleCommand(command, dLabel) {
            this.searchCommand = command;
            this.$refs.searchTypeDrop.innerText = dLabel.$attrs.dLabel;
            this.keywords = '';
            this.initSearchInputPlaceholder();
            if(command == 'account'){
                // this.$message('开发中，即将上线...');
                this.initSearchInputPlaceholder();
                    
                
            }
            else{
                this.$refs.searchInput.focus();
            }

        },
        /**初始化搜索框默认文本提示 */
        initSearchInputPlaceholder(){
            switch(this.searchPlat){
                case 'weibo':
                    if(this.searchCommand == 'account'){
                        this.searchInputPlaceholder = defaultWbAccountSearchTxt;
                    }
                    else{
                        this.searchInputPlaceholder = defaultKeywordSearchTxt;
                    }
                    break;
                case 'bilibili' : 
                    if (this.searchCommand === 'account') {
                        this.searchInputPlaceholder = defaultBilibiliAccountSearchTxt
                    } else {
                        this.searchInputPlaceholder = defaultKeywordSearchTxt
                    }
                    break
                default:
                    if(this.searchCommand == 'keyword'){
                        this.searchInputPlaceholder = defaultKeywordSearchTxt;
                    }
                    else{
                        this.searchInputPlaceholder = defaultAccountSearchTxt;
                    }
                    break;
            }
        },
        /**处理dom背景色 */
        handleDomBgColor(id,color){
            const searchTabs = this.$refs.searchTabs
            const tabItems = searchTabs.$el.querySelectorAll('.el-tabs__item')
            tabItems.forEach((itemEl) => {
                if (id === itemEl.id) {
                    itemEl.style.backgroundColor = color
                } else {
                    itemEl.style.backgroundColor = ''
                }
            })
        },
        /**改变边框样式 */
        changeBorderStyle(){
            var txt = document.getElementById('txtSearchInput');
            if(txt){
                txt.style.borderColor = this.platColor;
            }
        },
        /**处理tab点击事件样式 */
        handleClick(){
            router.replace({path: 'sds', query: {t: this.searchPlat}})
            this.handleTabStyleChange()
        },
        handleTabStyleChange() {
            this.platColor = platformColorMap[this.searchPlat]
            this.handleDomBgColor(`tab-${this.searchPlat}`,this.platColor)
            this.loadFirst('tabClick');
            this.initSearchInputPlaceholder();
        },
        /**加载更多 */
        loadMore(){
            ++this.page;
            if(this.searchCommand == 'account'){
                this.searchByAccount();
            }
            else
                this.search();
            // this.btnTop();
        },
        /**首次加载 */
        loadFirst(witch){
            if(witch == 'tabClick'){
                this.tabClick = true;
            }
            else{
                this.tabClick = false;
            }
            this.page = 1;
            GLOBAL.showDataList = [];
            this.dataList = [];
            this.searchBarDisplay = 'none';
            this.search();
        },
        /**公共搜索方法 */
        search(){
            if(this.searchPlat == 'weibo' && this.searchCommand == 'account'){
                this.wbAccountDate = this.$refs.wbMonthDate.wbAccountDate;
                console.log(this.wbAccountDate);
            }
            
            if(this.searchCommand == 'keyword'){
                this.searchComm();
            }
            else{
                // this.$message('开发中，即将上线...');
                if(this.searchPlat == 'weibo' || this.searchPlat == 'xigua' || this.searchPlat === 'bilibili'){
                    this.searchByAccount();
                }
                else{
                    this.searchAccountByKeyword();
                }
                
            }
                
        },
        /**随机获取loading文本 */
        loadLoadingText(){
            let texts = ['不经一番寒彻骨，怎得梅花扑鼻香。','每一天都比昨天要好，今天尤其如此！','失败，是留给不坚持的人。','山不辞土，故能成其高；海不辞水，故能成其深！','不要等待机会，而要创造机会。','业精于勤而荒于嬉，行成于思而毁于随','一百次心动不如一次行动。','越努力，越幸运！','很多事情不是你不会做，而是你想不想做，做没做的问题！'];
            let index = Math.floor(Math.floor(Math.random()*texts.length)) - 1;

            return texts[index];
        },
        /**通过idata接口搜索 */
        searchFromIDataapi(page, searchType, lod){
            let apiStr = "";
                if(window.location.href.indexOf('ss.meihua.info') > -1){
                    apiStr = "/api";
                }
                else{
                    apiStr = this.apiUrl;
                }

                let params = {
                    c:'1',
                    token : GLOBAL.token
                }

                let uidApiStr = '';
                if(searchType == 'kw'){
                    params.kw = this.keywords;
                    if(this.searchPlat == 'weibo'){
                        params.type = this.wbKeywordSearchType;
                        if(page>1 && this.pageToken){
                            params.pageToken = this.pageToken;
                        }
                        else{
                            //params.pageToken = '0';
                            console.log('');
                        }
                    }
                    //临时切换到自己到抖音数据
                    else if(this.searchPlat == 'douyin'){
                        if(global_variable.useDouyinApi == 'meihua'){
                            params.c = '1';
                            params.sort_type = '2';
                        }
                        else{
                            params.sort_type = '2';
                        }
                    }
                    else if(this.searchPlat == 'kuaishou'){
                        if(global_variable.useDouyinApi == 'meihua'){
                            // params.c = '';
                            // delete params.c;
                            this.$delete(params, 'c');
                            this.$delete(params, 'pageToken');
                            if(!this.page){
                                this.page = 1;
                                
                            }
                            this.pageToken = page.toString();
                            params.page = this.pageToken;
                            this.hasNext = true;
                        }
                        else{
                            console.log('ks');
                        }
                    }
                    else if(this.searchPlat == 'xiaohongshu'){
                        // params.sort = 'time_descending';

                        // sort 0=综合，2=最热，1=最新
                        params.sort = 1;
                    }
                    else if(this.searchPlat == 'zhihu'){
                        uidApiStr = '/question';
                        // params.time_zone = 'a_week';
                        params.time_zone = this.zhihuKeywordSearchType;
                        params.detail = '1';
                    }

                }
                else if(searchType == 'uid'){
                    if(this.searchPlat == 'douyin'){
                        params.sec_user_id = this.accountId;
                        if(page>1 && this.pageToken){
                            params.max_cursor = this.pageToken;
                        }
                        else{
                            params.max_cursor = '0';
                        }
                    }
                    else if(this.searchPlat == 'kuaishou'){
                        params.user_id = this.accountId;
                        params.userName = this.userName;
                        if(page>1 && this.pageToken){
                            params.p_cursor = this.pageToken;
                        }
                        else{
                            //params.p_cursor = '0';
                        }
                    }
                    else if(this.searchPlat == 'xiaohongshu'){
                        params.userId = this.accountId;
                        if(page>1 && this.pageToken){
                            params.page = this.pageToken;
                        }
                        else{
                            //params.page = '0';
                        }
                    }
                    else if(this.searchPlat == 'xigua'){
                        params.uid = this.accountId;
                        if(page>1 && this.pageToken){
                            params.pageToken = this.pageToken;
                        }
                        else{
                            //params.pageToken = '0';
                        }
                        params.page = this.pageToken;
                    }
                    else if(this.searchPlat == 'weibo'){
                        params.type = 'all';
                        params.uid = this.accountId;
                        params.date = this.wbAccountDate;
                        if(page>1 && this.pageToken){
                            params.pageToken = this.pageToken;
                        }
                        else{
                            //params.pageToken = '0';
                        }
                    }
                    else if (this.searchPlat === 'bilibili') {
                        params.uid = this.accountId
                        if (page > 1 && this.pageToken) {
                            params.pageToken = this.pageToken
                        }
                    }
                    
                    if(this.searchPlat != 'weibo' && this.searchPlat != 'xigua'){
                        uidApiStr = '/user/work';
                    }
                    
                }

                if(this.searchPlat == 'xigua'){
                    params.c = '';
                    params.sort_type = 'publish_time';
                }
                    
                var api = apiStr +  "/api/data/"+ this.searchPlat + uidApiStr +"/search";
                if (this.searchPlat === 'bilibili') {
                    api = apiStr + '/api/data/bilibili/search'
                }

                // console.log('search from id data api', api)
                console.log('search idata param', page)

                
                if(page > 1 ){

                    if(this.searchPlat != 'xigua'){
                        if(this.pageToken){
                            params.pageToken = this.pageToken;
                        }
                        

                        //临时抖音
                        if(this.searchPlat == 'douyin'){
                            if(global_variable.useDouyinApi == 'meihua'){
                                // params.page = this.pageToken;
                                params.pageToken = (page - 1) * 10;
                            }
                            else{
                                if(!this.hasNext){
                                    lod.close();
                                    this.$message('已经到底了...');
                                    return;
                                }
                            }
                        } else if (this.searchPlat === 'xiaohongshu') {
                            params.pageToken = page;
                        }
                        else{
                            if(!this.hasNext){
                                lod.close();
                                this.$message('已经到底了...');
                                return;
                            }
                        }
                        
                    }
                    else{
                        params.page = this.pageToken;
                    }
                    
                }

                this.$axios.post(api, params).then(res=>{
                    if(res){
                        lod.close();
                        if(this.searchPlat == 'xigua'){
                            if(!res.data.success){
                                this.$message('请求失败，请稍后再试...');
                            }
                            else{
                                if(res.data.data.data.length == 0){
                                    this.$message('已经到底了...');
                                    if(page>1){
                                        page = page-1;
                                    }
                                    return;
                                }
                            }
                        }
                        else{
                            //临时调整抖音使用自己数据
                            if(this.searchPlat == 'douyin'){
                                if(global_variable.useDouyinApi == 'meihua'){
                                    // if(res.data.success){
                                    //     console.log('success');
                                    // }
                                    // else{
                                    //     this.$message('请求失败，请稍后再试...' + res.data.message);
                                    //     return;
                                    // }

                                    // if(res.data.retcode != '000000'){
                                    //     this.$message('请求失败，请稍后再试...');

                                    //     if(page>1){
                                    //         page = page-1;
                                    //     }
                                    //     return;
                                    // }
                                    if(res.data.status_code != 0){
                                        this.$message('请求失败，请稍后再试...');

                                        if(page>1){
                                            page = page-1;
                                        }
                                        return;
                                    }
                                }
                                else{
                                    // if(res.data.retcode != '000000'){
                                    //     this.$message('请求失败，请稍后再试...');

                                    //     if(page>1){
                                    //         page = page-1;
                                    //     }
                                    //     return;
                                    // }
                                    if(res.data.status_code != 0){
                                        this.$message('请求失败，请稍后再试...');

                                        if(page>1){
                                            page = page-1;
                                        }
                                        return;
                                    }
                                }
                                
                            }
                            else if(this.searchPlat == 'kuaishou'){
                                if(global_variable.useDouyinApi == 'meihua'){
                                    if(res.data.success){
                                        console.log('success');
                                        this.pageToken = this.pageToken + 1;
                                    }
                                    else{
                                        this.$message('请求失败，请稍后再试...' + res.data.message);
                                        return;
                                    }
                                }
                                else{
                                    if(res.data.retcode != '000000'){
                                        this.$message('请求失败，请稍后再试...');

                                        if(page>1){
                                            page = page-1;
                                        }
                                        return;
                                    }
                                }
                            }
                            // else if(this.searchPlat == 'zhihu'){
                            //     if(res.data.retcode != '000000'){
                            //         this.$message('请求失败，请稍后再试...');

                            //         if(page>1){
                            //             page = page-1;
                            //         }
                            //         return;
                            //     }
                            //     else{
                            //         this.pageToken = res.data.pageToken;
                            //     }
                            // }
                            else{
                                // if(res.data.retcode != '000000'){
                                //     this.$message('请求失败，请稍后再试...');

                                //     if(page>1){
                                //         page = page-1;
                                //     }
                                //     return;
                                // }
                                if(res.data.status_code != 0){
                                    this.$message('请求失败，请稍后再试...');

                                    if(page>1){
                                        page = page-1;
                                    }
                                    return;
                                }
                            }
                            
                        }

                        this.searchBarDisplay = 'block';
                        if(page == 1){
                            GLOBAL.showDataList = [];
                            this.dataList = [];
                        }

                        if(this.searchPlat == 'xigua'){
                            this.pageToken = res.data.data.pageToken;
                            if(this.pageToken){
                                this.hasNext = true;
                            }
                        }
                        else{
                            if(this.searchPlat == 'douyin'){
                                if(global_variable.useDouyinApi == 'meihua'){
                                    // this.pageToken = page + 1;
                                    this.pageToken = res.data.pageToken;
                                    this.hasNext = true;
                                }
                                else{
                                    this.pageToken = res.data.pageToken;
                                    this.hasNext = res.data.hasNext;
                                }
                                
                                
                            }
                            else{
                                this.pageToken = res.data.pageToken;
                                this.hasNext = res.data.hasNext;
                            }
                            
                        }
                        
                        if (this.searchPlat == 'kuaishou') {
                            res.data.data.map(item => {
                                if(!item.publishDateStr){
                                    item.publishDateStr = moment(item.publishDate*1000).format('YYYY-MM-DD HH:mm:ss');
                                }
                                GLOBAL.showDataList.push(item);
                            });
                        } else if(this.searchPlat == 'douyin'){
                            console.log('douyin',res.data.data.data)
                            res.data.data.data.map(item => {
                                const aweme_info = item.aweme_info || {}
                                const share_info = aweme_info.share_info || {}
                                const video = aweme_info.video || {}
                                const author = aweme_info.author || {}
                                const newItem = {
                                    ...item,
                                    id: aweme_info.aweme_id,
                                    description: aweme_info.desc,
                                    coverUrl: video.cover?.url_list[0],
                                    webUrl: share_info.share_url,
                                    publishDateStr: moment(aweme_info.create_time * 1000).format('YYYY-MM-DD HH:mm:ss'),
                                    uid: author.uid,
                                    posterScreenName: author.nickname,
                                    viewCount: 0,
                                    commentCount: 0,
                                    answerCount: 0,
                                    followerCount: author.follower_count,
                                    likeCount: author.favoriting_count,
                                    profileUrl: `https://www.douyin.com/user/${author.sec_uid}?relation=0&vid=${aweme_info.aweme_id}`
                                }
                                GLOBAL.showDataList.push(newItem);
                            });
                            
                        } else if(this.searchPlat == 'xiaohongshu'){
                            console.log('xiaohongshu data',res.data.data)
                            res.data.data.items.map(item => {
                                const note_card = item.note_card || {}
                                const cover_info_list = note_card.cover?.info_list || []
                                const firstCover = cover_info_list[0] || {}
                                const interact_info = note_card.interact_info || {}
                                const user = note_card.user || {}
                                const newItem = {
                                    ...item,
                                    description: note_card.display_title || '',
                                    coverUrl: (firstCover.url || '').replace('http://', 'https://'),
                                    webUrl: '',
                                    uid: user.user_id,
                                    posterScreenName: user.nickname,
                                    avatar: user.avatar,
                                    likeCount: interact_info.liked_count,
                                    profileUrl: `https://www.xiaohongshu.com/user/profile/${user.user_id}`,
                                }
                                // 过滤部分node_card不存在的数据
                                if (item.note_card) {
                                    GLOBAL.showDataList.push(newItem);
                                }
                            });
                        }
                        else if(this.searchPlat == 'zhihu'){
                            res.data.data.map(item => {

                                let newItem = {};
                                newItem.description = item.title;
                                newItem.webUrl = item.url;
                                newItem.publishDateStr = moment(new Date(item.publishDate*1000)).format('YYYY-MM-DD HH:mm:ss');
                                // newItem.coverUrl = item.coverUrl;
                                // newItem.posterScreenName = item.posterScreenName;
                                // newItem.uid = item.posterId;
                                newItem.viewCount = item.viewCount;
                                newItem.commentCount = item.commentCount;
                                newItem.answerCount = item.answerCount;
                                newItem.id = item.id;
                                let tagStr = '';
                                let tags = item.tags;
                                if(tags && tags.length > 0){
                                    for(var i=0;i<tags.length;i++){
                                        tagStr += tags[i] + ',';
                                    }
                                    tagStr = tagStr.trimEnd(',');
                                }
                                newItem.tags = tagStr;
                                newItem.followerCount = item.fansCount;
                                newItem.content = item.content;
                                GLOBAL.showDataList.push(newItem);

                                //GLOBAL.showDataList.push(item);
                            });
                        }
                        else if(this.searchPlat == 'xigua'){
                            res.data.data.data.map(item => {
                                let newItem = {};
                                newItem.description = item.title;
                                newItem.webUrl = item.url;
                                newItem.publishDateStr = moment(new Date(item.publishDate*1000)).format('YYYY-MM-DD HH:mm:ss');
                                newItem.coverUrl = item.coverUrl;
                                newItem.posterScreenName = item.posterScreenName;
                                newItem.uid = item.posterId;
                                newItem.viewCount = item.viewCount;
                                newItem.commentCount = item.commentCount;
                                newItem.likeCount = item.likeCount;
                                newItem.id = item.id;
                                newItem.profileUrl = 'http://www.ixigua.com/home/' + item.posterId;

                                GLOBAL.showDataList.push(newItem);
                            });
                        }
                        else if(this.searchPlat == 'weibo'){
                            res.data.data.map(item => {
                                let newItem = {};
                                newItem.description = item.content;
                                newItem.webUrl = item.url;
                                newItem.publishDateStr = moment(new Date(item.publishDate*1000)).format('YYYY-MM-DD HH:mm:ss');
                                newItem.avatarUrl = item.from.extend.avatar_large;
                                if(item.coverUrl){
                                    newItem.coverUrl = item.coverUrl;
                                }
                                else{
                                    if(item.from.extend.cover_image){
                                        newItem.coverUrl = item.from.extend.cover_image;
                                    }
                                }
                                
                                newItem.publishDate = item.publishDate;
                                newItem.id = item.id;
                                newItem.likeCount = item.likeCount;
                                newItem.shareCount = item.shareCount;
                                newItem.commentCount = item.commentCount;
                                newItem.fansCount = item.from.fansCount;
                                newItem.posterScreenName = item.from.name;
                                newItem.uid = item.from.id;
                                newItem.profileUrl = 'http://weibo.com/u/' + item.from.id;
                                newItem.location = item.from.extend.location;
                                let genderStr = '';
                                if(item.from.extend.gender){
                                    if(item.from.extend.gender == 'm'){
                                        genderStr = '男';
                                    }
                                    else if(item.from.extend.gender == 'f'){
                                        genderStr = '女';
                                    }
                                }
                                newItem.gender = genderStr;
                                let verifiedStr = '';
                                if(item.from.extend.verified){
                                    verifiedStr = '已认证';
                                }
                                else{
                                    verifiedStr = '未认证';
                                }

                                newItem.verified = verifiedStr;

                                let verifiedTypeStr = '';
                                var vType = item.from.extend.verified_type;
                                if(vType != null){
                                    if(vType == 0)
                                        verifiedTypeStr = '个人橙V认证';
                                    else if(vType == 1 || vType == 2 || vType == 3 )
                                        verifiedTypeStr = '企业蓝V认证';
                                    else if(vType == 220)
                                        verifiedTypeStr = '微博达人';
                                    else 
                                        verifiedTypeStr = '普通用户';
                                }
                                newItem.verifiedType = verifiedTypeStr;
                                newItem.source = item.mblog.source;
                                newItem.imageUrls = item.imageUrls;
                                if(item.imageUrls){
                                    newItem.firstImage = item.imageUrls[0];
                                }
                                let retw = item.mblog.retweeted_status;
                                if(retw){
                                    newItem.original = '转发';
                                    //原微博信息
                                    newItem.originalPosterScreenName = retw.user.screen_name;
                                    newItem.originalUrl = retw.url;
                                    newItem.originalText = retw.text;
                                    newItem.originalSource = retw.source;
                                    newItem.originalLikeCount = retw.attitudes_count;
                                    newItem.originalCommentsCount = retw.comments_count;
                                    newItem.originalShareCount = retw.reposts_count;
                                    newItem.originalPublishDateStr = retw.created_at;
                                }
                                else{
                                    newItem.original = '原创';
                                }
                                
                                GLOBAL.showDataList.push(newItem);
                            });
                        }
                        else if (this.searchPlat === 'bilibili') {
                            // console.log('res data', res.data.data)
                            res.data.data.forEach(item => {
                                let newItem = {
                                    ...item,
                                    description: (!item.description || item.description === '-') ? item.title : item.description,
                                    webUrl: item.url,
                                    publishDateStr: moment(item.publishDateStr).format('YYYY-MM-DD HH:mm:ss'),
                                    profileUrl: 'https://space.bilibili.com/' + item.posterId,
                                };

                                GLOBAL.showDataList.push(newItem)
                            })
                        }
                        
                        this.dataList = GLOBAL.showDataList;
                        console.log(this.dataList)
                    }

                }).catch(err=>{
                    lod.close();
                    if(this.page>1){
                        this.page--;
                    }
                    console.log(err);

                    this.$message("请求失败，请稍后再试." + err);
                });
            
        },
        /**通过梅花接口搜索 */
        searchFromMeihua(page, kw, lod){

            let apiStr = "";
                if(window.location.href.indexOf('ss.meihua.info') > -1){
                    apiStr = "/api";
                }
                else{
                    apiStr = this.apiUrl;
                }

                var api = apiStr +  "/api/data/"+ this.searchPlat +"/search?kw="+ kw +"&page=" + page;
                let params = {
                    kw : this.keywords,
                    page : page,
                    token : GLOBAL.token
                }
                this.$axios.post(api, params).then(res=>{
                    if(res){
                        lod.close();
                        if(!res.data.success){
                            this.$message('请求失败，请稍后再试...');
                            if(page>1){
                                page = page-1;
                            }
                            return;
                        }
                        this.searchBarDisplay = 'block';
                        if(page == 1){
                            GLOBAL.showDataList = [];
                            this.dataList = [];
                        }
                        
                        if(this.searchPlat == 'kuaishou'){
                            res.data.data.map(item => {
                                GLOBAL.showDataList.push(item);
                            });
                        }
                        else if(this.searchPlat == 'douyin'){
                            res.data.data.map(item => {
                                GLOBAL.showDataList.push(item);
                            });
                            
                            // let dyDataList = this.fillDouyinModel(res);
                            // console.log(dyDataList)
                            // dyDataList.map(item => {
                            //     GLOBAL.showDataList.push(item);
                            // });
                        }
                        else if(this.searchPlat == 'xhs'){
                            res.data.data.map(item => {
                                GLOBAL.showDataList.push(item);
                            });
                        }
                        
                        this.dataList = GLOBAL.showDataList;
                        console.log(this.dataList)
                    }

                }).catch(err=>{
                    lod.close();
                    if(this.page>1){
                        this.page--;
                    }
                    console.log(this.page);
                    this.$message("请求失败，请稍后再试." + err);
                });

                //axios.get(api)
        },
        /**搜索公共方法 */
        searchComm(){
            var kw = this.strTrim(this.keywords);
            // console.log(kw);
            
            this.light = kw;
            var page = this.page;
            if(kw){
                
                this.$refs['searchApp'].style.width = "96%";
                this.$refs['searchApp'].style.margin = "2%";
                // this.$parent.$refs.app.style.margin = "1% auto";
                if(!page)
                    page = 1;

                let loadText = this.loadLoadingText();

                const lod = this.$loading({
                    lock: true,
                    text: loadText,
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                    });

                //this.searchFromMeihua(page, kw, lod);
                this.searchFromIDataapi(page, 'kw', lod);
            }
            else{
                if(!this.tabClick){
                    this.$message(defaultKeywordSearchTxt);
                }
                
                this.$refs.searchInput.focus();
            }
        },
        /**通过账号进行搜索 */
        searchByAccount(){
            var kw = this.strTrim(this.keywords);
            this.light = kw;
            var page = this.page;
            if(kw){
                if(this.searchPlat == 'weibo'){
                    if(this.keywords.indexOf('http:') > -1 || this.keywords.indexOf('https:') > -1){
                        if(this.keywords.indexOf('http://weibo.com/')>-1 || this.keywords.indexOf('https://weibo.com/')>-1){
                            let uid = this.keywords.split('/')[3];
                            if(uid){
                                this.accountId = uid;
                            }
                            else{
                                this.$message(defaultWbAccountSearchTxt);
                                return;
                            }
                        }
                        else{
                            this.$message(defaultWbUrlErrorAlert);
                            return;
                        }
                    }
                    else{
                        this.$message(defaultWbAccountSearchAlert);
                        return;
                    }

                }
                else if(this.searchPlat == 'xigua'){
                    if(this.keywords.indexOf('http:') > -1 || this.keywords.indexOf('https:') > -1){
                        if(this.keywords.indexOf('http://www.ixigua.com/home/')>-1 || this.keywords.indexOf('https://www.ixigua.com/home/')>-1){
                            let uid = this.keywords.split('/')[4];
                            if(uid){
                                this.accountId = uid;
                            }
                            else{
                                this.$message(defaultXiguaAccountSearchAlert);
                                return;
                            }
                        }
                        else{
                            this.$message(defaultXiguaAccountSearchAlert);
                            return;
                        }
                    }
                    else{
                        this.$message(defaultXiguaAccountSearchAlert);
                        return;
                    }
                }
                else if (this.searchPlat === 'bilibili') {
                    if (['http://space.bilibili.com/','https://space.bilibili.com/'].some(v => this.keywords.indexOf(v) >= 0)) {
                        let uid = this.keywords.split('/')[3]
                        let subEndIndex = uid.indexOf('?')
                        if (subEndIndex > 0) {
                            uid = uid.substring(0, subEndIndex)
                        } 
                        if (uid) {
                            this.accountId = uid
                        } else {
                            this.$message(defaultXiguaAccountSearchAlert);
                            return;
                        }
                    } else {
                        this.$message(defaultXiguaAccountSearchAlert);
                        return;
                    }
                }

                this.$refs['searchApp'].style.width = "96%";
                this.$refs['searchApp'].style.margin = "2%";
                // this.$parent.$refs.app.style.margin = "1% auto";
                if(!page)
                    page = 1;

                let loadText = this.loadLoadingText();

                const lod = this.$loading({
                    lock: true,
                    text: loadText,
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                    });

                //this.searchFromMeihua(page, kw, lod);
                this.searchFromIDataapi(page, 'uid', lod);
            }
            else{
                if(!this.tabClick){
                    if(this.searchPlat == 'weibo'){
                        this.$message(defaultWbAccountSearchAlert);
                    }
                    else if(this.searchPlat == 'xigua'){
                        this.$message(defaultXiguaAccountSearchAlert);
                    }
                    else{
                        this.$message(defaultAccountSearchTxt);
                    }
                    
                }
                
                this.$refs.searchInput.focus();
            }
        },
        /** 重新组装抖音数据对象 **/
        fillDouyinModel(res){
            let resultDataList = [];
            if(res && res.data.data.aweme_list){
                res.data.data.aweme_list.map(item => {
                    let newItem = {};
                    newItem.description = item.desc;
                    newItem.webUrl = item.share_url;
                    newItem.publishDateStr = moment(item.create_time*1000).format('YYYY-MM-DD HH:mm:ss');
                    newItem.publishDate = item.create_time.toString();
                    newItem.id = item.aweme_id;
                    newItem.userName = item.author.unique_id;
                    newItem.posterScreenName = item.author.nickname;
                    newItem.videoUrls = item.video.dynamic_cover.url_list[0];
                    newItem.coverUrl = item.video.cover.url_list[0];
                    newItem.likeCount = item.statistics.digg_count;
                    newItem.viewCount = 0;
                    newItem.shareCount = item.statistics.share_count;
                    newItem.commentCount = item.statistics.comment_count;
                    newItem.posterId = item.author.uid;
                    resultDataList.push(newItem);

                });
            }

            return resultDataList;
        },
        //替换字符串前后空格
        strTrim(str){
            if (str) {
                var textContent = str.replace(/^([\s\n\r]|<br>|<br\/>|&nbsp;)+/, "").replace(/([\s\n\r]|<br>|<br\/>|&nbsp;)+$/, "");
                textContent = textContent.replace(/(\r\n)|[\n\r]/g, '<br/>'); // 转换换行符
                //textContent = textContent.replace(/</g, "&lt;").replace(/>/g, "&gt;"); // 替换html标签符号
                return textContent;
            } else {
                return "";
            }
        }

    },
    components : {
        waterfall,
        MonthDate,
        Navigate
    },
    mounted(){
        this.logo = global_variable.global_logo_path;
        this.company = global_variable.global_company;
        this.$refs.searchInput.focus();
        this.changeBorderStyle();  
        
        setTimeout(() => {
            this.handleTabStyleChange()
        }, 100)
    }
}
</script>
<style lang="scss" scoped>
.keywords-search-type {
    padding: 20px 0 20px 20px;
    text-align: left;
    &.weibo {
        ::v-deep .el-radio-group {
            .el-radio__input.is-checked .el-radio__inner{
                border-color: #fa7d3c;
                background: #fa7d3c;
            }
            .el-radio__input.is-checked+.el-radio__label{
                color: #fa7d3c;
            }
        }
    }
    &.zhihu {
        ::v-deep .el-radio-group {
            .el-radio__input.is-checked .el-radio__inner{
                border-color: #0084ff;
                background: #0084ff;
            }
            .el-radio__input.is-checked+.el-radio__label{
                color: #0084ff;
            }
        }
    }
}
</style>
<style scpoed>
.icon-troc-info{
    cursor: pointer;
    position: fixed;
    right: 30px;
    bottom: 80px;
    font-size: 28px;
    opacity: .7;
    
}
.clear{
    clear: both;
}
.top-blank{
        height: 40px;
    }
/* .keywords-search-type{
    padding: 20px 0 20px 20px;
    text-align: left;
}
.el-radio__input.is-checked .el-radio__inner{
    border-color: #fa7d3c !important;
    background: #fa7d3c !important;
}
.el-radio__input.is-checked+.el-radio__label{
    color: #fa7d3c !important;
} */
.search-account-panel{
    position: relative;
    top:10px;
    left: 0;
    background-color: #fff;
    z-index: 1000;
    padding:0 10px 10px 10px;
    border: 1px solid #f7f7f7;
    box-shadow: 0 2px 14px 0 rgb(0 0 0/5%);
    min-width: 400px;
    max-height: 364px;
    overflow-y: scroll;
}
.profile-head{
    padding: 10px 10px 0 0;
    background-color: #fff;
    min-width: 375px;
    text-align: left;
    margin-top: 0px;
}
.label-left{
    float: left;
}
.label-right{
    float: right;
    cursor: pointer;
}
.profile-body{
    margin-top: 0;
}
.profile-hr{
    border-bottom: solid 1px #eee;
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #F0F0F0;
    border-radius: 12px 12px 12px 12px;
    overflow: hidden;
    box-sizing: border-box;
    transition: box-Shadow 0.5s, transform 0.5s;
}
.profile-hr:hover{
    background-color: aliceblue;
    cursor: pointer;
}
.profile-left{
    float: left;
    width: 10%;
    min-width: 59px;
}
.profile-right{
    float: left;
    width: 89%;
}
.profile-left img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: solid 2px #cccccc;
    background-color: #f2f6fc;
}
.profile-right img{
    width: 40px;
    height: 40px;
}
.profile-right .profile-scname{
    font-size: 16px;
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    padding: 0 10px 10px 10px;
}
.profile-right .profile-des{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    padding: 0 5px 5px 10px;
    color: #8c8c8c;
}
.profile-des-weibo a{
    color:#808080 !important;
    text-decoration-line: none !important;
}
    .search-comp{
        margin:2% 2%;
        width: 70%;
        min-height: 500px;
        margin: 0 auto;
        margin-top: 15%;

    }
    .searchInput{
       width:80%; 
       margin: 20px 0;
    }
    .appImg{
        width: 50px;
        height: 50px;
    }
.el-tabs__item.is-top.is-active{
    /* background-color: #409eff; */
    background-color: #161823;
    color: #fff;
    border-radius: 5px;
    width: 50px;
    height: 26px;
    line-height: 26px;
    font-size: 13px;
    text-align: center;
    padding: 0 !important;
}
.el-tabs__item{
    padding: 0;
    margin-right: 30px !important;
}
.el-tabs__header{
    margin: 0 0 5px !important;
}
.el-tabs__nav-wrap::after{
    background-color:#fff !important;
}
.el-tabs__active-bar{
    background-color:#fff !important;
}
.el-dropdown-link {
    cursor: pointer;
    color: #a0a0a0;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }

    .el-select .el-input {
    width: 130px;
  }
  .input-with-select {
    background-color: #fff;
    width: 50%;
  }
  .el-input-group__prepend {
    background-color: #fff;
  }
  .load-more{
      width: 100%;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      clear: both;
      background-color: #f5f7fa;
  }
   /* .search-comp .el-input--prefix,.search-comp .el-input__inner */
.search-comp .el-input__inner{
    border-radius: 0;
    height: 45px !important;
    border-top-left-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-left: 100px;
    /* border-right: 0 !important; */

  }
  .el-input__prefix{
      left:10px;
      margin-left: 5px;
  }
  .new-search-input{
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  .new-search-input-div{
      float: left;
      width: 79%;
      position: relative;
  }
  .new-search-left-drop{
    position: absolute !important;
    left: 18px;
    top: 12px;
  }

.new-search-btn{
    border-top-left-radius: 0 !important; 
    border-bottom-left-radius: 0 !important;
    height: 45px !important;
    border-top-right-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    width: 100% !important;
}
.new-search-btn:hover{
    opacity: .8;
}
    .el-button .el-button.is-round{
    border-radius: 0;
}
  html {
overflow-y: scroll;
}

:root {
overflow-y: auto;
overflow-x: hidden; 
}

:root body {
position: absolute;
}

body {
width: 100vw;
overflow: hidden;
}

</style>